import utils from './utils'
import katex from '../../js/katex/katex.mjs'

export function renderLatex (eltId: string) {
  const elTex = document.querySelectorAll('#' + eltId + ' .math-tex')
  let Tex = ''
  for (const texElement of Array.from(elTex)) {
    if (texElement.querySelector('span.katex') !== null) {
      const annotation = texElement.querySelector('annotation')
      if (annotation !== null) Tex = utils.text.decodeHtmlEntity(annotation.innerHTML.trim())
    } else {
      Tex = utils.text.decodeHtmlEntity(texElement.innerHTML.trim())
    }
    Tex = Tex.replace(/\\[()]/g, '')
    try {
      katex.render(Tex, texElement, {
        throwOnError: false,
        errorColor: '#FFF'
      })
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      texElement.innerHTML = err + ' erreur de rendu avec ' + Tex
    }
  }
}
