import Qcm from './qcm.js'
import Question from './question.js'

// [ qcm Id, qcm Name, qcm 1rst question Id, nb of questions ]
type entry = [number, string, number, number]

export default class QcmList {
  private _list: entry[]
  private _miniatures: Question[]

  constructor () {
    this._list = []
    this._miniatures = []
  }

  async importQcm (content: string, name: string): Promise<Qcm | undefined> {
    const qcm = await Qcm.import(content, name)
    if (qcm !== undefined) {
      this._list.push([qcm.id, qcm.name, qcm.questionsList[0], qcm.questionsList.length])
      return qcm
    } else return undefined
  }

  async importQcmList (textContent: string, filename: string): Promise<boolean> {
    if (textContent === '') return false
    try {
      const json = JSON.parse(textContent)
      if (json.uid === 'QCMCamQcms') {
        for (const qcm of json.qcms) {
          const newQcm = await Qcm.import(qcm, qcm._name)
          this._list.push([newQcm.id, newQcm.name, newQcm.questionsList[0], newQcm.questionsList.length])
        }
        return true
      } else return false
    } catch (e) {
      console.warn('Erreur d\'import de la liste des qcms de ' + filename)
      return false
    }
  }

  removeQcmReference (id: number): boolean {
    if (this._list[id] !== undefined) {
      this._list.splice(id, 1)
      return true
    } else return false
  }
  /** à revoir car il faut supprimer les questions aussi */

  async deleteQcmById (qcmId: number): Promise<boolean> {
    let retour = true
    for (const [key, qcm] of this._list.entries()) {
      if (qcm[0] === qcmId) {
        this._list.splice(key, 1)
        retour = await Qcm.delete(qcmId)
        break
      }
    }
    return retour
  }

  getQcmIndex (qcmId: number): number | undefined {
    for (const [key, qcm] of this._list.entries()) {
      if (qcm[0] === qcmId) return key
    }
  }

  async getMiniatures (): Promise<void> {
    this._miniatures = []
    for (const datas of this._list) {
      await Question.load(datas[2]).then(question => { this._miniatures.push(question) }).catch(err => {
        console.error('Erreur de récupération de vignette', err)
        // correction de l'erreur par suppression de la référence.
        // this._list.splice(index, 1)
      })
    }
  }

  updateQcm (qcm: Qcm): void {
    for (const [index, qcmEntry] of this.list.entries()) {
      if (qcm.id === qcmEntry[0]) {
        this.list[index] = [qcm.id, qcm.name, qcm.questionsList[0], qcm.questionsList.length]
        break
      }
    }
  }

  static remake (qcms: QcmList): entry[] {
    const newQcms = new QcmList()
    for (const qcmData of qcms._list) {
      newQcms._list.push(qcmData)
    }
    return newQcms.list
  }

  async export (): Promise<Blob> {
    const content: { uid: string, data: QcmList, qcms: Qcm[] } = { uid: 'QCMCamQcms', data: this, qcms: [] }
    for (const qcm of this.list) {
      const theQcm = await Qcm.load(qcm[0])
      content.qcms.push(await theQcm.export())
    }
    const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })
    return blob
  }

  get list (): entry[] {
    return this._list
  }

  set list (qcms: entry[]) {
    this._list = qcms
  }

  get miniatures (): Question[] {
    return this._miniatures
  }
}
