<script lang="ts">
  import { currentAction } from '../../lib/ts/services/store'
  import ModalWaitAction from './ModalWaitAction.svelte'
  import QcmEdition from './QCMEdition.svelte'
  import QcmSelection from './selection/QCMSelection.svelte'

  let selectedQcmId: number = 0
</script>

{#if $currentAction === 'edit'}
  <QcmEdition
    {selectedQcmId}
  />
{:else}
  <QcmSelection
    bind:selectedQcmId
  />
{/if}
<ModalWaitAction />
