<script lang="ts">
  import { hideModal } from '../../lib/ts/services/modal'

  let modal: HTMLElement
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-newuser" bind:this={modal}>
  <div class="modal-background" on:click|self={() => hideModal(modal)}></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Inscription à QCMCam</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-newuser" on:click={() => hideModal(modal)}></button>
    </header>
    <div class="modal-card-body">
      <div class="is-small">Adresse mail</div>
      <div class="control has-icons-left has-icons-right">
        <input type="email" class="input" placeholder="mail ou identifiant">
        <span class="icon is-left">
          <i class="ri-mail-line"></i>
        </span>
        <span class="icon is-right">
          <i class="ri-check-fill"></i>
        </span>
      </div>
      <div class="is-small">Choisir votre mot de passe</div>
      <div class="control has-icons-left">
        <input type="password" class="input" placeholder="●●●●●●●●">
        <span class="icon is-left">
          <i class="ri-lock-line"></i>
        </span>
      </div>
      <div class="is-small mt-4 mb-4">
        <input type="checkbox" class="checkbox">
        J'ai lu les <a href="cgu.html">conditions d'utilisation de QCMCam</a> et les accepte sans
        restriction.
      </div>
      <button class="button">Valider</button>
    </div>
  </div>
</div>
