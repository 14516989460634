import utils from './utils.js'
import AR from '../../js/aruco.js'

const detector = new AR.Detector()
let videoReady = false
let videoSource: HTMLVideoElement

const detection = {
  start (source: HTMLVideoElement): boolean {
    if (source === undefined) return false
    videoSource = source
    return true
  },
  stop () {
    videoReady = false
  },
  isStarted () {
    return videoReady
  },
  detectAnswers (markers: any[]) {
    const answers: Record<number, string> = {}
    for (let i = 0; i < markers.length; i++) {
      const corners = markers[i].corners
      let reponse = ''
      const dx = corners[0].x - corners[2].x
      const dy = corners[0].y - corners[2].y
      if (dx > 0) {
        if (dy > 0) {
          reponse = 'C'
        } else if (dy < 0) {
          reponse = 'D'
        }
      } else if (dx < 0) {
        if (dy > 0) {
          reponse = 'B'
        } else if (dy < 0) {
          reponse = 'A'
        }
      }
      answers[markers[i].id] = reponse
    }
    return answers
  },
  drawMarkers (markers: any[]) {
    const canvas = utils.DOM.getById('cameraCopy') as HTMLCanvasElement
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = canvas.getContext('2d')!
    let corners, corner, i, j, x, y, xmax, ymax
    context.strokeStyle = 'red'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    for (i = 0; i !== markers.length; ++i) {
      // answers.affecte(markersIdUsed[markers[i].id], camera.derterminateResponse(markers[i].corners))
      corners = markers[i].corners
      x = Infinity
      y = Infinity
      xmax = -Infinity
      ymax = -Infinity
      context.beginPath()
      context.moveTo(corners[0].x, corners[0].y)
      context.lineWidth = 3
      context.fillStyle = 'rgba(0,0,5,0.6)'
      for (j = 0; j < 4; j++) {
        corner = corners[(j + 1) % 4]
        context.lineTo(corner.x, corner.y)
        x = Math.min(x, corner.x)
        y = Math.min(y, corner.y)
        xmax = Math.max(xmax, corner.x)
        ymax = Math.max(ymax, corner.y)
      }
      context.closePath()
      context.stroke()
      context.fill()
      // id du marker
      context.fillStyle = 'white'
      context.lineWidth = 1
      context.font = String(Math.max((ymax - y) / 2)) + 'px Arial' // defaut : 25 px
      // Attention, l'id du marqueur doit être augmenté de 1 pour correspondre au marqueur distribué qui est décalé de 1.
      if (markers[i].id !== undefined) {
        context.fillText(String(Number(markers[i].id) + 1), (x + xmax) / 2, (y + ymax) / 2)
      } else {
        context.fillText('# ' + String(Number(markers[i].id) + 1) + ' #', (x + xmax) / 2, (y + ymax) / 2)
      }
    }
  },
  detect () {
    const canvas = utils.DOM.getById('cameraCopy') as HTMLCanvasElement
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = canvas.getContext('2d')!
    let ok = false
    // copie la vidéo dans une image.
    if (videoSource.readyState === 4) {
      if (!videoReady && videoSource.videoWidth > 0) {
        videoReady = true
        if (canvas !== null) {
          canvas.width = videoSource.videoWidth
          canvas.height = videoSource.videoHeight
          canvas.style.aspectRatio = utils.image.getAspectRatio(Number(canvas.width), Number(canvas.height))
          canvas.style.height = '100%'
        }
      }
      if (canvas.width > 0) {
        context.drawImage(videoSource, 0, 0, canvas.width, canvas.height)
        ok = true
      }
    }
    if (ok) {
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
      const markers = detector.detect(imageData)
      this.drawMarkers(markers)
      return this.detectAnswers(markers)
    }
  }
}

export { detection as default }
