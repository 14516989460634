export const DEFAULT_VIEW = 'home'
export const viewValidKeys = <const>[DEFAULT_VIEW, 'qcm', 'group', 'stats', 'show', 'marker', 'about']
type ViewValidKeysType = typeof viewValidKeys
export type View = ViewValidKeysType[number]
export function isView (obj: unknown): obj is View {
  if (obj == null || typeof obj !== 'string') return false
  return viewValidKeys.includes(obj as View)
}
export function isViews (obj: unknown[]): obj is View[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isView)
}

export const qcmActionValidKeys = <const>['select', 'edit']
type QcmActionValidKeysType = typeof qcmActionValidKeys
export type QcmAction = QcmActionValidKeysType[number]
export function isQcmAction (obj: unknown): obj is QcmAction {
  if (obj == null || typeof obj !== 'string') return false
  return qcmActionValidKeys.includes(obj as QcmAction)
}
export function isQcmActions (obj: unknown[]): obj is QcmAction[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isQcmAction)
}

export const showActionValidKeys = <const>['setup', 'play']
type ShowActionValidKeysType = typeof showActionValidKeys
export type ShowAction = ShowActionValidKeysType[number]
export function isShowAction (obj: unknown): obj is ShowAction {
  if (obj == null || typeof obj !== 'string') return false
  return showActionValidKeys.includes(obj as ShowAction)
}
export function isShowActions (obj: unknown[]): obj is QcmAction[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isShowAction)
}

export const markerActionValidKeys = <const>['download', 'generate']
type MarkerActionValidKeysType = typeof markerActionValidKeys
export type MarkerAction = MarkerActionValidKeysType[number]
export function isMarkerAction (obj: unknown): obj is MarkerAction {
  if (obj == null || typeof obj !== 'string') return false
  return markerActionValidKeys.includes(obj as MarkerAction)
}
export function isMarkerActions (obj: unknown[]): obj is QcmAction[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isMarkerAction)
}

const actionValidKeys = ['', ...qcmActionValidKeys, ...markerActionValidKeys, ...showActionValidKeys]
export type Action = '' | QcmAction | MarkerAction | ShowAction
export function isAction (obj: unknown): obj is Action {
  if (obj == null || typeof obj !== 'string') return false
  return actionValidKeys.includes(obj as Action)
}
export function isActions (obj: unknown[]): obj is Action[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isAction)
}
