<script lang="ts">
  import { displayQcmEditor } from '../../lib/ts/services/qcm'
  import { qcms } from '../../lib/ts/services/store'
  import utils from '../../lib/ts/services/utils'
  import QcmsList from '../../lib/ts/models/qcmsList.js'
  import { hideModal } from '../../lib/ts/services/modal'

  export let listQcms: () => void
  export let selectedQcmId: number
  let modal: HTMLElement

  function replaceMathJax(str: string): string {
    return str
      .replace (/(?<!\\)\\\(/g, "<span class='math-tex'>")
      .replace (/(?<!\\)\\\)/g, "</span>")
  }

  function openQCMFile (qcms: QcmsList, event: Event): void {
    if (event.target !== null) {
      const importModalFoot = document.querySelector('#qm-import-qcm .has-text-danger')
      const input = event.target as unknown as HTMLInputElement
      if (input.files === null) {
        return
      }
      for (let i = 0, len = input.files.length; i < len; i++) {
        const file = input.files[i]
        const reader = new FileReader()
        reader.onload = async function () {
          if (typeof reader.result === 'string') {
            let data = replaceMathJax(reader.result)
            let result
            try { result = JSON.parse(data) } catch (e) { console.log(e) }
            if (result.uid !== undefined) {
              let resultOfImport = await qcms.importQcmList(data, file.name)
              if (resultOfImport) {
                if (i === len - 1) {
                  utils.DOM.removeClass('qm-import-qcm', 'is-active')                  
                }
                listQcms()
              } else {
                (importModalFoot as HTMLElement).innerText = 'Erreur d\'import'
              }
            } else {
              const qcm = await qcms.importQcm(data, file.name)
              if (qcm !== undefined && i === len - 1) {
                // listQcms()
                selectedQcmId = qcms.list.length - 1
                displayQcmEditor()
                utils.DOM.removeClass('qm-import-qcm', 'is-active')
              } else {
                (importModalFoot as HTMLElement).innerText = 'Erreur d\'import'
              }
            }
          }
        }
        reader.onerror = function () {
          if (importModalFoot !== null) {
            (importModalFoot as HTMLElement).innerText = 'Erreur de chargement'
          }
        }
        reader.readAsText(file)
      }
    }
  }
function importOnlineFile (qcms: QcmsList, event: Event): void {
  if (event.target !== null) {
    const url = (event.target as HTMLInputElement).value
    let testurl
    // analyse de la validité de l'url
    try {
      testurl = new URL(url)
    } catch (e) {
      return
    }
    const importModalFoot = document.querySelector('#qcm-import-qcm .has-text-danger')
    const pathName = testurl.pathname
    const fileName = pathName.split('/').pop()
    let nomDuFichier = window.prompt('Donnez un nom au QCM :', fileName)
    if (nomDuFichier === null) {
      nomDuFichier = 'QCM importé'
    }
    const reader = new XMLHttpRequest()
    reader.open('GET', 'phploader.php?url=' + encodeURIComponent(url), false)
    reader.onreadystatechange = () => {
      if (reader.readyState === 4) {
        if (reader.status === 200 || reader.status === 0) {
          try {
            const qcm = qcms.importQcm(reader.responseText, nomDuFichier)
            if (qcm !== undefined) {
              listQcms()
              selectedQcmId = qcms.list.length - 1
              displayQcmEditor()
              utils.DOM.removeClass('qm-import-qcm', 'is-active')
            } else if (importModalFoot !== null) {
              (importModalFoot as HTMLElement).innerText = 'Erreur de format de fichier'
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
    reader.send(null)
  }
}
function importQCMFromText (qcms: QcmsList, event: Event): void {
  if (event.target !== null) {
    const importModalFoot = document.querySelector('#qcm-import-qcm .has-text-danger')
    const input = event.target as HTMLInputElement
    if (input.value !== '') {
      let Titre = window.prompt('Donnez un nom au QCM :')
      if (Titre === null) {
        Titre = 'QCM importé'
      }
      const qcm = qcms.importQcm(input.value, Titre)
      if (qcm !== undefined) {
        listQcms()
        selectedQcmId = qcms.list.length - 1
        displayQcmEditor()
        utils.DOM.removeClass('qm-import-qcm', 'is-active')
      } else if (importModalFoot !== null) {
        (importModalFoot as HTMLElement).innerText = 'Erreur de format de fichier'
      }
    }
  }
}
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-import-qcm" bind:this={modal}>
  <div class="modal-background" on:click|self={() => hideModal(modal)}></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Import de QCM</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-import-qcm" on:click={() => hideModal(modal)}></button>
    </header>
    <section class="modal-card-body">
      <div class="label">Fichier à importer depuis l'ordinateur</div>
      <div class="file is-centered">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            id="import-qcm-file"
            name="resume"
            accept=".txt"
            on:change={(event) => { openQCMFile($qcms, event) }}
            multiple
          >
          <span class="file-cta">
            <span class="file-icon">
              <i class="ri-upload-2-line"></i>
            </span>
            <span class="file-label">
              Fichier…
            </span>
          </span>
          <span class="file-name">Fichier txt</span>
        </label>
      </div>
      <div class="label">Fichier en ligne</div>
      <input
        type="text"
        id="import-qcm-url"
        class="input"
        placeholder="Coller l'adresse internet du fichier"
        on:input={(event) => { importOnlineFile($qcms, event) }}
      >
      <div class="label">Contenu de fichier copié/collé</div>
      <input
        type="text"
        id="import-qcm-text"
        class="input"
        placeholder="Coller ici les données"
        on:input={(event) => { importQCMFromText($qcms, event) }}
      >
    </section>
    <footer class="modal-card-foot">
      <a href="blabla.html">Importer des qcm ?</a>
      <span class="has-text-danger"></span>
    </footer>
  </div>
</div>
