<script lang="ts">
  import ModalCallRoll from './ModalCallRoll.svelte'
  import ModalGroupMaker from './ModalGroupMaker.svelte'
  import { onMount } from 'svelte'
  import { currentGroup, groups, qcms, config } from '../../../lib/ts/services/store'
  import Group from '../../../lib/ts/models/group'
  import type QcmList from '../../../lib/ts/models/qcmsList'
  import storage from '../../../lib/ts/services/storage'
  import utils from '../../../lib/ts/services/utils'
  import camera from '../../../lib/ts/services/camera'
  import { showModal } from '../../../lib/ts/services/modal'
  import type Student from '../../../lib/ts/models/student'

  export let startQCM: (groups: Group[]) => Promise<void>

  let mode: 'normal' | 'double' | 'duel' = 'normal'
  let filter1 = 'hidden', filter2 = 'hidden'
  let initialSelect: string[] = ['', '']

  onMount(() => {
    QCMCamOptions($groups, $qcms)
  })

  async function QCMCamOptions (groups: Group[], qcms: QcmList): Promise<void> {
    const $selectCameraChoice = document.getElementById('selectCameraChoice') as HTMLSelectElement
    const selectQCM = utils.DOM.getById('select-qcm-choice') as HTMLSelectElement
    const selectQCM0 = utils.DOM.getById('select-qcm-choice0') as HTMLSelectElement
    if ($selectCameraChoice.childNodes.length < 1) {
      await refreshCamerasList($selectCameraChoice)
    }
    const promises = [
      storage.db.store_data.get('QCMCamlatestSelectedGroup'),
      storage.db.store_data.get('QCMCamlatestSelectedQCM')
    ]
    Promise.all(promises).then((values) => {
      const latestSelectedGroup = values[0].data
      const latestSelectedQCM = values[1].data
      const $selectGroup = utils.DOM.getById('select-group-choice')
      if ($selectGroup !== null && selectQCM !== null) {
        $selectGroup.innerHTML = ''
        selectQCM.innerHTML = ''
        selectQCM0.innerHTML = ''
        for (const group of groups) {
          const option = utils.DOM.createOption({ value: String(group.id), text: group.name })
          if (latestSelectedGroup === String(group.id)) option.selected = true
          option.onclick = () => {
            currentGroup.set(group)
            setQcmGroupSelect()
            storage.db.store_data.put({ uid: 'QCMCamlatestSelectedGroup', data: String(group.id) })
          }
          $selectGroup.appendChild(option)
        }
        const $nbQuestions = utils.DOM.getById('qcm-nb-slides') as HTMLSpanElement
        const $nbQuestions0 = utils.DOM.getById('qcm-nb-slides0') as HTMLSpanElement
        let nbOfQuestions = 0
        let nbOfQuestions0 = 0
        if ($config.isAlphabeticallySorted) {
          qcms.list.sort((a, b) => a[1].localeCompare(b[1]))
        }
        for (const qcm of qcms.list) {
          const option = utils.DOM.createOption({ value: String(qcm[0]), text: qcm[1] })
          const option0 = utils.DOM.createOption({ value: String(qcm[0]), text: qcm[1] })
          option.dataset.nbOfQuestions = String(qcm[3])
          option0.dataset.nbOfQuestions = String(qcm[3])
          if (latestSelectedQCM === String(qcm[0])) {
            option.selected = true
            nbOfQuestions = qcm[3]
          }
          if ($nbQuestions !== null) $nbQuestions.innerText = '(' + String(nbOfQuestions) + ')'

          option.onclick = () => {
            nbOfQuestions = 0
            storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: String(qcm[0]) })
            // mise à jour du nombre de questions
            if (selectQCM !== null) {
              selectQCM.querySelectorAll('option').forEach((oneOption) => {
                if (oneOption.selected) {
                  nbOfQuestions += Number(oneOption.dataset.nbOfQuestions)
                }
              })              
              $nbQuestions.innerText = '(' + String(nbOfQuestions) + ')'
              // mise à jour du nombre max du champ extrait
              const inputSlideNumber = utils.DOM.getById('qcm-slides-number') as HTMLInputElement
              inputSlideNumber.max = String(nbOfQuestions - 1)
              if (Number(inputSlideNumber.value) > nbOfQuestions - 1) inputSlideNumber.value = String(nbOfQuestions - 1)
            }
          }
          option0.onclick = () => {
            nbOfQuestions0 = 0
            if (selectQCM0 !== null) {
              selectQCM0.querySelectorAll('option').forEach((oneOption) => {
                if (oneOption.selected) {
                  nbOfQuestions0 += Number(oneOption.dataset.nbOfQuestions)
                }
              })
              $nbQuestions0.innerText = '(' + String(nbOfQuestions0) + ')'
              // mise à jour du nombre max du champ extrait
              const inputSlideNumber = utils.DOM.getById('qcm-slides-number0') as HTMLInputElement
              inputSlideNumber.max = String(nbOfQuestions0 - 1)
              if (Number(inputSlideNumber.value) > nbOfQuestions0 - 1) inputSlideNumber.value = String(nbOfQuestions0 - 1)
            }
          }
          selectQCM.appendChild(option)
          selectQCM0.appendChild(option0)
        }
        initialSelect = [selectQCM.innerHTML, selectQCM0.innerHTML]
      }
    }).catch(error => {
      console.log(error)
      const $selectGroup = utils.DOM.getById('select-group-choice')
      if ($selectGroup !== null && selectQCM !== null) {
        $selectGroup.innerHTML = ''
        selectQCM.innerHTML = ''
        for (const group of groups) {
          const option = utils.DOM.createOption({ value: group.name, text: group.name })
          option.onclick = () => { storage.db.store_data.put({ uid: 'QCMCamlatestSelectedGroup', data: group.name }) }
          $selectGroup.appendChild(option)
        }
        for (const qcm of qcms.list) {
          const option = utils.DOM.createOption({ value: String(qcm[0]), text: qcm[1] })
          option.onclick = () => { storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: String(qcm[0]) }) }
          selectQCM.appendChild(option)
        }
      }
    })
  }

  async function refreshCamerasList (container?: HTMLSelectElement): Promise<void> {
    if (container === undefined) {
      container = utils.DOM.getById('selectCameraChoice') as HTMLSelectElement
    }
    await camera.detect().then((data) => {
      if (container !== null) {
        container.innerHTML = '<option value="-1">Pas de caméra</option>'
        if (typeof data !== 'string') {
          data.forEach(element => {
            const option = utils.DOM.createOption({ text: element[1], value: String(element[2]) })
            container.appendChild(option)
          })
        } else {
          alert(data)
        }
        container.onchange = () => {
          camera.setSource(Number(container.value))
          const $cameraVideo = document.getElementById('videotest') as HTMLVideoElement
          if ($cameraVideo !== null) {
            camera.start($cameraVideo)
          }
        }
      }
    })
  }
  function setQcmGroupSelect() {
    if (mode !== 'double') return
    // récupération de la liste des groupes sélectionnés
    const $selectGroup = utils.DOM.getById('select-group-choice') as HTMLSelectElement
    const selectedGroups = []
    if ($selectGroup !== null) {
      for (const option of Array.from($selectGroup.options)) {
        if (option.selected) {
          selectedGroups.push(Number(option.index))
        }
      }
      if (selectedGroups.length > 2) {
        alert('Trop de groupes sélectionnés')
      }
      if (selectedGroups.length === 2) {
        // alimenter les radios avec les noms des groupes
        document.getElementById('groupqcmselect0label').innerHTML = $groups[selectedGroups[0]].name
        document.getElementById('groupqcmselect0').value = $groups[selectedGroups[0]].id
        document.getElementById('groupqcmselect20label').innerHTML = $groups[selectedGroups[0]].name
        document.getElementById('groupqcmselect20').value = $groups[selectedGroups[0]].id
        document.getElementById('groupqcmselect1label').innerHTML = $groups[selectedGroups[1]].name
        document.getElementById('groupqcmselect1').value = $groups[selectedGroups[1]].id
        document.getElementById('groupqcmselect21label').innerHTML = $groups[selectedGroups[1]].name
        document.getElementById('groupqcmselect21').value = $groups[selectedGroups[1]].id
      } else if (selectedGroups.length === 1) {
        // alimenter les radios avec les sous-groupes s'ils existent
        if ($groups[selectedGroups[0]].subgroups[0].length > 0) {
          document.getElementById('groupqcmselect0label').innerHTML = 'Groupe A'
          document.getElementById('groupqcmselect0').value = String($groups[selectedGroups[0]].id) + ',0'
          document.getElementById('groupqcmselect20label').innerHTML = 'Groupe A'
          document.getElementById('groupqcmselect20').value = String($groups[selectedGroups[0]].id) + ',0'
          document.getElementById('groupqcmselect1label').innerHTML = 'Groupe B'
          document.getElementById('groupqcmselect1').value = String($groups[selectedGroups[0]].id) + ',1'
          document.getElementById('groupqcmselect21label').innerHTML = 'Groupe B'
          document.getElementById('groupqcmselect21').value = String($groups[selectedGroups[0]].id) + ',1'
        }
      }
    }
  }

  function checkGroups(a:number, b:number) {
    let groupOfRadio = ''
    if (a === 0) groupOfRadio = '2'
    b = 1 - b
    const otherRadio = document.getElementById('groupqcmselect' + groupOfRadio + String(b)) as HTMLInputElement
    otherRadio.checked = true
  }

  function createSubgroups (groups: Group[]) {
    // vérifier qu'il n'y a qu'un groupe sélectionné
    const groupChoice = utils.DOM.getById('select-group-choice') as HTMLSelectElement
    const groupIds = []
    // nombre d'éléments sélectionnés
    for (const option of Array.from(groupChoice.options)) {
      if (option.selected && option.value !== '') {
        groupIds.push(option.value)
      }
    }
    if (groupIds.length === 0) {
      alert('Veuillez selectionner un groupe')
    } else if (groupIds.length > 1) {
      alert('Veuillez ne selectionner qu’un seul groupe')
    } else {
      // récupération des élèves du groupe
      currentGroup.set(Group.getGroupById(Number(groupIds[0]), groups))
      // afficher les élèves
      if ($currentGroup !== undefined) {
        // check si des sous groupes existent déjà dans le groupe
        if ($currentGroup.subgroups[0].length === 0) {
          for (const key in $currentGroup.students) {
            $currentGroup.moveToSubgroup(0, Number(key))
          }
        }
        displaySubgroups($currentGroup)
      }
    }
  }

  function qcmsFilter(number: string): void {
    const selectQCM = utils.DOM.getById('select-qcm-choice') as HTMLSelectElement
    const selectQCM0 = utils.DOM.getById('select-qcm-choice0') as HTMLSelectElement
    const qcmChoiceNumber = number
    const value = (utils.DOM.getById('qcm-filter' + qcmChoiceNumber) as HTMLInputElement).value
    if (value.length < 2) {
      return
    }
    const selectQCMFiltered = qcmChoiceNumber === '' ? selectQCM : selectQCM0
    selectQCMFiltered.innerHTML = ''
    const $nbQuestions = utils.DOM.getById('qcm-nb-slides' + qcmChoiceNumber)
    let nbOfQuestions = 0
    const extractChoice = [...$qcms.list]
    if ($config.isAlphabeticallySorted) {
      extractChoice.sort((a, b) => a[1].localeCompare(b[1]))
    }
    for (const qcm of extractChoice) {
      if (!qcm[1].toLowerCase().includes(value.toLowerCase())) {
        continue
      }
      const option = utils.DOM.createOption({ value: String(qcm[0]), text: qcm[1] })
      option.dataset.nbOfQuestions = String(qcm[3])
      if ($nbQuestions !== null) $nbQuestions.innerText = '(' + String(nbOfQuestions) + ')'
      option.onclick = () => {
        nbOfQuestions = 0
        storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: String(qcm[0]) })
        // mise à jour du nombre de questions
        if ($nbQuestions !== null) {
          selectQCMFiltered.querySelectorAll('option').forEach((oneOption) => {
            if (oneOption.selected) {
              nbOfQuestions += Number(oneOption.dataset.nbOfQuestions)
            }
          })
          $nbQuestions.innerText = '(' + String(nbOfQuestions) + ')'
          // mise à jour du nombre max du champ extrait
          const inputSlideNumber = utils.DOM.getById('qcm-slides-number'+qcmChoiceNumber) as HTMLInputElement
          inputSlideNumber.max = String(nbOfQuestions - 1)
          if (Number(inputSlideNumber.value) > nbOfQuestions - 1) inputSlideNumber.value = String(nbOfQuestions - 1)
        }
      }
      selectQCMFiltered.appendChild(option)
    }
  }

  function restoreQcmList (number: string): void {
    const selectQCM = utils.DOM.getById('select-qcm-choice') as HTMLSelectElement
    const selectQCM0 = utils.DOM.getById('select-qcm-choice0') as HTMLSelectElement
    const selectQCMFiltered = number === '' ? selectQCM : selectQCM0
    selectQCMFiltered.innerHTML = initialSelect[number === '' ? 0 : 1]
  }

  function displaySubgroups (group: Group): void {
    // zones pour la répartition
    const $containerA = utils.DOM.getById('gpA-container') as HTMLElement
    const $containerB = utils.DOM.getById('gpB-container') as HTMLElement
    $containerA.innerHTML = ''
    $containerB.innerHTML = ''
    const containers = [$containerA, $containerB]
    for (const [key, subgroup] of group.subgroups.entries()) {
      containers[key].innerHTML = '';
      ((containers[key].parentNode as HTMLElement).querySelector('.label') as HTMLLabelElement).innerText = 'Groupe ' + ['A', 'B'][key] + ' (' + String(subgroup.length) + ')'
      for (const studentId of subgroup) {
        const student = group.students[studentId]
        if (student === undefined) {
          // suppression du sous groupe
          group.subgroups[key].splice(group.subgroups[key].indexOf(Number(studentId)), 1)
          group.save()
          displaySubgroups(group)
          return // le student n'existe pas/plus
        }
        const studentCard = createStudentCard(student)
        studentCard.onclick = () => {
          group.moveToSubgroup(1 - key, student.id)
          displaySubgroups(group)
        }
        containers[key].appendChild(studentCard)
      }
    }
  }
  function createStudentCard (student: Student): HTMLElement {
    const card = utils.DOM.create('article', { class: 'student pointer', id: 'gp-student' + String(student.markerId) })
    card.dataset.markerId = String(student.markerId)
    const cardMarker = utils.DOM.create('div', { class: 'markerid', text: String(student.markerId) })
    const cardName = utils.DOM.create('div', { class: 'studentname', text: (student.name ?? '') + ' ' + (student.firstname ?? '') })
    card.appendChild(cardMarker)
    card.appendChild(cardName)
    return card
  }
</script>

<div id="show-settings" class="tab mx-6">
  <h1 class="title is-2 has-text-centered">Paramètres du diaporama</h1>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Mode</b>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="normal" name="qcm-mode-choice"
          id="qcm-mode-choice0"
          checked={true}
          on:click={() => { mode = 'normal' }}
        >
          <label for="qcm-mode-choice0">Normal</label>
          <p class="help">1 groupe pour 1 QCM</p>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="double" name="qcm-mode-choice"
          id="qcm-mode-choice1"
          on:click={() => { mode = 'double';   setQcmGroupSelect(); }}
        >
          <label for="qcm-mode-choice1">Double QCM</label>
          <p class="help">2 QCM pour 2 (sous-)groupes</p>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="duel" name="qcm-mode-choice"
          id="qcm-mode-choice2"
          on:click={() => { mode = 'duel' }}
        >
        <label for="qcm-mode-choice2">Duel de QCM</label>
        <p class="help">2 (sous-)groupes avec le même QCM</p>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column has-text-centered">
        <b>Groupe</b><br>Deux possible<br>
        <select name="group-choice" class="select is-primary is-multiple border border-gray-400" id="select-group-choice" size="5"
          style="width: 50%; overflow: hidden;" multiple={true}>
        </select><br>
        <button class="button" on:click={() => {
          showModal('qm-gp-maker')
          createSubgroups($groups)
        }}>Sous-groupes</button>
      </div>
      <div class="column has-text-centered has-background-light">
        <b>QCM</b><button title="Filtrer les QCM" id="icon-filter" on:click={() => {if(filter1 === 'hidden') filter1 = ''; else {filter1 = 'hidden'; restoreQcmList('')}}}><i class="ri-filter-line"></i></button>
        <input type="text" placeholder="Commence par..." class="input {filter1}" id="qcm-filter" on:input={() => qcmsFilter('')}><br>
        Multisélection possible<br>
        <select name="qcm-choice" class="select is-info is-multiple border border-gray-400" id="select-qcm-choice" size="5"
          style="width: 95%;" multiple={true}>
        </select><br>
        <div id="groupqcmselect" class="{mode === 'double' ? '' : 'is-hidden'}">
          <input type="radio" name="groupqcmselect" class="is-checkradio is-info" id="groupqcmselect0" on:click={() => checkGroups(0,0)} checked> 
          <label for="groupqcmselect0" id="groupqcmselect0label">Groupe A</label>
          <input type="radio" name="groupqcmselect" class="is-checkradio is-info" id="groupqcmselect1"  on:click={() => checkGroups(0,1)}> 
          <label for="groupqcmselect1" id="groupqcmselect1label">Groupe B</label>
        </div>
      </div>
      <div
        class="column has-text-centered has-background-light {mode === 'double' ? '' : 'is-hidden'}"
        id="qcm-choice2">
        <b>QCM 2</b>
        <button id="icon-filter" title="Filtrer les QCM" on:click={() => {if(filter2 === 'hidden') filter2 = ''; else {filter2 = 'hidden'; restoreQcmList('0')}}}><i  class="ri-filter-line"></i></button>
        <input type="text" placeholder="Commence par..." class="input {filter2}" id="qcm-filter0" on:input={() => qcmsFilter('0')}><br>
        Multisélection possible<br>
        <select name="qcm-choice0" class="select is-info is-multiple" id="select-qcm-choice0" size="5"
          style="width: 95%;" multiple={true}>
        </select><br>
        <div id="groupqcmselect2">
          <input type="radio" name="groupqcmselect2" class="is-checkradio is-info" id="groupqcmselect20" on:click={() => checkGroups(1,0)}> 
          <label for="groupqcmselect20" id="groupqcmselect20label">Groupe A</label>
          <input type="radio" name="groupqcmselect2" class="is-checkradio is-info" id="groupqcmselect21" on:click={() => checkGroups(1,1)} checked> 
          <label for="groupqcmselect21" id="groupqcmselect21label">Groupe B</label>
        </div>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Commencer</b>
        <div class="field">
          &nbsp;<input type="radio" class="is-checkradio is-info" value="slider" name="qcm-start"
            id="qcm-start-choice0" checked={true}>
          <label for="qcm-start-choice0">au démarrage</label><br>
          <input type="radio" class="is-checkradio is-info" value="hidden" name="qcm-start" id="qcm-start-choice1">
          <label for="qcm-start-choice1">diapo caché</label><br>
          <input type="radio" class="is-checkradio is-info" name="qcm-start" id="qcm-start-choice2" value="appel">
          <label for="qcm-start-choice2">par l'appel</label>
        </div>
      </div>
      <div class="column has-background-light">
        <b>Paramètres QCM</b>
        <div class="field">
          <p>Questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" value="0" name="qcm-slides"
            id="qcm-slides-choice0" checked={true}>
          <label for="qcm-slides-choice0">Toutes <span id="qcm-nb-slides">(0)</span></label><br>
          <input type="radio" class="is-checkradio is-info" value="extract" name="qcm-slides"
            id="qcm-slides-choice1">
          <label for="qcm-slides-choice1">un extrait de</label>
          <input type="number" name="qcm-slides-number" id="qcm-slides-number" size="4" min="1" max="20" value="3">
          questions
        </div>
        <div class="field">
          <p>Ordre des questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-questions" id="qcm-order-questions-choice0" checked={true} value="ordered"><label for="qcm-order-questions-choice0">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-questions" id="qcm-order-questions-choice1" value="random"><label for="qcm-order-questions-choice1" title="si autorisé">aléatoire</label>
        </div>
        <div class="field">
          <p>Ordre des réponses</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-answers" id="qcm-order-answers-choice0" checked={true} value="ordered"><label for="qcm-order-answers-choice0">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-answers" id="qcm-order-answers-choice1" value="random"><label for="qcm-order-answers-choice1" title="si autorisé">aléatoire (si autorisé)</label>
        </div>
      </div>
      <div class="column {mode === 'double' ? '' : 'is-hidden'}" id="qcm-questions-choice2">
        <b>Paramètres QCM 2</b>
        <div class="field">
          <p>Questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" value="0" name="qcm-slides0"
            id="qcm-slides-choice00" checked={true}>
          <label for="qcm-slides-choice00">Toutes <span id="qcm-nb-slides0">(0)</span></label><br>
          <input type="radio" class="is-checkradio is-info" value="extract" name="qcm-slides0"
            id="qcm-slides-choice10">
          <label for="qcm-slides-choice10">un extrait de</label>
          <input type="number" name="qcm-slides-number0" id="qcm-slides-number0" size="4" min="1" max="20" value="3">
          questions
        </div>
        <div class="field">
          <p>Ordre des questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-questions0"
            id="qcm-order-questions-choice00" checked={true} value="ordered"><label for="qcm-order-questions-choice00">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-questions0"
            id="qcm-order-questions-choice01" value="random"><label for="qcm-order-questions-choice01" title="si autorisé">aleatoire</label>
        </div>
        <div class="field">
          <p>Ordre des réponses</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-answers0"
            id="qcm-order-answers-choice00" checked={true} value="ordered"><label for="qcm-order-answers-choice00">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-answers0"
            id="qcm-order-answers-choice01" value="random"><label for="qcm-order-answers-choice01" title="si autorisé">aleatoire</label>
        </div>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Caméra</b>
        <div class="field">
          &nbsp;<input type="radio" class="is-checkradio" value="local" name="camera-mode-choice"
            id="camera-mode-choice0" checked={true}>
          <label for="camera-mode-choice0">Branchée à l'ordinateur</label><br>
          <input type="radio" class="is-checkradio" value="deported" name="camera-mode-choice"
            id="camera-mode-choice1">
          <label for="camera-mode-choice1">Déportée (smartphone...)</label>
        </div>
      </div>
      <div class="column">
        <div>
          Camera : <select id="selectCameraChoice"></select> <button on:click={() => refreshCamerasList()} title="Rafraichir la liste des caméras"><i class="ri-refresh-line"></i></button>
        </div>
        <video autoplay muted playsinline id="videotest" class="is-hidden" style="width:150px;margin: 1em 0 0 3em;"></video>
      </div>
    </div>
  </div>
  <footer class="modal-card-foot is-justify-content-center">
    <button class="button is-light is-large" on:click={() => { startQCM($groups).catch(err => { console.log(err) }) }}>
      <img src="images/play-circle-line.svg" alt="Play button">
    </button>
  </footer>
</div>
<ModalCallRoll
  {startQCM}
/>
<ModalGroupMaker
  {displaySubgroups}
/>
