<script lang="ts">
  import { onMount } from 'svelte'
  import utils from '../../../lib/ts/services/utils'
  import Qcm from '../../../lib/ts/models/qcm'
  import storage from '../../../lib/ts/services/storage'

  export let qcms
  export let index
  export let listQcms: () => void
  export let updateSelectedQcmId: (id: number) => void
  export let displayQcmEditor: () => void

  let id: string
  let title: string

  onMount(() => {
    id = qcms.list[index][0]
    title = qcms.list[index][1]
  })

  const editQcm = () => {
    try {
      storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: id })
      updateSelectedQcmId(index)
      displayQcmEditor()
    } catch (e) {
      alert('Erreur lors du chargement du QCM')
      console.warn(e)
    }
  }

  const copyQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const newQcm = await qcm.copy()
      await newQcm.save()
      qcms.list.push([newQcm.id, newQcm.name, newQcm.questionsList[0], newQcm.questionsList.length])
      await qcms.save()
      listQcms()
    } catch (err) {
      console.error('Erreur de copie', err)
    }
  }

  const downloadQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const content = await qcm.export()
      const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })
      utils.file.download(blob, `${qcm.name}.txt`)
    } catch (err) {
      console.warn('oupsi export qcm !', err)
    }
  }

  const deleteQcm = async () => {
    if (confirm(`Vous allez supprimer le qcm \n${title}\nConfirmez-vous ?`)) {
      await qcms.deleteQcmById(id)
      await qcms.save()
      listQcms()
    }
  }
</script>

<div class="qcm-line">
    <div class="floatting">
      <input type="checkbox" value={id} id="qcm-selection-{index}" class="check-selection">
    </div>
    <label for="qcm-selection-{index}" class="qcm-title">{title}</label>
    <div class="button-list">
        <button class="btn-select-qcm" title="Modifier" on:click={editQcm}>
        <i class="ri-edit-line"></i>
        </button>
        <button class="btn-select-qcm" title="Dupliquer" on:click={copyQcm}>
        <i class="ri-file-copy-line"></i>
        </button>
        <button class="btn-select-qcm" title="Exporter" on:click={downloadQcm}>
        <i class="ri-file-download-line"></i>
        </button>
        <button class="btn-select-qcm" title="Supprimer" on:click={deleteQcm}>
        <i class="ri-delete-bin-line"></i>
        </button>
    </div>
</div>

<style>
    .qcm-line {
        margin-left: 1em;
        margin-bottom: 0.3em;
        position: relative;
    }
    .qcm-line::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5em;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: grey;
    }
    .button-list {
        display: inline-block;
        vertical-align: middle;
    }
    .qcm-title {
        display: inline-block;
        vertical-align: middle;
    }
    .qcm-line .floatting {
      position: absolute;
      top:0.4rem;
      left:-0.2rem;
    }
    .qcm-line .floatting input {
      display: none;
    }
    .qcm-line .floatting input:checked {
      display: block;
    }
    .qcm-line label {
      cursor: pointer;
    }
    .qcm-line:hover .floatting input {
      display: block;
    }

</style>