<script lang="ts">
  import { onMount } from 'svelte'
  import utils from '../../lib/ts/services/utils'
  import storage, { type StoreSessions } from '../../lib/ts/services/storage'
  import Qcm from '../../lib/ts/models/qcm'
  import Session from '../../lib/ts/models/session'

  let container: HTMLElement
  let scoresContainer: HTMLElement

  onMount(() => {
    statisticsDisplay()
  })

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  async function statisticsDisplay () {
    // Affiche la liste des sessions enregistrées
    container.innerHTML = ''
    const $ul = utils.DOM.create('div', { class: 'list' })
    container.appendChild($ul)
    if (storage.isAvailable()) {
      storage.db.store_sessions.toArray()
        .then(async (entries: StoreSessions[]) => {
          for (const entry of entries) {
            const $li = utils.DOM.create('div', { class: 'list-item pointer', text: entry.uid + ' - ' + 'Session du ' + new Date(entry.data._date).toLocaleString() })
            $ul.appendChild($li)
            $li.onclick = async () => {
              sessionStatistics(entry.uid, 'dom').then((node: string) => {
                if (node === undefined) return
                scoresContainer.innerHTML = node
              })
              unActiveResultList($li)
            }
            const $btnExport = utils.DOM.createButton({ class: 'btn-export', html: '<i class="ri-download-line"></i>', title: 'Exporter pour tableur' })
            $li.appendChild($btnExport)
            $btnExport.onclick = async (evt: Event) => {
              // prevent bubbling
              evt.stopPropagation()
              const text = await sessionStatistics(entry.uid, 'csv') as string
              exportSession(text, entry.uid)
            }
            const $suppress = utils.DOM.create('div', { class: 'btn-close', text: 'X', title: 'Supprimer la session' })
            $li.appendChild($suppress)
            $suppress.onclick = async (evt: Event) => {
              // prevent bubbling
              evt.stopPropagation()
              if (confirm('Voulez-vous supprimer la session ' + entry.uid + ' ?')) {
                await storage.db.store_sessions.delete(entry.uid)
                statisticsDisplay()
              }
            }
          }
        }).catch((error: any) => { console.error('Problème chargement des sessions.', error) })
    }
    // Affiche les statistiques enregistrées pour le qcm en cours.
    /* if (session !== undefined) {
      const $container2 = utils.DOM.getById('scores-container') as HTMLElement
      await this.sessionStatistics($container2, session)
      for (const [index, question] of get(currentQuestions).entries()) {
        questionStatistics(index, $container, question)
      }
      renderLatex('stats-container')
    } */
  }
  function unActiveResultList(elementToActive: HTMLElement): void {
    const $lis = document.querySelectorAll('.list-item')
    for (const $li of $lis) {
      $li.classList.remove('is-active')
    }
    elementToActive.classList.add('is-active')
  }
  /*
  function questionStatistics (index, container, question?): void {
    // Affiche les stats pour la question en cours
    // récupère les réponses fournies pour la question
    const stats = votes.getAnswersOfQuestion(index)
    if (session.mode !== 'normal') {
      // todo : make different color for stats of each vote
      const stats2 = votes2.getAnswersOfQuestion(index)
      for (const key in stats) {
        if (stats2[key] !== undefined) {
          if (stats[key] === undefined) stats[key] = 0
          stats[key] = stats[key] + stats2[key]
        }
      }
    }
    if (utils.object.getLength(stats) > 0) {
      if (container !== null) {
        const article = utils.DOM.create('article', { class: 'cell has-text-centered' })
        if (question !== undefined) {
          const divEnonce = utils.DOM.create('div')
          divEnonce.innerHTML = question.content
          article.appendChild(divEnonce)
        }
        const canvasContainer = utils.DOM.create('div', { class: 'square' })
        const canvasStats = utils.DOM.createCanvas()
        canvasContainer.appendChild(canvasStats)
        article.appendChild(canvasContainer)
        container.appendChild(article)
        const colors = ['#3e95cd', '#3e95cd', '#3e95cd', '#3e95cd']
        const colorsGoodAnswers = ['#3e95cd', '#3e95cd', '#3e95cd', '#3e95cd']
        let count = 0
        if (question === undefined) question = $currentQuestions[currentQuestionIndex - 1]
        question?.answers.forEach(answer => {
          if (answer.isCorrect) {
            colorsGoodAnswers[count] = '#adff2f'
          }
          count++
        })
        const data = {
          type: 'bar',
          data: {
            labels: ['A', 'B', 'C', 'D'],
            datasets: [{
              label: 'Réponses question ' + String(index + 1),
              backgroundColor: colors,
              data: [
                stats.A !== undefined ? stats.A : 0,
                stats.B !== undefined ? stats.B : 0,
                stats.C !== undefined ? stats.C : 0,
                stats.D !== undefined ? stats.D : 0
              ]
            }]
          },
          options: {
            responsive: true,
            indexAxis: 'y'
          }
        }
        // eslint-disable-next-line no-undef
        const chart = new Chart(canvasStats, data)
        if (chart === null) console.warn('Impossible de créer le graphique.', index + 1, question)
        const buttonShowGood = utils.DOM.createButton({ text: 'Révéler', class: 'button' })
        article.appendChild(buttonShowGood)
        buttonShowGood.onclick = () => {
          if (chart !== null) {
            chart.data.datasets[0].backgroundColor = colorsGoodAnswers
            chart.update()
          }
        }
      }
    } else {
      console.warn('questionStatistics', 'Pas de stats à afficher')
    }
  } */
  async function sessionStatistics (sessionId:number, type:string): Promise<string> {
    if (sessionId === undefined) {
      console.warn('sessionStatistics', 'sessionId is undefined')
      return ''
    }
    let container : HTMLElement | string
    if (type === 'dom') {
      container = document.createElement('div')
    } else {
      container = ''
    }
    const qcms: Qcm[] = []
    const sess = await storage.db.store_sessions.get(sessionId)
    if (sess !== undefined) {
      const session = await Session.remake(sess.data)
      if (typeof container !== 'string') {
        container.appendChild(utils.DOM.create('h2', { text: 'QCM ' + String(session.mode), class: 'title' }))
      }
      if (session !== undefined) {
        const qcm1 = new Qcm('QCM 1', 'qcmcam2')
        qcms.push(qcm1)
        let qcm2: Qcm
        // recréation des QCM
        if (session.questions[0].length > 0) {
          for (const questionconf of session.questions[0]) {
            qcm1.questionsList.push(questionconf[0])
          }
          await qcm1.loadQuestions()
            .then(
              quests => {
                qcm1.questions = quests
                // reorder answers
                for (const [index, conf] of session.questions[0].entries()) {
                  if (conf[1] === undefined) continue
                  const answers = [...qcm1.questions[index].answers]
                  for (let i = 0, len = answers.length; i < len; i++) {
                    qcm1.questions[index].answers[conf[1].indexOf(['A', 'B', 'C', 'D'][i])] = answers[i]
                  }
                }
              }
            ).catch((error: any) => {
              console.error(error)
              return ''
            })
        }
        if (session.questions[1] !== undefined && session.questions[1].length > 0) {
          qcm2 = new Qcm('QCM 2', 'qcmcam2')
          qcms.push(qcm2)
          for (const questionconf of session.questions[1]) {
            qcm2.questionsList.push(questionconf[0])
          }
          await qcm2.loadQuestions()
            .then(
              async quests => {
                qcm2.questions = quests
                // reorder answers as in conf
                for (const [index, conf] of session.questions[1].entries()) {
                  if (conf[1] === undefined) continue
                  const answers = [...qcm1.questions[index].answers]
                  for (let i = 0, len = answers.length; i < len; i++) {
                    qcm2.questions[index].answers[conf[1].indexOf(['A', 'B', 'C', 'D'][i])] = answers[i]
                  }
                }
              })
            .catch((error: any) => {
              console.error(error)
              return ''
            })
        }
        // il peut y avoir deux groupes
        for (const [index, group] of session.constructedGroups.entries()) {
          const scores = []
          const qcm = qcms[index] ?? qcm1
          // Affiche les stats pour le groupe
          if (typeof container !== 'string') {
            const table = utils.DOM.create('table')
            container.appendChild(table)
            const tr = utils.DOM.create('tr')
            table.appendChild(tr)
            tr.appendChild(utils.DOM.create('th', { text: 'Participant' }))
            // colonnes pour les questions
            for (let id = 0; id < qcm.questionsList.length; id++) {
              tr.appendChild(utils.DOM.create('th', { text: 'Q' + String(id + 1) }))
            }
            tr.appendChild(utils.DOM.create('th', { text: 'Scores' }))
            // ligne des bonnes réponses
            const tr2 = utils.DOM.create('tr')
            table.appendChild(tr2)
            tr2.appendChild(utils.DOM.create('td', { text: 'Bonnes réponses' }))
            for (let i = 0; i < qcm.questions.length; i++) {
              const goodAns = utils.numberToLetter(qcm.questions[i].getGoodAnswer()).join(',')
              tr2.appendChild(utils.DOM.create('td', { text: goodAns ?? 'N/A' }))
            }
            tr2.appendChild(utils.DOM.create('td', { text: '--', id: 'gp-score'+index }))
            for (const key in group.students) {
              const result = studentStatistics(group.students[key], session.results[index], qcm.questions)
              table.appendChild(result[0])
              scores.push(result[1])
            }            
            container.appendChild(table);
            (table.querySelector('#gp-score'+index) as HTMLElement).innerText = String(scores.reduce((a, b) => a + b, 0))
          } else {
            container += 'Participant'
            for (let id = 0; id < qcm.questionsList.length; id++) {
              container += '\tQ' + String(id + 1)
            }
            container += '\tScores\nBonnes réponses'
            for (let i = 0; i < qcm.questions.length; i++) {
              const goodAns = utils.numberToLetter(qcm.questions[i].getGoodAnswer()).join(',')
              container += '\t' + (goodAns ?? 'N/A')
            }
            container += '\t--'
            for (const key in group.students) {
              container += '\n' + studentStatistics(group.students[key], session.results[index], qcm.questions)[0].innerText
            }
          }
        }
        if (typeof container !== 'string') {
          return container.innerHTML
        } else {
          return container
        }
      }
    }
    return ''
  }
  function exportSession (data: string, uid: string): void {
    // export as CSV file
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'Session-' + uid + '-resultats.csv')
    link.click()
  }
  function studentStatistics (student, results, questions): [HTMLElement, number] {
    const tr = utils.DOM.create('tr')
    const td = utils.DOM.create('td', { text: student.firstname + ' ' + student.name })
    if (results === undefined) {
      // console.warn('studentStatistics', 'results is undefined')
      tr.innerHTML += '<td>' + student.firstname + ' ' + student.name + '</td><td>-</td>'.repeat(questions.length + 1)
      return [tr,0]
    }
    const resultOfStudent = results.filter(result => result[1] === student.id)
    tr.appendChild(td)
    let score = 0
    for (let id = 0; id < questions.length; id++) {
      const td2 = utils.DOM.create('td')
      const answerOfStudent = resultOfStudent.filter(result => result[0] === id)
      if (answerOfStudent[0] !== undefined) {
        const goodAnswer = utils.numberToLetter(questions[id].getGoodAnswer()).join(',')
        if (answerOfStudent[0][2] === goodAnswer) {
          score++
          td2.classList.add('good')
        }
        td2.innerHTML = answerOfStudent[0][2]
      } else {
        td2.innerHTML = '-'
      }
      tr.appendChild(td2)
    }
    const td3 = utils.DOM.create('td', { text: String(score) + '/' + String(questions.length) })
    tr.appendChild(td3)
    return [tr, score]
  }
</script>

<div class="tab">
  <h1 class="title is-2">Dernières sessions de QCM</h1>
  <div
    bind:this={container}
    id="stats-container"
    class="grid is-col-min-12 is-gap-2"
  >
  </div>
  <div bind:this={scoresContainer} id="scores-container"></div>
</div>
