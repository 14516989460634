import type Question from './question'

export default class Votes {
  private _markerAnswers: Record<number, Record<number, voteRecord>>
  private readonly _minVues: number
  private readonly _simpleInterval: number
  private readonly _multiInterval: number
  private _questionNumber: number
  private _question: Question | undefined
  private readonly _markers: number[]

  constructor (markers: number[]) {
    this._markerAnswers = {}
    this._minVues = 4
    this._simpleInterval = 1000
    this._multiInterval = 3500
    this._questionNumber = 0
    this._markers = markers
    for (const id of markers) {
      this._markerAnswers[id] = {}
    }
  }

  setQuestion (questionNumber: number, question: Question): void {
    this._questionNumber = questionNumber
    this._question = question
  }

  analyseAnswers (answers: Record<number, string>, changeOpinion: boolean): Record<number, Record<number, voteRecord>> {
    for (const id in answers) {
      this.setAnswer(Number(id) + 1, answers[id], changeOpinion)
    }
    return this._markerAnswers
  }

  addMarkerAnswerOfQuestion (id: number, value: string, timestamp: number): boolean {
    if (this._markerAnswers[id][this._questionNumber] === undefined) {
      this._markerAnswers[id][this._questionNumber] = {
        value: [],
        timestamp,
        temporaryValue: value,
        vues: 1,
        history: []
      }
      return true
    } return false
  }

  setAnswer (id: number, value: string, changeOpinion: boolean): void {
    if (this._markerAnswers[id] === undefined) {
      return
    }
    const timestamp = Date.now()
    const markerAnswers = this._markerAnswers[id][this._questionNumber]
    if (this.addMarkerAnswerOfQuestion(id, value, timestamp)) {
      // do nothing
    } else if (markerAnswers.temporaryValue !== value) {
      // valeur différente, mise à jour de la valeur et remise à zéro du compteur de vues.
      markerAnswers.temporaryValue = value
      markerAnswers.vues = 1
      markerAnswers.timestamp = timestamp
    } else {
      // on vient de voir la même valeur, on ajoute une vue.
      markerAnswers.vues++
      if (markerAnswers.vues > this._minVues && this._question !== undefined) {
        // le nombre de vues est suffisant, on valide la réponse.
        if (!this._question.multipleAnswerMode && !markerAnswers.value.includes(value)) {
          if (markerAnswers.history.length === 0) {
            // vote non encore pris en compte, on l'ajoute
            markerAnswers.value.push(value)
            markerAnswers.history.push([ value, '+' ])
          } else if (markerAnswers.timestamp + this._simpleInterval < timestamp && changeOpinion) {
            // un vote existait, on le remplace et on annonce que c'est un nouveau vote (historique +)
            markerAnswers.value[0] = value
            markerAnswers.timestamp = timestamp
            markerAnswers.history.push([ value, '+' ])
          }
        } else if (this._question.multipleAnswerMode) { // qcm à réponse multiple uniquement
          if (markerAnswers.history.length === 0) {
            markerAnswers.value.push(value)
            markerAnswers.timestamp = timestamp
            markerAnswers.history.push([ value, '+' ])
          } else if (markerAnswers.timestamp + this._multiInterval < timestamp) {
            markerAnswers.vues = 1
            markerAnswers.timestamp = timestamp
            if (!markerAnswers.value.includes(value)) {
              markerAnswers.value.push(value)
              markerAnswers.history.push([ value, '+' ])
            } else {
              markerAnswers.value = markerAnswers.value.filter(o => o !== value)
              markerAnswers.history.push([ value, '-' ])
            }
          }
        }
      }
    }
  }

  getAnswersOfQuestion (id: number): Record<string, number> {
    const records: Record<string, number> = {}
    for (const markerId in this._markerAnswers) {
      if (this._markerAnswers[markerId][id] !== undefined) {
        const answers = this._markerAnswers[markerId][id].value
        for (const letter of answers) {
          if (records[letter] === undefined) {
            records[letter] = 1
          } else {
            records[letter]++
          }
        }
      }
    }
    return records
  }

  static remake (voteObject: any): any {
    const votes = new Votes(voteObject._groupName)
    votes._markerAnswers = voteObject._markerAnswers
    votes._questionNumber = voteObject._questionNumber
    return votes
  }

  get markersAnswers (): Record<number, Record<number, voteRecord>> {
    return this._markerAnswers
  }

  get markers (): number[] {
    return this._markers
  }
}

interface voteRecord {
  value: string[]
  timestamp: number
  temporaryValue: string
  vues: number
  history: Array<[string, string]>
}
