const utils = {
  numberToLetter (listOfNumbers: number[]): string[] {
    const letters = [ 'A', 'B', 'C', 'D' ]
    const answers = []
    for (const i of listOfNumbers) {
      answers.push(letters[i])
    }
    return answers
  },
  letterToNumber (listOfLetters: string[]): number[] {
    const letters = [ 'A', 'B', 'C', 'D' ]
    const answers = []
    for (const i of listOfLetters) {
      answers.push(letters.indexOf(i))
    }
    return answers
  },
  text: {
    decodeHtmlEntity (str: string): string {
      str = str.replace(/&amp;/g, '&')
      str = str.replace(/&lt;/g, '<')
      return str.replace(/&gt;/g, '>')
    },
    extractTextFromTags (str: string): string {
      const div = document.createElement('div')
      div.innerHTML = str
      return div.innerText
    }
  },
  object: {
    getLength (obj: Record<any, any> | undefined): number {
      if (obj === undefined) return 0
      const count = Object.keys(obj).length
      return count
    }
  },
  image: {
    isResizing: false,
    startX: 0,
    startWidth: 0,
    defaultPixelSize: 0,
    getAspectRatio (width: number, height: number): string {
      function mdc (w: number, h: number): number {
        let resto: number
        if (w < h) [ w, h ] = [ h, w ]
        do {
          resto = w % h
          w = h
          h = resto
        } while (resto !== 0)
        return w
      }
      const gcd = mdc(width, height)
      return String(width / gcd) + ' / ' + String(height / gcd)
    },
    toSmallSize (image: HTMLImageElement): void {
      if (image.dataset.small === undefined) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = image.naturalWidth / 10
        canvas.height = image.naturalHeight / 10
        if (ctx !== null) {
          ctx.drawImage(image,
            0, 0, image.width, image.height,
            0, 0, canvas.width, canvas.height
          )
          image.src = canvas.toDataURL()
          image.dataset.small = '1'
        }
      }
    },
    startResize (event: MouseEvent, image: HTMLImageElement): void {
      this.isResizing = true
      this.startX = event.clientX
      this.startWidth = parseFloat(image.style.width)
    },
    resize (event: MouseEvent): string | undefined {
      if (!this.isResizing) return
      const newWidth = this.startWidth * this.defaultPixelSize + (event.clientX - this.startX)
      return String(newWidth / this.defaultPixelSize) + 'em'
    },
    stopResize (event: Event): number {
      this.isResizing = false
      return this.startWidth
    },
    calculateSizeInEm (elementTargetId: string): boolean {
      let imageResized = false
      document.querySelectorAll('#' + elementTargetId + ' .question img').forEach(image => {
        if ((image as HTMLImageElement).style.width === '') {
          const size = utils.DOM.px2em(image as HTMLImageElement)
          if (size !== false) {
            (image as HTMLImageElement).style.width = size;
            (image as HTMLImageElement).style.height = 'auto'
            image.dispatchEvent(new Event('change', { bubbles: true }))
            imageResized = true
          }
        }
        (image as HTMLImageElement).removeAttribute('width');
        (image as HTMLImageElement).removeAttribute('height')
      })
      return imageResized
    },
    makeEditable (targetElementId: string): void {
      const images = document.querySelectorAll<HTMLImageElement>('#' + targetElementId + ' .question img')
      for (const image of Array.from(images)) {
        image.onmousedown = (e) => {
          e.preventDefault()
          utils.image.startResize(e, image)
        }
        image.onmousemove = (e) => {
          e.preventDefault()
          const size = utils.image.resize(e)
          if (size !== undefined) image.style.width = size
        }
        image.onmouseup = (e) => {
          if (Math.abs(parseFloat(image.style.width) - utils.image.stopResize(e)) > 0.1) {
            image.dispatchEvent(new Event('change', { bubbles: true }))
          }
        }
        image.onmouseout = (e) => {
          if (Math.abs(parseFloat(image.style.width) - utils.image.stopResize(e)) > 0.1) {
            image.dispatchEvent(new Event('change', { bubbles: true }))
          }
        }
      }
    }
  },
  array: {
    shuffle (array: any[]): void {
      for (let i = array.length - 1; i > 0; i--) {
        const j: number = Math.floor(Math.random() * (i + 1));
        [ array[i], array[j] ] = [ array[j], array[i] ]
      }
    },
    areEqual (array1: any[], array2: any[]) {
      let equal = true
      for (const val of array1) {
        if (!array2.includes(val)) {
          equal = false
        }
        if (!equal) break
      }
      if (equal) {
        for (const val of array2) {
          if (!array1.includes(val)) {
            equal = false
          }
          if (!equal) break
        }
      }
      return equal
    }
  },
  file: {
    download (blob: Blob, fileName: string): void {
      const anchor = window.document.createElement('a')
      anchor.href = window.URL.createObjectURL(blob)
      anchor.download = fileName
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(anchor.href)
    }
  },
  forms: {
    /**
         *
         * @param {String} name name of the radio button
         * @returns input checked value | null
         */
    getRadioValue: function (name: string) {
      const domEl: HTMLInputElement | null = document.querySelector("input[type='radio'][name='" + name + "']:checked")
      if (domEl !== null) {
        return domEl.value
      } else {
        return null
      }
    },
    /**
         * Check if an element is an input and return his value
         * @param {String} id of the element
         * @returns value of the element or null
         */
    getValue: function (id: string) {
      const domEl = document.getElementById(id) as HTMLInputElement
      if (domEl !== null) {
        const localname: string = domEl.localName.toLowerCase()
        if ([ 'select', 'input', 'textarea', 'option', 'datalist' ].includes(localname)) { return domEl.value }
      } else {
        return null
      }
    },
    setValue: function (id: string, value: string): void {
      const domElement = document.getElementById(id) as HTMLInputElement
      if (domElement !== null) domElement.value = value
    },
    checkRadioValue: function (name: string, value: string) {
      const radios = document.getElementsByName(name) as NodeListOf<HTMLInputElement>
      for (const radio of Array.from(radios)) {
        if (radio.value === value) {
          radio.checked = true
          break
        }
      }
    }
  },
  DOM: {
    /**
         * Return DOM element selected by his id
         * @param {String} id of the element
         * @returns element or null
         */
    getById: function (id: string) {
      const domEl = document.getElementById(id)
      if (domEl !== null) {
        return domEl
      } else {
        return null
      }
    },
    class: function (id: string, className: string) {
      const domElement = document.getElementById(id)
      if (domElement !== null) domElement.className = className
    },
    addClass: function (id: string, className: string) {
      const domElement = document.getElementById(id)
      if (domElement !== null) domElement.classList.add(className)
    },
    removeClass: function (id: string, className: string) {
      const domElement = document.getElementById(id)
      if (domElement !== null) domElement.classList.remove(className)
    },
    toggleClass: function (id: string, className: string) {
      const domElement = document.getElementById(id)
      if (domElement !== null) domElement.classList.toggle(className)
    },
    text: function (id: string, content: string) {
      const domElement = document.getElementById(id)
      if (domElement !== null) domElement.innerText = content
    },
    dragElement (element: HTMLElement) {
      let pos1 = 0
      let pos2 = 0
      let pos3 = 0
      let pos4 = 0
      // otherwise, move the DIV from anywhere inside the DIV:
      element.onmousedown = dragMouseDown

      function dragMouseDown (e: MouseEvent): any {
        e = e ?? window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag (e: MouseEvent): any {
        e = e ?? window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        // set the element's new position:
        element.style.top = String(element.offsetTop - pos2) + 'px'
        element.style.left = String(element.offsetLeft - pos1) + 'px'
      }

      function closeDragElement (): void {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
      }
    },
    addAction: function (id: string, action: domActionType, fonction: () => void): void {
      const objet = this.getById(id)
      if (objet !== null) {
        objet.addEventListener(action, fonction)
      }
    },
    createAnchor (options?: createAnchorOptions): HTMLAnchorElement {
      const anchor = document.createElement('a')
      if (options !== undefined) {
        if (options.class !== undefined) anchor.className = options.class
        if (options.text !== undefined) anchor.innerText = options.text
        if (options.title !== undefined) anchor.title = options.title
        if (options.id !== undefined) anchor.id = options.id
        if (options.href !== undefined) anchor.href = options.href
      }
      return anchor
    },
    createButton (options?: createButtonOptions): HTMLButtonElement {
      const button = document.createElement('button')
      if (options !== undefined) {
        if (options.class !== undefined) button.className = options.class
        if (options.text !== undefined) button.innerText = options.text
        if (options.title !== undefined) button.title = options.title
        if (options.value !== undefined) button.value = options.value
        if (options.id !== undefined) button.id = options.id
        if (options.html !== undefined) button.innerHTML = options.html
      }
      return button
    },
    createCanvas (): HTMLCanvasElement {
      const canvas = document.createElement('canvas')
      return canvas
    },
    createIframe (options: createIframeOptions): HTMLIFrameElement {
      const iframe = document.createElement('iframe')
      iframe.src = options.src
      if (options.class !== undefined) iframe.className = options.class
      if (options.text !== undefined) iframe.innerText = options.text
      if (options.id !== undefined) iframe.id = options.id
      if (options.width !== undefined) iframe.width = String(options.width)
      if (options.height !== undefined) iframe.height = String(options.height)
      return iframe
    },
    createImage (options?: createImageOptions): HTMLImageElement {
      const image = document.createElement('img')
      if (options !== undefined) {
        if (options.src !== undefined) image.src = options.src
        if (options.class !== undefined) image.className = options.class
        if (options.title !== undefined) image.title = options.title
        if (options.alt !== undefined) image.alt = options.alt
      }
      return image
    },
    createInput (options: createInputOptions): HTMLInputElement {
      const input = document.createElement('input')
      input.type = options.type
      if (options.class !== undefined) input.className = options.class
      if (options.text !== undefined) input.innerText = options.text
      if (options.title !== undefined) input.title = options.title
      if (options.value !== undefined) input.value = options.value
      if (options.id !== undefined) input.id = options.id
      if (options.name !== undefined) input.name = options.name
      return input
    },
    createOption (options?: createButtonOptions): HTMLOptionElement {
      const option = document.createElement('option')
      if (options !== undefined) {
        if (options.class !== undefined) option.className = options.class
        if (options.text !== undefined) option.innerText = options.text
        if (options.title !== undefined) option.title = options.title
        if (options.value !== undefined) option.value = options.value
        if (options.id !== undefined) option.id = options.id
        if (options.html !== undefined) option.innerHTML = options.html
      }
      return option
    },
    createSwitchButton (id: string, colorClass: string = ''): HTMLInputElement {
      return utils.DOM.createInput({ type: 'checkbox', id, name: id, class: 'switch is-rounded ' + colorClass })
    },
    createSwitchLabelButton (id: string, content: string): HTMLLabelElement {
      const label = document.createElement('label')
      label.className = 'ml-3'
      label.innerText = content
      label.htmlFor = id
      return label
    },
    create: function (type: createElementType, options?: createElementOptions): createElementReturned {
      const domElement = document.createElement(type)
      if (options !== undefined) {
        if (options.class !== undefined) domElement.className = options.class
        if (options.text !== undefined) domElement.innerText = options.text
        if (options.id !== undefined) domElement.id = options.id
      }
      return domElement
    },
    px2em: function (elem: HTMLImageElement) {
      const W = window
      if (elem.parentNode !== null) {
        const parentFontSize = parseInt(W.getComputedStyle(elem.parentNode as HTMLElement, null).fontSize, 10)
        let elemWidth = 1
        if (elem.clientWidth !== 0) elemWidth = elem.clientWidth
        else elemWidth = elem.naturalWidth
        const pxInEms = Math.floor((elemWidth / parentFontSize) * 1000) / 1000
        return String(pxInEms) + 'em'
      } else return false
    }
  }
}

export default utils

interface createElementOptions {
  'class'?: string
  'text'?: string
  'title'?: string
  'alt'?: string
  'id'?: string
}
interface createImageOptions extends createElementOptions { 'src': string, 'alt'?: string }
interface createAnchorOptions extends createElementOptions { 'href'?: string }
interface createInputOptions extends createElementOptions { 'value'?: string, 'id'?: string, 'type': string, 'name'?: string }
interface createButtonOptions extends createElementOptions { 'value'?: string, 'id'?: string, 'html'?: string }
interface createIframeOptions extends createElementOptions { 'src': string, 'height'?: number, 'width'?: number }
type createElementType = 'div' | 'span' | 'i' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'header' | 'ul' | 'ol' | 'li' | 'section' | 'article' | 'select' | 'label' | 'p' | 'footer' | 'table' | 'tbody' | 'tr' | 'td' | 'th'
type createElementReturned = HTMLElement
type domActionType = 'click' | 'input' | 'mouseover' | 'mouseout'
