import storage from '../services/storage.js'
import type Group from './group.js'

interface dataToSend {
  data: Student
  uid?: number
}

export default class Student {
  private _id: number
  private _name: string
  private _firstname: string
  private _groups: string[]
  private _markerId: number
  private _present: boolean

  constructor (name: string, firstname: string, groups: string[], markerId: number, id?: number) {
    this._id = id ?? 0
    this._name = name
    this._firstname = firstname
    this._groups = groups
    this._markerId = markerId > 0 ? markerId : 0
    this._present = true
  }

  async init (groupid: number): Promise<number> {
    let id = 0
    id = await this.save()
    if (id > 0) await this.saveLinkWithGroup(groupid)
    return id
  }

  async addToGroup (group: Group): Promise<boolean> {
    if (!this._groups.includes(group.name)) {
      this._groups.push(group.name)
      await this.saveLinkWithGroup(group.id)
      await this.save()
      return true
    } else return false
  }

  async removeFromGroup (group: Group): Promise<boolean> {
    const groupid = this._groups.indexOf(group.name)
    if (groupid > -1) {
      this._groups.splice(groupid, 1)
      await this.deleteLinkWithGroup(group.id)
      await this.save()
      return true
    } else return false
  }

  static remake (students: Record<number, Student>): Record<number, Student> {
    const newStudents: Record<number, Student> = {}
    for (const key in students) {
      const student = students[key]
      newStudents[Number(key)] = new Student(student._name, student._firstname, student._groups, student._markerId, student._id)
    }
    return newStudents
  }

  async save (): Promise<number> {
    let id = 0
    if (storage.isAvailable()) {
      if (this._id === 0) {
        id = Number(await storage.db.store_students.add({ data: this }))
        if (id > 0) {
          this._id = id
          this.save()
        }
      } else {
        id = Number(await storage.db.store_students.put({ data: this, uid: this._id }))
        if (id > 0) {
          console.log('Mise à jour de l\'etudiant ' + String(this._id))
        }
      }
    }
    return id
  }

  static async load (id: number): Promise<Student> {
    if (storage.isAvailable()) {
      const result = await storage.db.store_students.get(id)
      if (result) {
        const student = new Student(result.data._name, result.data._firstname, result.data._groups, result.data._markerId, id)
        return student
      }
    }
    console.warn('Étudiant non trouvé.')
    return new Student('Nouvel etudiant', '', [], 0)
  }

  static deleteStudent (id: number): void {
    if (storage.isAvailable()) {
      storage.db.store_students.where('uid').equals(id).delete().catch(() => { console.error('Erreur avec deleteStudent ' + String(id)) })
    }
  }

  async saveLinkWithGroup (groupId: number): Promise<void> {
    if (groupId === 0) {
      console.log('Lien non créé : ' + String(this._id) + ', référence au groupe nul')
      return
    }
    if (storage.isAvailable()) {
      storage.db.links_groups_students.put({ groupid: groupId, studentid: this._id }).then(() => { console.log('Élève ' + String(this._id) + ' lié au groupe ' + String(groupId)) }).catch(() => { console.error('Erreur avec saveLinkWithGroup : Élève' + String(this._id) + ' groupe ' + String(groupId)) })
    }
  }

  async deleteLinkWithGroup (groupId: number): Promise<void> {
    if (storage.isAvailable()) {
      storage.db.links_groups_students.where('groupid').equals(groupId).and((link: { studentid: number }) => link.studentid === this._id).delete().catch(() => { console.error('Erreur avec deleteLinkWithGroup') })
    }
  }

  get id (): number {
    return this._id
  }

  get name (): string {
    return this._name
  }

  set name (name) {
    this._name = name
  }

  get firstname (): string {
    return this._firstname
  }

  set firstname (firstname) {
    this._firstname = firstname
  }

  get groups (): string[] {
    return this._groups
  }

  set groups (groups: string[]) {
    this._groups = groups
  }

  get markerId (): number {
    return this._markerId
  }

  set markerId (id) {
    this._markerId = id
  }

  get present (): boolean {
    return this._present
  }

  set present (present) {
    this._present = present
  }
}
