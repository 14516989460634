<script lang="ts">

</script>

<button
  class="{$$props.class ? $$props.class : 'px-4'}
    transition-colors duration-300
    hover:bg-gray-100"
  id="{$$props.id}"
  on:click
>
  <slot />
</button>
