/**
 * Deep copy an object, not only the first level.
 * Does not work with functions, undefined, NaN, Infinity, -Infinity
 */
export function deepCopy<T> (obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export function isStrings (obj: unknown): obj is string[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(item => typeof item === 'string')
}
