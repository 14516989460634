import { isStrings } from '../services/types'

export const CONFIG_DEFAULT_COLORS = ['#a8f9f9', '#f9b7d8', '#c5bcf8', '#f8f596']

export interface Config {
  changeOpinion: boolean
  scanAutoStop: boolean
  defaultSlideTime: number
  startMessage: string
  language: string
  defaultColors: string[]
  isCardList: boolean
  isAlphabeticallySorted: boolean
}
export function isConfig (obj: unknown): obj is Config {
  if (obj == null || typeof obj !== 'object') return false
  return 'changeOpinion' in obj && typeof obj.changeOpinion === 'boolean' &&
    'scanAutoStop' in obj && typeof obj.scanAutoStop === 'boolean' &&
    'defaultSlideTime' in obj && typeof obj.defaultSlideTime === 'number' &&
    'startMessage' in obj && typeof obj.startMessage === 'string' &&
    'language' in obj && typeof obj.language === 'string' &&
    'defaultColors' in obj && isStrings(obj.defaultColors) &&
    'isCardList' in obj && typeof obj.isCardList === 'boolean' &&
    'isAlphabeticallySorted' in obj && typeof obj.isAlphabeticallySorted === 'boolean'
}
export function isConfigs (obj: unknown): obj is Config[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isConfig)
}
export const emptyConfig: Config = {
  changeOpinion: true,
  scanAutoStop: true,
  startMessage: '',
  language: 'fr',
  defaultSlideTime: 15,
  defaultColors: [...CONFIG_DEFAULT_COLORS],
  isCardList: true,
  isAlphabeticallySorted: false
}
