export default class Answer {
  private _content: string
  private _isCorrect: boolean
  private _color: string

  constructor (text: string) {
    this._content = text
    this._isCorrect = false
    this._color = ''
  }

  static remake (answers: Answer[]): Answer[] {
    const remakeAnswers = []
    for (const answer of answers) {
      const newAnswer = new Answer(answer._content)
      newAnswer.isCorrect = answer._isCorrect
      newAnswer.color = answer._color
      remakeAnswers.push(newAnswer)
    }
    return remakeAnswers
  }

  set isCorrect (isCorrect: boolean) {
    this._isCorrect = isCorrect
  }

  get isCorrect (): boolean {
    return this._isCorrect
  }

  set content (text: string) {
    this._content = text
  }

  get content (): string {
    return this._content
  }

  set color (color: string) {
    this._color = color
  }

  get color (): string {
    return this._color
  }
}
