<script lang="ts">
  import { hideModal, showModal } from '../../lib/ts/services/modal'
  import ModalNewUser from './ModalNewUser.svelte'

  let modal: HTMLElement

</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-connect" bind:this={modal}>
  <div class="modal-background" on:click|self={() => hideModal(modal)}></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Connexion à QCMCam</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-connect" on:click={() => hideModal(modal)}></button>
    </header>
    <div class="modal-card-body">
      <h2 class="is-small">Identifiant</h2>
      <div class="control has-icons-left has-icons-right">
        <input type="email" class="input" placeholder="mail ou identifiant">
        <span class="icon is-left">
          <i class="ri-mail-line"></i>
        </span>
        <span class="icon is-right">
          <i class="ri-check-fill"></i>
        </span>
      </div>
      <h2 class="is-small">Mot de passe</h2>
      <div class="control has-icons-left">
        <input type="password" class="input" placeholder="●●●●●●●●">
        <span class="icon is-left">
          <i class="ri-lock-line"></i>
        </span>
      </div>
      <div class="has-text-centered mt-4">
        <button class="button is-white">
          <a href="forgotpassword.html">Mot de passe oublié ?</a>
        </button>
        <button class="button">Valider</button>
      </div>
    </div>
    <footer class="modal-card-foot">
      <div class="has-text-centered container">
        Pas de compte ?
        <button class="button ml-5 is-vcentered" on:click={() => showModal('qm-newuser')}>Créer un compte</button>
      </div>
    </footer>
  </div>
</div>

<ModalNewUser />
