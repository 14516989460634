<script lang="ts">
  import { onMount, tick } from 'svelte'
  import type QcmList from '../../lib/ts/models/qcmsList'
  import { currentQcm, qcms, config, inlineEditors, currentQuestions } from '../../lib/ts/services/store'
  import utils from '../../lib/ts/services/utils'
  import { setQuestionFontSize, setStyleSheetColor } from '../../lib/ts/services/qcm'
  import Qcm from '../../lib/ts/models/qcm'
  import { hideModal } from '../../lib/ts/services/modal'
  import Question from '../../lib/ts/models/question'

  // quand le plugin math sera implémenté pour un usage compatible import du package
  // sans erreur Uncaught CKEditorError: ckeditor-duplicated-modules
  // import inlineEditor from '../../lib/ts/services/ckeditor'

  export let selectedQcmId: number

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type InlineEditor = any // Sera réglé lorsqu'on utilisera le package ckeditor5 au lieu du js actuellement importé

  let qcm: Qcm
  let questionToCopy = 0
  let questionToCut = { question: 0, QCMfrom: 0, index: 0 }
  let inlineEditor: InlineEditor
  let isQCMColored: boolean = false

  onMount(async () => {
    const module = await import('../../lib/js/ckeditor5/build/ckeditor')
    inlineEditor = module.default
    await tick()
    await setup()
    setColorEditor()
  })

  async function setup () {
    qcm = await Qcm.load($qcms.list[selectedQcmId][0])
    const questionListName = utils.DOM.getById('question-list-name')
    const editorQCMName = utils.DOM.create('div', { id: 'editor-qcm-name', text: qcm.name })
    if (questionListName !== null) {
      questionListName.innerHTML = ''
      questionListName.appendChild(editorQCMName)
      if (qcm !== undefined) {
        inlineEditor.create(editorQCMName, {
          toolbar: ['undo', 'redo']
        }).then(
          (editor: InlineEditor) => {
            $inlineEditors.push(editor)
            editor.editing.view.document.on('blur', () => {
              const text = utils.text.extractTextFromTags(editor.getData())
              if (qcm.name !== text) {
                qcm.name = text
                qcm.save()
                $qcms.list[selectedQcmId][1] = text
                // $qcms.save()
                $qcms.updateQcm(qcm)
              }
            })
          }
        ).catch((error: unknown) => { console.error('Pb création éditeur', error) })
      }
    }
    const dom = utils.DOM.getById('questions-list')
    if (dom !== null) {
      dom.innerHTML = ''
      if (qcm !== undefined) {
        currentQcm.set(qcm)
        updateQCMColors()
        editorQCMName.dataset.id = String(qcm.id)
        const container = utils.DOM.create('div', { class: 'container' })
        isQCMColored = qcm.coloredAnswers
        qcm.loadQuestions().then(questions => {
          currentQuestions.set(questions)
          for (const [index, question] of questions.entries()) {
            container.appendChild(displayQuestionEditor(question, index, qcm, $qcms, qcm.name))
          }
          dom.appendChild(container)
          const divImageInCenter = utils.DOM.create('div', { class: 'has-text-centered pointer btn-add-answer' })
          const imagePlus = utils.DOM.createImage({ src: './images/plus-circle-1427-svgrepo-com.svg', title: 'Ajouter une question', class: 'is-inline-flex' })
          divImageInCenter.appendChild(imagePlus)
          divImageInCenter.onclick = async () => {
          // création des deux premières questions
            const newQuestion = await qcm.createQuestion('<h3>Nouvelle question</h3>')
            newQuestion.addAnwser('')
            newQuestion.addAnwser('')
            await newQuestion.save()
            await qcm.save()
            $qcms.updateQcm(qcm)
            container.appendChild(displayQuestionEditor(newQuestion, qcm.questionsList.length - 1, qcm, $qcms, ''))
          }
          dom.appendChild(divImageInCenter)
          // TODO : à revoir !!! ckeditor enregistre la taille des images et les restitue après le changement :(
          setTimeout(() => {
          /** utils.image.calculateSizeInEm('questions-list')
          utils.image.makeEditable('qm-qcm-editor') */
            hideModal(document.getElementById('waitAction'))
          }, 1200)
          setQuestionFontSize()
        }).catch(err => { console.error('Erreur de chargement des questions', err) })
      // renderLatex('questions-list')
      } else dom.appendChild(document.createTextNode('?! Questionnaire non trouvé !?'))
    } else {
      hideModal(document.getElementById('waitAction'))
    }
  }

  function setColorEditor () {
    utils.DOM.addAction('defaultColorEditor0', 'click', () => { setQCMDefaultColors() })
    for (let i = 1; i < 5; i++) {
      const colorsetter = utils.DOM.getById('defaultColorEditor' + String(i)) as HTMLInputElement
      colorsetter.onchange = () => { setQCMAnswerColor(i, colorsetter.value) }
    }
  }

  function setQCMDefaultColors () {
    if ($currentQcm !== undefined) {
      $currentQcm.colors = $config.defaultColors
      updateQCMColors()
      $currentQcm.save()
    }
  }

  function setQCMAnswerColor (index: number, colorValue: string) {
    if ($currentQcm !== undefined) {
      $currentQcm.colors[index - 1] = colorValue
      setStyleSheetColor()
      $currentQcm.save()
    }
  }

  function QCMDelete (qcms: QcmList) {
    if (confirm('Vous êtes sur le point de supprimer le QCM.\nConfirmer ?')) {
      const editorQCMName = utils.DOM.getById('editor-qcm-name')
      if (editorQCMName !== null) {
        const qcmID = Number(editorQCMName.dataset.id)
        if ($currentQcm !== undefined && qcmID === $currentQcm.id) {
          qcms.deleteQcmById(qcmID).then(() => {
            // qcms.save()
            emptyQCMQuestionsList()
            document.getElementById('btn-qcm')?.click()
          }).catch(err => { console.error('Erreur de suppression de QCM', err) })
        }
      }
    }
  }
  function emptyQCMQuestionsList (): void {
    const questionListName = utils.DOM.getById('question-list-name')
    if (questionListName !== null) {
      questionListName.innerHTML = ''
      questionListName.appendChild(document.createTextNode('Sélectionner un QCM...'))
    }
    const dom = utils.DOM.getById('questions-list')
    if (dom !== null) {
      dom.innerHTML = `<div class="py-6 is-size-3 has-text-grey-light has-text-weight-bold is-flex">
      <div class="is-flex is-justify-content-center is-flex-direction-column mx-4"><i
          class="ri-arrow-left-s-line"></i></div>
      <div>Sélectionner/Créer/Charger un qcm<br> pour voir et éditer ses questions</div>
    </div>`
    }
  }

  function refreshQuestions (qcm: Qcm, qcms: QcmList, name: string): void {
    const dom = utils.DOM.getById('questions-list')
    if (dom !== null) {
      dom.innerHTML = ''
      const container = utils.DOM.create('div', { class: 'container' })
      for (const [index, question] of $currentQuestions.entries()) {
        container.appendChild(displayQuestionEditor(question, index, qcm, qcms, name))
      }
      dom.appendChild(container)
      const divImageInCenter = utils.DOM.create('div', { class: 'has-text-centered pointer btn-add-answer' })
      const imagePlus = utils.DOM.createImage({ src: './images/plus-circle-1427-svgrepo-com.svg', title: 'Ajouter une question' })
      divImageInCenter.appendChild(imagePlus)
      divImageInCenter.onclick = async () => {
        // création des deux premières questions
        const newQuestion = await qcm.createQuestion('<h3>Nouvelle question</h3>')
        newQuestion.addAnwser('')
        newQuestion.addAnwser('')
        await newQuestion.save()
        await qcm.save()
        container.appendChild(displayQuestionEditor(newQuestion, qcm.questionsList.length - 1, qcm, qcms, ''))
      }
      dom.appendChild(divImageInCenter)
      /** setTimeout(() => {
        utils.image.calculateSizeInEm('questions-list')
        utils.image.makeEditable('qm-qcm-editor')
      }, 300) */
      setQuestionFontSize()
    }
  }
  function displayQuestionEditor (question: Question, index: number, qcm: Qcm, qcms: QcmList, name: string) {
    const container = utils.DOM.create('article')
    const qcmId = qcms.getQcmIndex(qcm.id)
    let timeOutTextSizer: undefined | number
    let timeOutSaveTimer: undefined | number
    const questionNumero = utils.DOM.create('h2', { text: 'Question n°' + String(index + 1), class: 'title has-background-grey-light p-2' })
    container.appendChild(questionNumero)
    const buttonDelete = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right', html: '<i class="ri-delete-bin-line"></i>' })
    switchIsQCMColored(qcm.coloredAnswers)
    buttonDelete.onclick = () => {
      if (confirm('Supprimer la question ' + String(index + 1) + ' ?')) {
        qcm.removeQuestion(index)
        qcm.save()
        qcms.updateQcm(qcm)
        $currentQuestions?.splice(index, 1)
        refreshQuestions(qcm, qcms, name)
      }
    }
    questionNumero.appendChild(buttonDelete)
    const buttonCancel = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right has-text-danger is-hidden paste-btn', html: '<i class="ri-file-close-line"></i>' })
    buttonCancel.title = 'Annuler la copie de question'
    buttonCancel.onclick = () => {
      removeCopyCutClasses()
      questionToCopy = 0
      questionToCut = { question: 0, QCMfrom: 0, index: 0 }
    }
    questionNumero.appendChild(buttonCancel)
    const buttonCopy = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right copy-btn', html: '<i class="ri-file-copy-line"></i>' })
    buttonCopy.title = 'Copier la question'
    buttonCopy.onclick = () => {
      utils.DOM.addClass('questions-list', 'copy-mode')
      questionContainer.classList.add('copied')
      questionToCopy = question.id
    }
    questionNumero.appendChild(buttonCopy)
    const buttonCut = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right copy-btn', html: '<i class="ri-scissors-line"></i>' })
    buttonCut.title = 'Couper la question'
    buttonCut.onclick = () => {
      utils.DOM.addClass('questions-list', 'copy-mode')
      questionContainer.classList.add('cutted')
      questionToCut = { question: question.id, QCMfrom: qcm.id, index }
    }
    questionNumero.appendChild(buttonCut)
    const buttonPasteBefore = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right paste-btn is-hidden', html: '<i class="ri-file-upload-line"></i>' })
    buttonPasteBefore.title = 'Coller la question avant'
    buttonPasteBefore.onclick = async () => {
      if (questionToCopy !== 0) {
        const newQuestionId = Number(await Question.duplicate(questionToCopy))
        if (newQuestionId !== 0) {
          qcm.questionsList.splice(index, 0, newQuestionId)
          const newQuestion = await Question.load(newQuestionId)
          if (newQuestion !== undefined) {
            $currentQuestions?.splice(index, 0, newQuestion)
          }
          qcm.save()
          qcms.updateQcm(qcm)
          refreshQuestions(qcm, qcms, name)
        }
      } else if (questionToCut.question !== 0) {
        // insertion
        qcm.questionsList.splice(index, 0, questionToCut.question)
        qcm.save()
        const thisQuestion = await Question.load(questionToCut.question)
        if (thisQuestion !== undefined) {
          $currentQuestions?.splice(index, 0, thisQuestion)
        }
        qcms.updateQcm(qcm)
        // suppression de l'ancien QCM
        const QCMfrom = await Qcm.load(questionToCut.QCMfrom)
        QCMfrom.questionsList.splice(questionToCut.index, 1)
        QCMfrom.save()
        qcms.updateQcm(QCMfrom)
        refreshQuestions(qcm, qcms, name)
        questionToCut = { question: 0, QCMfrom: 0, index: 0 }
        removeCopyCutClasses()
      }
    }
    questionNumero.appendChild(buttonPasteBefore)
    const buttonPasteAfter = utils.DOM.createButton({ class: 'button ml-4 is-pulled-right paste-btn is-hidden', html: '<i class="ri-file-download-line"></i>' })
    buttonPasteAfter.title = 'Coller la question après'
    buttonPasteAfter.onclick = async () => {
      if (questionToCopy !== 0) {
        const newQuestionId = Number(await Question.duplicate(questionToCopy))
        if (newQuestionId !== 0) {
          qcm.questionsList.splice(index + 1, 0, newQuestionId)
          qcm.save()
          qcms.updateQcm(qcm)
          const newQuestion = await Question.load(newQuestionId)
          if (newQuestion !== undefined) {
            $currentQuestions?.splice(index + 1, 0, newQuestion)
          }
          refreshQuestions(qcm, qcms, name)
        }
      } else if (questionToCut.question !== 0) {
        // insertion
        qcm.questionsList.splice(index + 1, 0, questionToCut.question)
        qcm.save()
        const thisQuestion = await Question.load(questionToCut.question)
        if (thisQuestion !== undefined) {
          $currentQuestions?.splice(index + 1, 0, thisQuestion)
        }
        qcms.updateQcm(qcm)
        // suppression de l'ancien QCM
        const QCMfrom = await Qcm.load(questionToCut.QCMfrom)
        QCMfrom.questionsList.splice(questionToCut.index, 1)
        QCMfrom.save()
        qcms.updateQcm(QCMfrom)
        refreshQuestions(qcm, qcms, name)
        questionToCut = { question: 0, QCMfrom: 0, index: 0 }
        removeCopyCutClasses()
      }
    }
    function removeCopyCutClasses (): void {
      document.querySelector('#questions-list .copied')?.classList.remove('copied')
      document.querySelector('#questions-list .cutted')?.classList.remove('cutted')
      utils.DOM.removeClass('questions-list', 'copy-mode')
    }
    questionNumero.appendChild(buttonPasteAfter)
    const questionCommands = utils.DOM.create('div', { class: 'question-commands' })
    const questionContainer = utils.DOM.create('div', { class: 'question' + ' ' + question.displayMode, id: 'questionInList' + String(index) })
    if (!isNaN(question.textSize)) questionContainer.style.fontSize = String(question.textSize) + 'em'
    const div = utils.DOM.create('div')
    /* display mode */
    const divDropDownActions = utils.DOM.create('div', { class: 'dropdown ml-3' })
    div.appendChild(divDropDownActions)
    const divDropDownTrigger = utils.DOM.create('div', { class: 'dropdown-trigger' })
    divDropDownActions.appendChild(divDropDownTrigger)
    const buttonDropDownTrigger = utils.DOM.createButton({ class: 'button' })
    buttonDropDownTrigger.setAttribute('aria-haspopup', 'true')
    buttonDropDownTrigger.setAttribute('aria-controls', 'dropdown-menu')
    buttonDropDownTrigger.appendChild(utils.DOM.create('span', { text: 'Disposition' }))
    buttonDropDownTrigger.appendChild(utils.DOM.create('span', { class: 'icon is-small' }).appendChild(utils.DOM.create('i', { class: 'ri-arrow-down-s-line' })))
    buttonDropDownTrigger.onclick = () => {
      const hisClass = divDropDownActions.classList
      const isActive = hisClass.contains('is-active')
      document.querySelectorAll('#questions-list-container .dropdown.is-active').forEach(el => { el.classList.remove('is-active') })
      if (!isActive) divDropDownActions.classList.add('is-active')
    }
    divDropDownTrigger.appendChild(buttonDropDownTrigger)
    const divDropDownMenu = utils.DOM.create('div', { class: 'dropdown-menu', id: 'questionEdit-' + String(index) })
    divDropDownMenu.setAttribute('role', 'menu')
    divDropDownActions.appendChild(divDropDownMenu)
    const divDropDownContent = utils.DOM.create('div', { class: 'dropdown-content' })
    divDropDownMenu.appendChild(divDropDownContent)
    const buttonDisplayClassic = utils.DOM.createAnchor({ class: 'dropdown-item' })
    const iPrint = utils.DOM.create('i', { class: 'ri-list-check' })
    buttonDisplayClassic.appendChild(iPrint)
    buttonDisplayClassic.appendChild(document.createTextNode(' liste de texte'))
    if (question.displayMode === 'classic') buttonDisplayClassic.classList.add('is-active')
    buttonDisplayClassic.onclick = () => {
      divDropDownActions.classList.toggle('is-active')
      unactivDropDownActionsButtons()
      buttonDisplayClassic.classList.add('is-active')
      question.displayMode = 'classic'
      questionContainer.classList.remove('imageAbove', 'imageAside', 'imagesInAnswers', 'answerWithoutContent')
      question.save()
    }
    divDropDownContent.appendChild(buttonDisplayClassic)
    const buttonImage = utils.DOM.createAnchor({ class: 'dropdown-item' })
    buttonImage.appendChild(utils.DOM.create('i', { class: 'ri-image-line' }))
    buttonImage.appendChild(document.createTextNode(' Image au dessus'))
    if (question.displayMode === 'imageAbove') buttonImage.classList.add('is-active')
    buttonImage.onclick = () => {
      divDropDownActions.classList.toggle('is-active')
      unactivDropDownActionsButtons()
      buttonImage.classList.add('is-active')
      question.displayMode = 'imageAbove'
      questionContainer.classList.remove('imageAside', 'imagesInAnswers', 'answerWithoutContent', 'classic')
      questionContainer.classList.add('imageAbove')
      question.save()
    }
    divDropDownContent.appendChild(buttonImage)
    const buttonImageAside = utils.DOM.createAnchor({ class: 'dropdown-item' })
    buttonImageAside.appendChild(utils.DOM.create('i', { class: 'ri-indent-decrease' }))
    buttonImageAside.appendChild(document.createTextNode(' Image à gauche'))
    if (question.displayMode === 'imageAside') buttonImageAside.classList.add('is-active')
    buttonImageAside.onclick = () => {
      divDropDownActions.classList.toggle('is-active')
      unactivDropDownActionsButtons()
      buttonImageAside.classList.add('is-active')
      question.displayMode = 'imageAside'
      questionContainer.classList.remove('imageAbove', 'imagesInAnswers', 'classic', 'answerWithoutContent')
      questionContainer.classList.add('imageAside')
      question.save()
    }
    divDropDownContent.appendChild(buttonImageAside)
    const buttonImagesInAnswers = utils.DOM.createAnchor({ class: 'dropdown-item' })
    const i2 = utils.DOM.create('i', { class: 'ri-gallery-line' })
    buttonImagesInAnswers.appendChild(i2)
    buttonImagesInAnswers.appendChild(document.createTextNode(' Images dans les réponses'))
    if (question.displayMode === 'imagesInAnswers') buttonImagesInAnswers.classList.add('is-active')
    buttonImagesInAnswers.onclick = () => {
      divDropDownActions.classList.toggle('is-active')
      unactivDropDownActionsButtons()
      buttonImagesInAnswers.classList.add('is-active')
      question.displayMode = 'imagesInAnswers'
      questionContainer.classList.remove('imageAbove', 'imageAside', 'answerWithoutContent', 'classic')
      questionContainer.classList.add('imagesInAnswers')
      question.save()
    }
    divDropDownContent.appendChild(buttonImagesInAnswers)
    const buttonAnswerWithoutContent = utils.DOM.createAnchor({ class: 'dropdown-item' })
    buttonAnswerWithoutContent.appendChild(utils.DOM.create('i', { class: 'ri-rectangle-line' }))
    buttonAnswerWithoutContent.appendChild(document.createTextNode(' Réponses vides'))
    if (question.displayMode === 'answerWithoutContent') buttonAnswerWithoutContent.classList.add('is-active')
    buttonAnswerWithoutContent.onclick = () => {
      divDropDownActions.classList.toggle('is-active')
      unactivDropDownActionsButtons()
      buttonAnswerWithoutContent.classList.add('is-active')
      question.displayMode = 'answerWithoutContent'
      questionContainer.classList.remove('imageAbove', 'imageAside', 'imagesInAnswers', 'classic')
      questionContainer.classList.add('answerWithoutContent')
      question.save()
    }
    function unactivDropDownActionsButtons (): void {
      [buttonAnswerWithoutContent, buttonDisplayClassic, buttonImage, buttonImageAside, buttonImagesInAnswers].forEach(el => { el.classList.remove('is-active') })
    }
    divDropDownContent.appendChild(buttonAnswerWithoutContent)
    div.appendChild(divDropDownActions)
    /* shuffle mode */
    const shuffleModeQuestion = utils.DOM.createSwitchButton('shuffleModeQuestion' + String(index))
    if (question.shuffleMode) shuffleModeQuestion.checked = true
    shuffleModeQuestion.title = 'Mélange l’ordre des réponses'
    shuffleModeQuestion.onclick = () => {
      question.shuffleMode = shuffleModeQuestion.checked
      question.save()
    }
    div.appendChild(shuffleModeQuestion)
    div.appendChild(utils.DOM.createSwitchLabelButton('shuffleModeQuestion' + String(index), 'Mélanger'))
    /* answer mode */
    const multipleAnswerModeQuestion = utils.DOM.createSwitchButton('multipleAnswerModeQuestion' + String(index), 'is-info')
    if (question.multipleAnswerMode) multipleAnswerModeQuestion.checked = true
    multipleAnswerModeQuestion.onclick = () => { question.multipleAnswerMode = multipleAnswerModeQuestion.checked; qcm.save() }
    div.appendChild(multipleAnswerModeQuestion)
    div.appendChild(utils.DOM.createSwitchLabelButton('multipleAnswerModeQuestion' + String(index), 'Réponse multiple'))
    /* chronometer mode */
    const divQuestionTimer = utils.DOM.create('div', { class: 'field is-inline-block mx-2 is-vertical-aligned' })
    const pQuestionTimer = utils.DOM.create('p', { class: 'control has-icons-left has-icons-right' })
    const questionTimer = utils.DOM.createInput({ type: 'number', value: String(question.duration), class: 'input has-text-right' })
    if (question.duration === 0) questionTimer.classList.add('has-text-grey-light')
    pQuestionTimer.appendChild(questionTimer)
    divQuestionTimer.appendChild(pQuestionTimer)
    const questionTimerIcon = utils.DOM.create('span', { class: 'icon is-left' })
    questionTimerIcon.innerHTML = '<i class="ri-timer-line"></i>'
    pQuestionTimer.appendChild(questionTimerIcon)
    const questionTimerUnit = utils.DOM.create('span', { class: 'icon is-right' })
    questionTimerUnit.innerHTML = 's.'
    pQuestionTimer.appendChild(questionTimerUnit)
    questionTimer.min = '0'
    questionTimer.max = '360'
    questionTimer.title = '0 = illimité'
    const timeOutSaveTime = 1200
    questionTimer.oninput = () => {
      question.duration = Number(questionTimer.value)
      if (question.duration === 0) {
        questionTimer.classList.add('has-text-grey-light')
        question.timedMode = false
      } else {
        questionTimer.classList.remove('has-text-grey-light')
        question.timedMode = true
      }
      if (timeOutSaveTimer !== undefined) {
        clearTimeout(timeOutSaveTimer)
        timeOutSaveTimer = undefined
      } else {
        timeOutSaveTimer = setTimeout(() => {
          question.save()
        }, timeOutSaveTime)
      }
    }
    div.appendChild(divQuestionTimer)
    /* zoom buttons */
    const zoomIn = utils.DOM.createButton({ class: 'button is-white px-1', html: '<i class="ri-zoom-in-line is-size-5"></i>' })
    const timeOutSaveSize = 2500
    zoomIn.onclick = () => {
      question.zoomIn()
      questionContainer.style.fontSize = String(question.textSize) + 'em'
      if (timeOutTextSizer !== undefined) {
        timeOutTextSizer = undefined
      }
      timeOutTextSizer = setTimeout(() => {
        question.save()
      }, timeOutSaveSize)
    }
    const zoom1 = utils.DOM.createButton({ text: '1:1', class: 'button is-white px-1' })
    zoom1.onclick = () => {
      question.textSize = 1
      questionContainer.style.fontSize = '1em'
      if (timeOutTextSizer !== undefined) {
        timeOutTextSizer = undefined
      }
      timeOutTextSizer = setTimeout(() => {
        question.save()
      }, timeOutSaveSize)
    }
    const zoomOut = utils.DOM.createButton({ class: 'button is-white px-1', html: '<i class="ri-zoom-out-line is-size-5"></i>' })
    zoomOut.onclick = () => {
      question.zoomOut()
      questionContainer.style.fontSize = String(question.textSize) + 'em'
      if (timeOutTextSizer !== undefined) {
        timeOutTextSizer = undefined
      }
      timeOutTextSizer = setTimeout(() => {
        question.save()
      }, timeOutSaveSize)
    }
    div.appendChild(zoomOut)
    div.appendChild(zoom1)
    div.appendChild(zoomIn)
    /* div inclusion */
    const divDropDownMoveActions = utils.DOM.create('div', { class: 'dropdown ml-3 is-float-right' })
    div.appendChild(divDropDownMoveActions)
    const divDropDownTrigger2 = utils.DOM.create('div', { class: 'dropdown-trigger' })
    divDropDownMoveActions.appendChild(divDropDownTrigger2)
    const buttonDropDownTrigger2 = utils.DOM.createButton({ class: 'button' })
    buttonDropDownTrigger2.setAttribute('aria-haspopup', 'true')
    buttonDropDownTrigger2.setAttribute('aria-controls', 'dropdown-menu')
    buttonDropDownTrigger2.appendChild(utils.DOM.create('span', { text: 'Déplacer' }))
    buttonDropDownTrigger2.appendChild(utils.DOM.create('span', { class: 'icon is-small' }).appendChild(utils.DOM.create('i', { class: 'ri-arrow-down-s-line' })))
    buttonDropDownTrigger2.onclick = () => {
      const hisClass = divDropDownMoveActions.classList
      const isActive = hisClass.contains('is-active')
      document.querySelectorAll('#questions-list-container .dropdown.is-active').forEach(el => { el.classList.remove('is-active') })
      if (!isActive) divDropDownMoveActions.classList.add('is-active')
    }
    divDropDownTrigger2.appendChild(buttonDropDownTrigger2)
    const divDropDownMenu2 = utils.DOM.create('div', { class: 'dropdown-menu', id: 'questionMove-' + String(index) })
    divDropDownMenu2.setAttribute('role', 'menu')
    divDropDownMoveActions.appendChild(divDropDownMenu2)
    const divDropDownContent2 = utils.DOM.create('div', { class: 'dropdown-content' })
    divDropDownMenu2.appendChild(divDropDownContent2)
    const questionId = index
    if (index > 0) {
      const buttonMoveToTop = utils.DOM.createAnchor({ class: 'dropdown-item' })
      const itop = utils.DOM.create('i', { class: 'ri-skip-up-line' })
      buttonMoveToTop.appendChild(itop)
      buttonMoveToTop.appendChild(document.createTextNode(' en premier'))
      buttonMoveToTop.onclick = () => {
        qcm.moveQuestionOnTop(questionId)
        qcm.save()
        if (qcmId !== undefined) {
          qcms.list[qcmId][2] = qcm.questionsList[0]
          // qcms.save()
        }
        const thisQuestion = $currentQuestions[index]
        $currentQuestions.splice(index, 1)
        $currentQuestions.splice(0, 0, thisQuestion)
        refreshQuestions(qcm, qcms, name)
      }
      divDropDownContent2.appendChild(buttonMoveToTop)
      const buttonMoveUp = utils.DOM.createAnchor({ class: 'dropdown-item' })
      const iup = utils.DOM.create('i', { class: 'ri-arrow-up-s-line' })
      buttonMoveUp.appendChild(iup)
      buttonMoveUp.appendChild(document.createTextNode(' monter'))
      buttonMoveUp.onclick = () => {
        qcm.moveQuestionUp(questionId)
        qcm.save()
        if (index === 1 && qcmId !== undefined) {
          qcms.list[qcmId][2] = qcm.questionsList[0]
          // qcms.save()
        }
        const thisQuestion = $currentQuestions[index]
        $currentQuestions.splice(index, 1)
        $currentQuestions.splice(index - 1, 0, thisQuestion)
        refreshQuestions(qcm, qcms, name)
      }
      divDropDownContent2.appendChild(buttonMoveUp)
    }
    if (index < qcm.questionsList.length - 1) {
      const buttonMoveDown = utils.DOM.createAnchor({ class: 'dropdown-item' })
      const idown = utils.DOM.create('i', { class: 'ri-arrow-down-s-line' })
      buttonMoveDown.appendChild(idown)
      buttonMoveDown.appendChild(document.createTextNode(' descendre'))
      buttonMoveDown.onclick = () => {
        qcm.moveQuestionDown(questionId)
        qcm.save()
        if (index === 0 && qcmId !== undefined) {
          qcms.list[qcmId][2] = qcm.questionsList[0]
          // qcms.save()
        }
        const thisQuestion = $currentQuestions[index]
        $currentQuestions.splice(index, 1)
        $currentQuestions.splice(index + 1, 0, thisQuestion)
        refreshQuestions(qcm, qcms, name)
      }
      divDropDownContent2.appendChild(buttonMoveDown)
      const buttonMoveToBottom = utils.DOM.createAnchor({ class: 'dropdown-item' })
      const ibottom = utils.DOM.create('i', { class: 'ri-skip-down-line' })
      buttonMoveToBottom.appendChild(ibottom)
      buttonMoveToBottom.appendChild(document.createTextNode(' en dernier'))
      buttonMoveToBottom.onclick = () => {
        qcm.moveQuestionToBottom(questionId)
        qcm.save()
        if (index === 0 && qcmId !== undefined) {
          qcms.list[qcmId][2] = qcm.questionsList[0]
          // qcms.save()
        }
        const thisQuestion = $currentQuestions[index]
        $currentQuestions.splice(index, 1)
        $currentQuestions.push(thisQuestion)
        refreshQuestions(qcm, qcms, name)
      }
      divDropDownContent2.appendChild(buttonMoveToBottom)
    }
    questionCommands.appendChild(div)
    const questionHeader = utils.DOM.create('header', { class: 'question-content ' + question.type })
    const $divHeader = utils.DOM.create('div')
    questionHeader.appendChild($divHeader)
    $divHeader.innerHTML = question.content
    inlineEditor.create($divHeader).then(
      (editor: InlineEditor) => {
        $inlineEditors.push(editor)
        editor.editing.view.document.on('blur', () => {
          if (question.content !== editor.getData()) {
            question.content = editor.getData()
            question.save()
          }
        })
      }
    ).catch((error: unknown) => { console.error('Pb création éditeur', error) })
    const answersContainer = utils.DOM.create('ol', { class: 'answers ' + question.type + ' is-relative' }) as HTMLOListElement
    const addAnswerButton = utils.DOM.create('div', { class: 'has-text-centered pointer is-hidden btn-add-answer', title: 'Ajouter une reponse' })
    addAnswerButton.innerHTML = '<i class="ri-play-list-add-line"></i>'
    addAnswerButton.onclick = () => {
      const answerDestroy = utils.DOM.create('div', { class: 'absolute-left is-clickable answer-destroy' })
      answerDestroy.innerHTML = '<i class="ri-delete-bin-6-line"></i>'
      answerDestroy.onclick = () => {
        const list = [...Array.from(answersContainer.children)]
        const indexOfAnswer = list.indexOf(answerContent)
        question.removeAnswer(indexOfAnswer)
        answersContainer.removeChild(answerContent)
        answersList.splice(indexOfAnswer, 1)
        question.save()
        if (list.length === 4) {
          addAnswerButton.classList.remove('is-hidden')
        }
      }
      const newAnswer = question.addAnwser('')
      answersList.push(newAnswer)
      const answerContent = utils.DOM.create('li', { class: 'answer ' + question.type })
      const $divAnswerContent = utils.DOM.create('div')
      answerContent.appendChild($divAnswerContent)
      $divAnswerContent.innerHTML = newAnswer.content
      const answerIsGood = utils.DOM.create('div', { class: 'absolute-left is-clickable answer-is-good' })
      answerIsGood.innerHTML = '<i class="ri-checkbox-line"></i>'
      answerIsGood.onclick = () => {
        if (!question.multipleAnswerMode) {
          for (const localanswer of question.answers) {
            if (localanswer !== newAnswer) {
              localanswer.isCorrect = false
            }
          }
          document.querySelectorAll('#questionInList' + String(index) + ' li.answer').forEach(el => { el.classList.remove('is-correct') })
        }
        newAnswer.isCorrect = !newAnswer.isCorrect
        if (newAnswer.isCorrect) answerContent.classList.add('is-correct')
        else answerContent.classList.remove('is-correct')
        question.save()
      }
      inlineEditor.create($divAnswerContent).then(
        (editor: InlineEditor) => {
          $inlineEditors.push(editor)
          editor.editing.view.document.on('blur', () => {
            if (newAnswer.content !== editor.getData()) {
              newAnswer.content = editor.getData()
              question.save()
            }
          })
        })
        .catch((error: unknown) => { console.error('Problème initialisation éditeur.', error) })
      answersContainer.appendChild(answerContent)
      answerContent.appendChild(answerIsGood)
      answerContent.appendChild(answerDestroy)
      if (answersList.length < 4) {
        addAnswerButton.classList.remove('is-hidden')
      } else {
        addAnswerButton.classList.add('is-hidden')
      }
    }
    const answersList = [...question.answers]
    if (answersList.length < 4) {
      addAnswerButton.classList.remove('is-hidden')
    }
    for (const answer of answersList) {
      const answerContent = utils.DOM.create('li', { class: 'answer ' + question.type })
      const $divAnswerContent = utils.DOM.create('div')
      answerContent.appendChild($divAnswerContent)
      $divAnswerContent.innerHTML = answer.content
      const answerDestroy = utils.DOM.create('div', { class: 'absolute-left is-clickable answer-destroy' })
      answerDestroy.innerHTML = '<i class="ri-delete-bin-6-line"></i>'
      answerDestroy.onclick = () => {
        const list = [...Array.from(answersContainer.children)]
        const indexOfAnswer = list.indexOf(answerContent) / 3
        question.removeAnswer(indexOfAnswer)
        answersContainer.removeChild(answerContent)
        answersList.splice(indexOfAnswer, 1)
        question.save()
        if (list.length === 4) {
          addAnswerButton.classList.remove('is-hidden')
        }
      }
      const answerIsGood = utils.DOM.create('div', { class: 'absolute-left is-clickable answer-is-good' })
      answerIsGood.innerHTML = '<i class="ri-checkbox-line"></i>'
      answerIsGood.onclick = () => {
        if (!question.multipleAnswerMode) {
          for (const localanswer of question.answers) {
            if (localanswer !== answer) {
              localanswer.isCorrect = false
            }
          }
          document.querySelectorAll('#questionInList' + String(index) + ' li.answer').forEach(el => { el.classList.remove('is-correct') })
        }
        answer.isCorrect = !answer.isCorrect
        if (answer.isCorrect) answerContent.classList.add('is-correct')
        else answerContent.classList.remove('is-correct')
        question.save()
      }
      if (answer.isCorrect) { answerContent.classList.add('is-correct') }
      inlineEditor.create($divAnswerContent).then(
        (editor: InlineEditor) => {
          $inlineEditors.push(editor)
          editor.editing.view.document.on('blur', () => {
            const textToSave = editor.getData()
            if (answer.content !== textToSave) {
              answer.content = textToSave
              question.save()
            }
          })
        })
        .catch((error: unknown) => { console.error('Problème initialisation éditeur.', error) })
      answersContainer.appendChild(answerContent)
      answerContent.appendChild(answerIsGood)
      answerContent.appendChild(answerDestroy)
    }
    questionContainer.appendChild(questionHeader)
    questionContainer.appendChild(answersContainer)
    questionContainer.appendChild(addAnswerButton)
    container.appendChild(questionCommands)
    container.appendChild(questionContainer)
    return container
  }

  function updateQCMColors () {
    if ($currentQcm === undefined) return
    for (let i = 1; i < 5; i++) {
      const colorInput = utils.DOM.getById('defaultColorEditor' + String(i)) as HTMLInputElement
      if ($currentQcm?.colors[i - 1] !== undefined && $currentQcm?.colors[i - 1] !== '') colorInput.value = $currentQcm?.colors[i - 1] ?? ''
      else colorInput.value = $config.defaultColors[i - 1]
    }
    setStyleSheetColor()
  }

  function switchIsQCMColored (isColored: boolean) {
    if ($currentQcm === undefined) return
    isQCMColored = isColored
    if (isColored) {
      document.querySelectorAll('#questions-list').forEach(element => { element.classList.add('colored') })
      $currentQcm.coloredAnswers = true
      $currentQcm.save()
    } else {
      document.querySelectorAll('#questions-list').forEach(element => { element.classList.remove('colored') })
      $currentQcm.coloredAnswers = false
      $currentQcm.save()
    }
  }
</script>

<div id="qm-qcm-editor" class="tab is-flex">
  <div class="p-3 is-flex-grow-1">
    <div class="p-3" id="questions-list-container">
      <div id="qcm-name-commands">
        <button class="button is-danger" on:click={() => QCMDelete($qcms)}><i class="ri-delete-bin-line"></i></button>
      </div>
      <h4 id="question-list-name" class="title p-3 mb-0">Sélectionner un QCM...</h4>
      <div id="questions-list-general-commands">
        <input
          id="switchShowCorrectAnswers"
          type="checkbox"
          name="switchShowCorrectAnswers"
          class="switch is-rounded"
          on:click={() => utils.DOM.toggleClass('questions-list', 'showCorrectAnswers')}
        >
        <label for="switchShowCorrectAnswers">Colorer les réponses correctes</label>
        <input
          id="switchIsQCMColored"
          type="checkbox"
          name="switchIsQCMColored"
          class="switch is-rounded is-info"
          checked={isQCMColored}
          on:change={(e) => {
            if (e.target instanceof HTMLInputElement) {
              switchIsQCMColored(e.target.checked)
            }
          }}
        >
        <label for="switchIsQCMColored">Colorer les réponses</label>
        <div class="{isQCMColored ? 'is-inline-block' : 'is-hidden'}">
          <div class="field is-horizontal" id="defaultColorsEditor">
            <button class="button" id="defaultColorEditor0">Défaut
              <span id="editorConfigColor1">A</span>
              <span id="editorConfigColor2">B</span>
              <span id="editorConfigColor3">C</span>
              <span id="editorConfigColor4">D</span>
            </button>
            <label for="defaultColorEditor1" class="field-label">A</label>
            <input type="color" id="defaultColorEditor1" class="input is-link" value="#a8f9f9">
            <label for="defaultColorEditor2" class="field-label">B</label>
            <input type="color" id="defaultColorEditor2" class="input is-link" value="#f9b7d8">
            <label for="defaultColorEditor3" class="field-label">C</label>
            <input type="color" id="defaultColorEditor3" class="input is-link" value="#c5bcf8">
            <label for="defaultColorEditor4" class="field-label">D</label>
            <input type="color" id="defaultColorEditor4" class="input is-link" value="#f8f596">
          </div>
        </div>
      </div>
      <div id="questions-list">
        <div class="py-6 is-size-3 has-text-grey-light has-text-weight-bold is-flex">
          <div class="is-flex is-justify-content-center is-flex-direction-column mx-4"><i
              class="ri-arrow-left-s-line"></i></div>
          <div>Sélectionner/Créer/Charger un qcm<br> pour voir et éditer ses questions</div>
        </div>
      </div>
    </div>
  </div>
</div>
