type languageDictionary = Record<string, Record<string, string>>
const language = {
  lang: '',
  dictionary: {} satisfies languageDictionary,
  setLanguage: function (lang: string): void {
    language.lang = lang
  },
  text: function (): string {
    let chaine: string = arguments[0]
    if (arguments[1] !== undefined) {
      for (let i = 1; i < arguments.length; i++) {
        chaine = chaine.replace('{}', arguments[i])
      }
    }
    return chaine
  },
  getNavigatorLanguage () {
    let lang = 'fr'
    if (navigator.language !== undefined) {
      lang = navigator.language
      lang = lang.substring(0, 2)
    }
    language.setLanguage(lang)
  },
  translate: function (force: boolean): void {
    if (!language.loadLang(true)) return
    // CKEDITOR.config.language = language.lang
    const els = document.querySelectorAll<HTMLElement>('*[data-translate]')
    let json, attr: string[]
    els.forEach(function (item) {
      if (item.dataset.translate !== undefined) json = JSON.parse(item.dataset.translate)
      else json = {}
      for (let chaine of json) {
        attr = chaine.split('.')
        if (attr[0] !== undefined && attr[1] !== undefined && language.dictionary[attr[0]] !== undefined && attr[1] in language.dictionary[attr[0]]) {
          chaine = language.dictionary[attr[0]][attr[1]]
          if (chaine === 'html') item.innerHTML = chaine
          else item[attr[0]] = chaine
        }
      }
    })
    /* if (!utils.isEmpty(CKEDITOR.instances)) { // on recrée les editeurs s'ils existent.
      QCMeditors.reloadCKEDITOR()
    }*/
  },
  loadLang: function (force: boolean): boolean {
    // au premier chargement si le navigateur est français, on ne touche à rien
    if (language.lang === 'fr' && force === undefined) return false;
    // si la langue n'existe pas, on arrête.
    if (![ 'en', 'es', 'fr', 'de', 'it' ].includes(language.lang)) {
      language.lang = 'fr'
      alert('Your language is not supported, default is french.\n\nYou can switch to Spanish, English, Italian or German\nin the bottom.\n\nOr you can help translating. See Info');
      return false
    }
    // sinon, on teste l'existence des éléménts de Traduction
    // si n'existe pas, on charge le script puis relance la traduction.
    if (language.lang !== '') {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const jsonFile = 'js/lang/' + language.lang + '.json'
      let callback = function (): boolean {
        return false
      }
      if (force) callback = language.translate
      // on ne fait pas la traduction tout de suite, elle sera lancée par le callback
      return false
    } else { // le fichier de langue a déjà été chargé
      return true
    }
  },
  changeLanguage: function (newlang: string) {
    language.lang = newlang
    language.translate(true)
  }
}
export default language
