import { goToView } from './navigation'
import utils from './utils'

export function showModal (id: string) {
  utils.DOM.addClass(id, 'is-active')
  if (id === 'qm-settings') {
    navigator.storage.estimate().then(({ usage, quota }) => {
      const used = (usage / quota) * 100
      if (used > 90) {
        alert('Le quota de stockage dépasse 90% !\nVeuillez supprimer des données')
      }
      const progress = document.getElementById('storagesize') as HTMLElement
      if (progress !== null) progress.innerHTML = used.toFixed(2)
    }).catch(() => { console.log('Error while getting storage usage') })
  }
}

export function hideModal (domEl: Element | null) {
  if (domEl !== null) {
    domEl.classList.remove('is-active')
    if (domEl.id === 'qm-diaporama-selection') {
      hideQCMCamShow()
    }
  }
}

export function hideQCMCamShow () {
  utils.DOM.addClass('QCMCamShow', 'is-hidden')
  utils.DOM.removeClass('show-settings', 'is-hidden')
  const qcmContainer = utils.DOM.getById('qcm-container')
  if (qcmContainer !== null) qcmContainer.innerHTML = ''
}
