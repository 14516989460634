import { get } from 'svelte/store'
import { hideModal, showModal } from './modal'
import { config, currentAction, currentQcm, currentQcm2, currentView } from './store'
import utils from './utils'

export const textToQuestionWidthFactor = 27.302

export async function displayQcmEditor (): Promise<void> {
  showModal('waitAction')
  currentView.set('qcm')
  currentAction.set('edit')
  setTimeout(() => {
    hideModal(document.getElementById('waitAction'))
  }, 1500)
}

export function setStyleSheetColor (second = '') {
  if (get(currentQcm) === undefined) return
  let qcm = get(currentQcm)
  if (second === '0' && get(currentQcm2) !== undefined) qcm = get(currentQcm2)
  if (qcm === undefined) return
  let colorInput = utils.DOM.getById('styleSheetColor')
  if (colorInput === null) {
    colorInput = document.createElement('style')
    colorInput.id = 'styleSheetColor'
    document.body.appendChild(colorInput)
  }
  if (second === 'destroy') {
    colorInput.innerHTML = ''
    return
  }
  const style = `
  ${second === '0' ? '#qcm-container0' : ''}.colored .question:not(.showCorrectAnswers) .answer:nth-of-type(1),
  ${second === '0' ? '#qcm-container0' : ''}.colored .question.showCorrectAnswers .answer:not(.is-correct):nth-of-type(1) {
  background-color: ${qcm.colors[0] !== undefined && qcm.colors[0] !== '' ? qcm.colors[0] : get(config).defaultColors[0]} !important;
}
${second === '0' ? '#qcm-container0' : ''}.colored .question:not(.showCorrectAnswers) .answer:nth-of-type(2),
${second === '0' ? '#qcm-container0' : ''}.colored .question.showCorrectAnswers .answer:not(.is-correct):nth-of-type(2) {
  background-color: ${qcm.colors[1] !== undefined && qcm.colors[1] !== '' ? qcm.colors[1] : get(config).defaultColors[1]} !important;
}
${second === '0' ? '#qcm-container0' : ''}.colored .question:not(.showCorrectAnswers) .answer:nth-of-type(3),
${second === '0' ? '#qcm-container0' : ''}.colored .question.showCorrectAnswers .answer:not(.is-correct):nth-of-type(3) {
  background-color: ${qcm.colors[2] !== undefined && qcm.colors[2] !== '' ? qcm.colors[2] : get(config).defaultColors[2]} !important;
}
${second === '0' ? '#qcm-container0' : ''}.colored .question:not(.showCorrectAnswers) .answer:nth-of-type(4),
${second === '0' ? '#qcm-container0' : ''}.colored .question.showCorrectAnswers .answer:not(.is-correct):nth-of-type(4){
  background-color: ${qcm.colors[3] !== undefined && qcm.colors[3] !== '' ? qcm.colors[3] : get(config).defaultColors[3]} !important;
}
  `
  if (second === '') colorInput.innerHTML = style
  else if (second === '0' || second === '1') colorInput.innerHTML += style
}

export function setQuestionFontSize (): void {
  const questionsContainer = document.querySelector('#questions-list > .container') as HTMLElement
  const oneQuestion = document.querySelector('#questions-list > .container .question') as HTMLElement
  if (questionsContainer !== null && oneQuestion !== null) {
    const width = oneQuestion.getBoundingClientRect().width
    const pixelSize = width / textToQuestionWidthFactor
    questionsContainer.style.fontSize = String(pixelSize) + 'px'
    utils.image.defaultPixelSize = pixelSize
  }
}

export function setQCMQuestionFontSize (): void {
  let questionsContainer = document.querySelector('#qcm-container') as HTMLElement
  if (questionsContainer !== null) {
    const boundingBox = questionsContainer.getBoundingClientRect()
    const width = Math.min(boundingBox.width - 40, (boundingBox.height - 60) * 4 / 3)
    questionsContainer.style.fontSize = String(width / textToQuestionWidthFactor) + 'px'
  }
  questionsContainer = document.querySelector('#qcm-container0') as HTMLElement
  if (questionsContainer !== null) {
    const boundingBox = questionsContainer.getBoundingClientRect()
    const width = Math.min(boundingBox.width - 40, (boundingBox.height - 60) * 4 / 3)
    questionsContainer.style.fontSize = String(width / textToQuestionWidthFactor) + 'px'
  }
}
