import Student from './student.js'
import { storage } from '../services/storage.js'
import type { StoreGroups } from '../services/storage.js'

interface StudentJson {
  _name: string;
  _firstname: string;
  _groups: string[];
  _markerId: number;
}

interface GroupJson {
  _name: string;
  _id: number;
  _size: number;
  _subgroups: number[][]; // or define a more specific type for _subgroups
  _students: { [key: string]: StudentJson }; // or define a more specific type for _students
}

export default class Group {
  private _name: string
  private _students: Record<number, Student>
  private _subgroups: number[][]
  private _size: number
  private _id: number

  constructor (name: string, id?: number) {
    this._name = name
    this._id = id ?? 0
    this._subgroups = [[], []]
    this._students = {}
    this._size = 0
  }

  async init (): Promise<number> {
    let id = 0
    if (this._id === 0) id = await this.save()
    return id
  }

  async addStudent (name: string, firstname: string, markerId: number, groups: string[] = [this._name]): Promise<number> {
    if (this.id === 0) return 0
    for (const key in this.students) {
      const student = this._students[key]
      if (student.markerId === markerId) {
        console.warn('Erreur de double', student, markerId)
        return 0
      }
    }
    const newStudent = new Student(name, firstname, groups, markerId)
    await newStudent.init(this._id)
    this.students[newStudent.id] = newStudent
    this._size++
    return newStudent.id
  }

  pushStudent (student: Student): void {
    this._students[student.id] = student
    this._size++
  }

  async removeStudent (student: Student): Promise<void> {
    if (this._students[student.id] !== undefined) {
      const id = student.id
      await this._students[id].removeFromGroup(this)
      // suppression de l'étudiant des sous-groupes
      for (const subgroup of this._subgroups) {
        const index = subgroup.indexOf(id)
        if (index > -1) {
          subgroup.splice(index, 1)
          await this.save()
        }
      }
      if (this._students[id].groups.length === 0) {
        // supression de l'étudiant de la base de données
        Student.deleteStudent(id)
      }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this._students[id]
      this._size--
    }
  }

  async removeStudentById (id: number): Promise<void> {
    if (this._students[id] !== undefined) {
      await this.removeStudent(this._students[id])
    }
  }

  async removeAllStudents (): Promise<void> {
    for (const key in this._students) {
      await this.removeStudent(this._students[key])
    }
  }

  getStudentById (markerId: number): Student | undefined {
    for (const key in this.students) {
      if (this._students[key].markerId === markerId) return this._students[key]
    }
    return undefined
  }

  getListOfDisponibleMarkers (): number[] {
    let numberMax = 0
    const freeMarkers: number[] = []
    const existantMarkers: number[] = []
    for (const key in this._students) {
      if (numberMax < this._students[key].markerId) numberMax = this._students[key].markerId
      existantMarkers.push(this._students[key].markerId)
    }
    for (let int = 1; int <= numberMax; int++) {
      if (!existantMarkers.includes(int)) freeMarkers.push(int)
    }
    // add possible supplementary marker
    freeMarkers.push(numberMax + 1)
    return freeMarkers.sort((a, b) => a - b)
  }

  getPresents (mks: Record<number, string>): void {
    for (const key in this._students) {
      if (mks[this._students[key].markerId - 1] !== undefined) {
        this._students[key].present = true
      }
    }
  }

  getMarkerList (): number[] {
    return Object.keys(this._students).map((i) => this._students[Number(i)].markerId)
  }

  setSubgroupsByVotes (mks: Record<number, string>): void {
    for (const key in this._students) {
      if (mks[this._students[key].markerId - 1] === 'A') {
        this.moveToSubgroup(0, Number(key))
      } else if (mks[this._students[key].markerId - 1] === 'B') {
        this.moveToSubgroup(1, Number(key))
      }
    }
  }

  static async load (id: number): Promise<Group> {
    if (storage.isAvailable()) {
      const data = await storage.db.store_groups.get(id)
      if (data !== undefined) {
        return await Group.remake(data.data)
      }
    }
    return new Group('Groupe inexistant')
  }

  moveToSubgroup (subgroup: number, studentId: number): void {
    if (subgroup !== 0 && subgroup !== 1) return
    if (this._subgroups[subgroup].includes(studentId)) return
    if (this._subgroups[1 - subgroup].includes(studentId)) {
      this._subgroups[1 - subgroup].splice(this._subgroups[1 - subgroup].indexOf(studentId), 1)
    }
    this._subgroups[subgroup].push(studentId)
    this._subgroups[subgroup].sort((a, b) => this._students[Number(a)].markerId - this._students[Number(b)].markerId).map((i) => Number(i))
  }

  randomSubgroups (): void {
    const studentskeys = []
    for (const key in this._students) {
      studentskeys.push(Number(key))
    }
    studentskeys.sort(() => Math.random() - 0.5)
    for (let i = 0, len = studentskeys.length; i < len; i++) {
      this.moveToSubgroup(i % 2, studentskeys[i])
    }
  }

  static async deleteGroup (group: Group): Promise<void> {
    if (storage.isAvailable()) {
      const id = group.id
      // suppression de tous les élèves du groupe
      await group.removeAllStudents()
      // suppression de tous les liens avec ces élèves
      storage.db.links_groups_students.where('groupid').equals(id).delete()
      storage.db.store_groups.where('uid').equals(id).delete(id)
    }
  }

  static async import (textContent: string, groups: Group[]): Promise<[Group[], true] | [ string, false]> {
    if (textContent === '') return ['', false]
    try {
      let groupsImported = JSON.parse(textContent)
      groupsImported = await this.createGroupsFromJson(groupsImported)
      if (groups.length > 1) {
        if (confirm('Ok pour ajouter les groupes\nAnnuler pour remplacer les groupes.')) {
          // on concatène
          groups = [...groups, ...groupsImported]
        } else {
        // effacement des groupes actuels puis remplacement par les nouveaux groupes
          for (const group of groups) {
            await Group.deleteGroup(group)
          }
          groups = groupsImported
        }
      } else {
        groups = groupsImported
      }
      return [groupsImported, true]
    } catch {
      console.log('Pas groupes json')
      return [textContent, false]
    }
  }

  async save (): Promise<number> {
    if (storage.isAvailable()) {
      const dataToSend: StoreGroups = { data: { _name: this._name, _id: this._id, _size: this._size, _subgroups: this._subgroups } }
      if (this._id === 0) {
        return storage.db.store_groups.add(dataToSend)
        // premier enregistrement, on récupère l'id de la base et on l'enregistre.
          .then(async (id: string) => { console.log('Enregistrement du groupe ' + String(id)); this._id = Number(id); await this.save(); return Number(id) })
          .catch((e) => { console.error('Erreur d\'enregistrement des groupes', e); return 0 })
      } else {
        dataToSend.uid = this._id
        return storage.db.store_groups.put(dataToSend)
          .then((id: string) => { console.log('Maj du groupe ' + id); return Number(id) })
          .catch(() => { console.error('Erreur d\'enregistrement des groupes'); return 0 })
      }
    } else {
      return 0
    }
  }

  static async remake (group: Group, id?: number): Promise<Group> {
    const newGroup = new Group(group._name, id)
    // récupération des students du groupe
    newGroup.size = group._size
    newGroup._subgroups = group._subgroups
    if (storage.isAvailable()) {
      const studentsIds = await storage.db.links_groups_students.where('groupid').equals(group._id).toArray()
      studentsIds.forEach(async (data: { studentid: number }) => {
        newGroup._students[data.studentid] = await Student.load(data.studentid)
      })
    }
    return newGroup
  }

  static async createGroupsFromJson (jsonParsed: GroupJson): Promise<Group[]> {
    const importedGroups: Group[] = []
    for (const group of jsonParsed) {
      const newGroup = new Group(group._name)
      await newGroup.init()
      newGroup.size = group._size
      newGroup._subgroups = group._subgroups
      newGroup._students = {}
      await newGroup.save()
      for (const student of Object.values(group._students)) {
        const newStudent = new Student(student._name, student._firstname, student._groups, student._markerId)
        const studentId = await newStudent.init(newGroup.id)
        newGroup._students[studentId] = newStudent
      }
      importedGroups.push(newGroup)
    }
    return importedGroups
  }

  static getGroupById (id: number, groups: Group[]): Group | undefined {
    for (const group of groups) {
      if (group._id === id) return group
    }
  }

  get name (): string {
    return this._name
  }

  set name (name) {
    this._name = name
  }

  get subgroups (): number[][] {
    return this._subgroups
  }

  get id (): number {
    return this._id
  }

  get alphabeticalStudentIds (): number[] {
    return Object.keys(this._students).sort((a, b) => this._students[Number(a)].name.localeCompare(this._students[Number(b)].name)).map((i) => Number(i))
  }

  get orderedByMarkersIdStudentsIds (): number[] {
    return Object.keys(this._students).sort((a, b) => this._students[Number(a)].markerId - this._students[Number(b)].markerId).map((i) => Number(i))
  }

  get students (): Record<number, Student> {
    return this._students
  }

  set students (students) {
    this._students = students
  }

  get size (): number {
    return this._size
  }

  set size (size) {
    this._size = size
  }
}
