import storage from '../services/storage.js'
import Group from './group.js'
import Student from './student.js'

// permet de garder une trace de la configuration d'une session,
// pour lecture a posteriorie et sauvegarde.
export default class Session {
  private readonly _date: number // date de création
  private _id: number // identifiant dans la base
  private readonly _mode: string // mode = normal, double, duel
  private readonly _qcms: string[][] // [[qcmId0, qcmId1, ...], [qcmId0, qcmId1, ...]]
  // permet de récupérer les paramétrages des qcms
  private readonly _shuffleAnswersMode: boolean[] // mélange de l'ordre des réponses si autorisé ou pas
  private readonly _shuffleQuestionsMode: boolean[] // mélange de l'ordre des questions
  private readonly _questions: Array<Array<[number, string | string[]]>> // à compléter si extraits des qcms ou params aléatoires,
  // [[[questionId0, ['A','D', ...]], [questionId1, ['B','C',...]], ...],
  // [[[questionId0, ['A','C', ...]], [questionId1: ['B','A', ...]], ...]]
  // ou [questionId0, '']
  // permet de récupérer l'ordre des questions et l'ordre des réponses de chaque qcm
  private readonly _groups: number[][] // [[gpid1, gpid2, ...], et si extraits : [student0, student1, ...], [student0, student1, ...]]
  private readonly _constructedGroups: Group[]
  private readonly _results: Array<Array<[number, number, string]>>

  constructor (mode: string, qcms: string[][], questions: Array<Array<[number, string | string[]]>>, groups: number[][], shuffleAnswersMode: boolean[], shuffleQuestionsMode: boolean[], id?: number, date?: number, results?: Array<Array<[number, number, string]>>) {
    this._date = date ?? Date.now()
    this._id = id ?? 0
    this._mode = mode
    this._qcms = qcms
    this._questions = [...questions]
    this._groups = [...groups]
    this._constructedGroups = []
    this._shuffleAnswersMode = shuffleAnswersMode
    this._shuffleQuestionsMode = shuffleQuestionsMode
    this._results = results ?? []
  }

  getId (): number {
    return Math.floor(Math.random() * 10000)
  }

  async init (): Promise<void> {
    if (this._id === 0) await this.save()
  }

  async save (): Promise<void> {
    if (storage.isAvailable()) {
      const dataToSend: dataToSend = { data: this }
      if (this._id !== 0) dataToSend.uid = this._id
      await storage.db.store_sessions.put(dataToSend)
        .then((id: number) => { this._id = id })
        .catch(() => { console.error('Erreur de sauvegarde de la session ') })
    }
  }

  async load (id: number): Promise<Session> {
    if (storage.isAvailable()) {
      const result = await storage.db.store_sessions.get(id)
      if (result !== undefined) {
        return Session.remake(result.data)
      } else return new Session('normal', [], [], [], [false], [false])
    } else return new Session('normal', [], [], [], [false], [false])
  }

  static async remake (data: any): Promise<Session> {
    const newSession = new Session(data._mode, data._qcms, data._questions, data._groups, data._shuffleAnswersMode, data._shuffleQuestionsMode, data._id, data._date, data._results)
    // restore groups
    if (data._groups[1] === undefined || data._groups[1].length === 0) {
      await Group.load(data._groups[0][0]).then((group) => newSession._constructedGroups.push(group)).catch((err) => { console.error(err) })
    } else if (data._groups[1].length > 0) {
      const group = new Group('Groupe A')
      for (const id of data._groups[1]) {
        await Student.load(id).then((student) => { group.pushStudent(student) }).catch((err) => { console.error(err) })
      }
      newSession._constructedGroups.push(group)
    }
    if (data._groups[2] === undefined && data._groups[0][1] !== undefined) {
      await Group.load(data._groups[0][1]).then((group) => newSession._constructedGroups.push(group)).catch((err) => { console.error(err) })
    } else if (data._groups[2] !== undefined && data._groups[2].length > 0) {
      const group = new Group('Groupe B')
      for (const id of data._groups[2]) {
        await Student.load(id).then((student) => { group.pushStudent(student) }).catch((err) => { console.error(err) })
      }
      newSession._constructedGroups.push(group)
    }
    return newSession
  }

  getSession (): any {
    return {
      date: this._date,
      id: this._id,
      mode: this._mode,
      qcms: this._qcms,
      questions: this._questions,
      groups: this._groups,
      shuffleAnswersMode: this._shuffleAnswersMode,
      shuffleQuestionsMode: this._shuffleQuestionsMode,
      results: this._results
    }
  }

  static copySession (session: Session): Session {
    return new Session(session.mode, session.qcms, session.questions, session.groups, session.shuffleAnswersMode, session.shuffleQuestionsMode)
  }

  get shuffleAnswersMode (): boolean[] {
    return this._shuffleAnswersMode
  }

  get shuffleQuestionsMode (): boolean[] {
    return this._shuffleQuestionsMode
  }

  get constructedGroups (): Group[] {
    return this._constructedGroups
  }

  get questions (): Array<Array<[number, string | string[]]>> {
    return this._questions
  }

  get groups (): number[][] {
    return this._groups
  }

  get qcms (): string[][] {
    return this._qcms
  }

  get results (): Array<Array<[number, number, string]>> {
    return this._results
  }

  get id (): number {
    return this._id
  }

  get date (): number {
    return this._date
  }

  get mode (): string {
    return this._mode
  }
}

/**
 * qId : question id
 * stId : student id
 * ans : answer
 * */
interface dataToSend {
  data: Session
  uid?: number
}
