let videoSources: Array<[string, string, number]> = []
let selectedSource = 0
let resolution = 1920

const camera = {
  isReady: false,
  /*
  * affiche l'erreur rencontrée passée en paramètre
  */
  handleError: function (error: any): string {
    console.log('navigator.getUserMedia error: ', error)
    return error
  },
  getVideoDevices (deviceInfos: MediaDeviceInfo[]): Array<[string, string, number]> {
    videoSources = []
    let count = 0
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      if (deviceInfo.kind === 'videoinput') {
        let leLabel = ''
        if (deviceInfo.label !== '') leLabel = deviceInfo.label
        else leLabel = 'camera ' + String(videoSources.length + 1)
        const source: [string, string, number] = [ deviceInfo.deviceId, leLabel, count++ ]
        videoSources.push(source)
      }
    }
    if (videoSources.length === 1) {
      selectedSource = 0
      if (localStorage !== undefined) {
        localStorage.camera = selectedSource
      }
    } else if (videoSources.length === 0) {
      alert('Pas de caméra détectée')
      if (localStorage !== undefined) {
        delete localStorage.camera
      }
    } else {
      selectedSource = 1 // 2nd camera
      // Add buttons to change cameras input
    }
    return videoSources
  },
  detect (): PromiseLike<string | Array<[string, string, number]>> {
    return navigator.mediaDevices.enumerateDevices().then(this.getVideoDevices).catch(this.handleError)
  },
  setSource (id: number) {
    selectedSource = id
  },
  change () {
    if (videoSources.length === 1) return false
    if (videoSources.length > 1) {
      selectedSource = (selectedSource + 1) % videoSources.length
    }
    if (localStorage !== undefined) {
      localStorage.camera = selectedSource
    }
  },
  setResolution (resol: number): void {
    resolution = resol
  },
  stop (videoElement: HTMLVideoElement) {
    if (videoElement.srcObject !== null && videoElement.srcObject instanceof MediaStream) {
      const tracks = videoElement.srcObject.getTracks()
      tracks.forEach((track) => { track.stop() })
      videoElement.srcObject = null
    }
    videoElement.classList.add('is-hidden')
  },
  start (videoElement: HTMLVideoElement) {
    // stop all remaining streams
    if (videoElement.srcObject !== null && videoElement.srcObject instanceof MediaStream) {
      const tracks = videoElement.srcObject.getTracks()
      tracks.forEach((track) => { track.stop() })
      videoElement.srcObject = null
    }
    if (selectedSource < 0) {
      return
    }
    const videoSource = videoSources[selectedSource][0]
    videoElement.classList.remove('is-hidden')
    const constraints = {
      video: {
        width: {
          min: 640,
          ideal: resolution
        },
        deviceId: videoSource !== '' ? { exact: videoSource } : undefined
      },
      audio: false
    }
    if (videoElement.onerror === undefined) {
      videoElement.onerror = () => {
        if (videoSources.length > 1) {
          console.log('Erreur with video start')
        }
      }
    }
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      console.log('Camera infos', stream.getVideoTracks()[0].getSettings().width, stream.getVideoTracks()[0].getSettings().height)
      videoElement.srcObject = stream
      videoElement.onloadedmetadata = () => { void videoElement.play() }
      this.isReady = true
    }).catch((err) => {
      console.log(String(err.name) + ': ' + String(err.message))
      if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        // required track is missing
      } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        // webcam or mic are already in use
        console.log('Problème de lecture du flux :(')
      } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
        // constraints can not be satisfied by avb. devices
      } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        // permission denied in browser
      } else if (err.name === 'TypeError' || err.name === 'TypeError') {
        // empty constraints object
      } else {
        // other errors
      }
    })
  }
}
export default camera
