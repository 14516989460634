import Group from '../models/group.js'
import utils from './utils.js'
import AR from '../../js/aruco.js'

const dictionary = new AR.Dictionary('QCMCAM_4X4_157h3')
export const dicLength = dictionary.codeList.length

export function generateMarker (groups: Group[], lettersColor: string, markerId: number | undefined = undefined, forceGroup: boolean = false): HTMLCanvasElement {
  emptyLog()
  markerId = formatMarkerId(markerId)
  const canvas = dictionary.generateCANVAS(markerId - 1, 720, 720)
  if (markerId === 0) return canvas
  addLettersAndStudentsNames(canvas, markerId, lettersColor, groups, forceGroup)
  return canvas
}

function emptyLog (): void {
  const logDiv = document.getElementById('log')
  if (logDiv !== null) logDiv.innerHTML = ''
}

function formatMarkerId (markerId: number | undefined): number {
  const mid = document.getElementById('mid') as HTMLFormElement
  if (typeof markerId !== 'number') return 0
  if (markerId === undefined && mid !== null) markerId = Number(mid.value)
  if (Number(markerId) > dicLength || Number(markerId) <= 0) {
    // todo : à revoir en fonction du max du modèle choisi
    alert('Numéro non valide, il faut un nombre entre 1 et ' + String(dicLength))
    return 0
  } else {
    return markerId
  }
}

function addLettersAndStudentsNames (canvas: HTMLCanvasElement, markerId: number, lettersColor: string, groups: Group[], forceGroup: boolean = false): void {
  let group: Group | undefined
  if (forceGroup) group = groups[0]
  else group = getSelectedGroup(groups)
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.fillStyle = lettersColor
  ctx.save()
  const lettres = [ 'A', 'B', 'C', 'D' ]
  const positionIndice = Math.floor(Math.random() * 4)
  for (let i = 0; i < 4; i++) {
    ctx.textAlign = 'center'
    ctx.font = 'bold 42px sans-serif'
    ctx.fillText(lettres[i], canvas.width / 2, 80)
    if (i === positionIndice) {
      ctx.textAlign = 'end'
      ctx.fillText(String(markerId), 620, 80)
    }
    if (i === ((positionIndice + 1) % 4) && (group !== undefined)) {
      const user = group.getStudentById(markerId)
      if (user !== undefined) {
        const userName = ((user.firstname ?? '') + ' ' + (user.name ?? '')).trim().slice(0, 17)
        ctx.textAlign = 'end'
        ctx.font = 'bold 24px sans-serif'
        ctx.fillText(userName, 620, 80)
      }
    }
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate(Math.PI / 2)
    ctx.translate(-canvas.width / 2, -canvas.height / 2)
  }
  ctx.restore()
}

function getSelectedGroup (groups: Group[]): Group | undefined {
  const selectedOption = utils.forms.getValue('classes')
  if (selectedOption !== null && selectedOption !== undefined && selectedOption !== '0') {
    return Group.getGroupById(Number(selectedOption), groups)
  }
}
