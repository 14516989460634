<script lang="ts">
  import { onDestroy } from 'svelte'
  import ShowSettings from './settings/ShowSettings.svelte'
  import ShowPlay from './ShowPlay.svelte'
  import utils from '../../lib/ts/services/utils.js'
  // import language from '../../lib/ts/services/language.js'
  import Student from '../../lib/ts/models/student.js'
  import Group from '../../lib/ts/models/group.js'
  import Qcm from '../../lib/ts/models/qcm.js'
  import Question from '../../lib/ts/models/question.js'
  // import type Answer from '../../lib/ts/models/answer.js'
  import detection from '../../lib/ts/services/markerdetection.js'
  import Votes from '../../lib/ts/models/votes.js'
  import Session from '../../lib/ts/models/session.js'
  import { config, currentQcm, currentQcm2, currentQuestions, currentQuestions2, tictoc } from '../../lib/ts/services/store'
  import { setQCMQuestionFontSize, setStyleSheetColor } from '../../lib/ts/services/qcm'
  import { renderLatex } from '../../lib/ts/services/katex'
  import camera from '../../lib/ts/services/camera'
  import { get } from 'svelte/store';

  onDestroy(() => {
    $tictoc.forEach((t) => {
      if (t != null) clearInterval(t)
    })
  })
interface voteRecord {
  value: string[]
  timestamp: number
  temporaryValue: string
  vues: number
  history: Array<[string, string]>
}

let currentQuestionIndex: number = 1
let currentQuestionIndex2: number = 1
let votes: Votes
let votes2: Votes
let dectectionWithCamera = false
let currentGroup: Group | undefined
let session: Session

// let goodAnswers: string[] = []
// let qcmChoice: number[] = []
  function answersStatistics (votes: Votes[], index: number, targetDiv: HTMLElement): void {
    const stats = votes[0].getAnswersOfQuestion(index - 1)
    if (votes[1] !== undefined) {
      const stats2 = votes[1].getAnswersOfQuestion(index - 1)
      for (const key in stats) {
        if (stats2[key] !== undefined) {
          if (stats[key] === undefined) stats[key] = 0
          stats[key] = stats[key] + stats2[key]
        }
      }
    }
    if (utils.object.getLength(stats) > 0) {
      let total = 0
      const colors = ['#3e95cd', '#3e95cd', '#3e95cd', '#3e95cd']
      if (stats.A !== undefined) total += Number(stats.A)
      if (stats.B !== undefined) total += Number(stats.B)
      if (stats.C !== undefined) total += Number(stats.C)
      if (stats.D !== undefined) total += Number(stats.D)
      if (stats.A !== undefined) {
        const percent = Math.round((Number(stats.A) / total) * 100)
        const li = targetDiv.querySelector('li.answer:nth-child(1)') as HTMLLIElement
        if (li !== null) {
          appendStatBar(li, colors[0], percent)
        }
      }
      if (stats.B !== undefined) {
        const percent = Math.round((Number(stats.B) / total) * 100)
        const li = targetDiv.querySelector('li.answer:nth-child(2)') as HTMLLIElement
        if (li !== null) {
          appendStatBar(li, colors[1], percent)
        }
      }
      if (stats.C !== undefined) {
        const percent = Math.round((Number(stats.C) / total) * 100)
        const li = targetDiv.querySelector('li.answer:nth-child(3)') as HTMLLIElement
        if (li !== null) {
          appendStatBar(li, colors[2], percent)
        }
      }
      if (stats.D !== undefined) {
        const percent = Math.round((Number(stats.D) / total) * 100)
        const li = targetDiv.querySelector('li.answer:nth-child(4)') as HTMLLIElement
        if (li !== null) {
          appendStatBar(li, colors[3], percent)
        }
      }
    }
  }
  function appendStatBar (li: HTMLLIElement, color: string, percent: number): void {
    const child = li.querySelector('.statsbar')
    if (child !== null) {
      li.removeChild(child)
    }
    const statsBar = utils.DOM.create('div')
    statsBar.className = 'statsbar'
    const percentBar = utils.DOM.create('div')
    percentBar.className = 'percentbar'
    percentBar.style.width = String(percent) + '%'
    percentBar.style.backgroundColor = color
    statsBar.appendChild(percentBar)
    li.appendChild(statsBar)
  }
  function showGoodAnswersOfStudents (second = ''): void {
    let thisvotes, currentQ, currentQcm
    if (second === '') {
      thisvotes = votes
      currentQ = currentQuestionIndex
      currentQcm = $currentQuestions
    } else {
      thisvotes = votes2
      currentQ = currentQuestionIndex2
      currentQcm = $currentQuestions2
    }
    if (currentGroup === undefined) return
    for (const id in thisvotes.markersAnswers) {
      if (thisvotes.markersAnswers[id][currentQ - 1] !== undefined) {
        const goodAnswers = utils.numberToLetter(currentQcm[currentQ - 1].getGoodAnswer())
        if (utils.array.areEqual(thisvotes.markersAnswers[id][currentQ - 1].value, goodAnswers)) {
          utils.DOM.addClass('marker' + id, 'hasgood')
        }
      }
    }
  }
  function displayStatsOfAnswers (second = ''): void {
    let target = '#qcm-container' + second
    let thisvotes, currentQ
    if (session.mode === 'normal') {
      target += ' .question:nth-of-type(' + String(currentQuestionIndex) + ')'
      thisvotes = [votes]
      currentQ = currentQuestionIndex
    } else if (session.mode === 'duel') {
      target += ' .question:nth-of-type(' + String(currentQuestionIndex) + ')'
      thisvotes = [votes, votes2]
      currentQ = currentQuestionIndex
    } else if (second === '') {
      target += ' .question:nth-of-type(' + String(currentQuestionIndex2) + ')'
      thisvotes = [votes2]
      currentQ = currentQuestionIndex2
    } else {
      target += ' .question:nth-of-type(' + String(currentQuestionIndex) + ')'
      thisvotes = [votes]
      currentQ = currentQuestionIndex
    }
    const targetDiv = document.querySelector(target) as HTMLDivElement
    if (targetDiv !== null) {
      answersStatistics(thisvotes, currentQ, targetDiv)
    }
  }
  function startDetection (second = ''): void {
    const tictocId = second === '0' ? 0 : 1
    const tictocTictocId = $tictoc[tictocId]
    if (tictocTictocId !== null) {
      clearInterval(tictocTictocId)
      $tictoc[tictocId] = null
    }
    if (second === '') {
      if($currentQuestions[currentQuestionIndex - 1] === undefined) return
    } else {
      if($currentQuestions2[currentQuestionIndex2 - 1] === undefined) return
    }
    let thisvotes: Votes
    let timeZero = Infinity
    let thisQuestion: Question
    if (second === '') {
      thisvotes = votes
      thisQuestion = $currentQuestions[currentQuestionIndex - 1]
      votes.setQuestion(currentQuestionIndex - 1, thisQuestion)
    } else {
      thisvotes = votes2
      thisQuestion = $currentQuestions2[currentQuestionIndex2 - 1]
      votes2.setQuestion(currentQuestionIndex2 - 1, thisQuestion)
    }
    if (session.mode === 'duel') {
      votes2.setQuestion(currentQuestionIndex - 1, thisQuestion)
    }
    if (thisQuestion.timedMode) {
      const $divChrono = document.querySelector('#qcm-container' + second + ' .chrono') as HTMLDivElement
      if ($divChrono !== null) {
        $divChrono.style.width = '100%'
      } else {
        const $divChronoContainer = utils.DOM.create('section', { class: 'chrono-container' })
        const $divChrono = utils.DOM.create('article', { class: 'chrono' })
        $divChronoContainer.appendChild($divChrono)
        document.getElementById('qcm-container' + second)?.prepend($divChronoContainer)
      }
    }
    utils.DOM.removeClass('cameraCopy', 'inactiv')
    setTimeout(() => {
      timeZero = Date.now()
      $tictoc[tictocId] = setInterval(() => detectionInterval(thisQuestion, second, timeZero, thisvotes), 97)
    }, 500)
  }
  function startCountDown (second = ''): void {
    let timeZero = Infinity
    let thisQuestion = $currentQuestions[currentQuestionIndex - 1]
    if (second === '0') {
      thisQuestion = $currentQuestions2[currentQuestionIndex2 - 1]
    }
    if (!thisQuestion.timedMode) {
      return
    }
    const $divChrono = document.querySelector('#qcm-container' + second + ' .chrono') as HTMLDivElement
    if ($divChrono !== null) {
      $divChrono.style.width = '100%'
    } else {
      const $divChronoContainer = utils.DOM.create('section', { class: 'chrono-container' })
      const $divChrono = utils.DOM.create('article', { class: 'chrono' })
      $divChronoContainer.appendChild($divChrono)
      document.getElementById('qcm-container' + second)?.prepend($divChronoContainer)
    }
    const tictocId = second === '0' ? 0 : 1
    const tictocTictocId = $tictoc[tictocId]
    if (tictocTictocId !== null) {
      clearInterval(tictocTictocId)
      $tictoc[tictocId] = null
    }
    setTimeout(() => {
      timeZero = Date.now()
      $tictoc[tictocId] = setInterval(() => {
        const $divChrono = document.querySelector('#qcm-container' + second + ' .chrono') as HTMLDivElement
        if (Date.now() - timeZero > thisQuestion.duration * 1000) {
          if ($divChrono !== null) {
            $divChrono.style.width = '0'
          }
          const tictocTictocId = $tictoc[tictocId]
          if (tictocTictocId !== null) {
            clearInterval(tictocTictocId)
            $tictoc[tictocId] = null
          }
          nextQuestion(second)
        } else {
          if ($divChrono !== null) {
            const timeLeft = Math.floor((thisQuestion.duration * 1000 - (Date.now() - timeZero)) * 100 / (thisQuestion.duration * 1000))
            $divChrono.style.width = String(timeLeft) + '%'
          }
        }
      }, 97)
    }, 1000)
  }
  function startCallRoll (group1: Group, group2?: Group) {
    const tictoc0 = $tictoc[0]
    if (tictoc0 !== null) {
      clearInterval(tictoc0)
      $tictoc[0] = null
    }
    $tictoc[0] = setInterval(() => {
      const mks: Record<number, string> | undefined = detection.detect()
      if (utils.object.getLength(mks) > 0 && mks !== undefined) {
        group1.getPresents(mks)
        if (group2 !== undefined) group2.getPresents(mks)
        callRollSetPresentState(group1, group2)
      }
    }, 97)
  }
  function stopCallRoll () {
    const tictoc0 = $tictoc[0]
    if (tictoc0 !== null) {
      clearInterval(tictoc0)
      $tictoc[0] = null
    }
  }
  function stopDetection (second = '', force = false): boolean {
    if (!detection.isStarted() && !force) {
      startDetection(second)
      return false
    }
    function getResults (group: Group, answers: Record<number, Record<number, voteRecord>>): Array<[number, number, string]> {
      const results: Array<[number, number, string]> = []
      for (const [markerNumber, entry] of Object.entries(answers)) {
        const student = group.getStudentById(parseInt(markerNumber))
        let studentId = 0
        if (student !== undefined) {
          studentId = Number(student.id)
        }
        if (studentId === 0) {
          console.warn('getResults', 'studentId is 0', markerNumber, student)
          continue
        }
        for (const [questionNumber, subentry] of Object.entries(entry)) {
          const values = subentry.value
          results.push([Number(questionNumber), studentId, values.join(',') ?? ''])
        }
      }
      return results
    }
    detection.stop()
    utils.DOM.addClass('cameraCopy', 'inactiv')
    const tictocId = second === '0' ? 0 : 1
    const tictocTictocId = $tictoc[tictocId]
    if (tictocTictocId !== null) clearInterval(tictocTictocId)
    $tictoc[tictocId] = null
    // on enregistre les données de session plutôt que les données de vote
    if (second === '') {
      session.results[0] = getResults(session.constructedGroups[0], votes.markersAnswers)
      session.save().catch(err => { console.error(err) })
    }
    if (second === '0' || session.mode === 'duel') {
      session.results[1] = getResults(session.constructedGroups[1], votes2.markersAnswers)
      session.save().catch(err => { console.error(err) })
    }
    return true
  }
  async function startQCM (groups: Group[], rollCallIsOver = false) {
    // liste des groupes sélectionnés ou créés via l'appel
    const mode = utils.forms.getRadioValue('qcm-mode-choice') ?? 'normal'
    const shuffleAnswersMode: boolean[] = [false]
    const shuffleQuestionsMonde: boolean[] = [false]
    const listsOfQCMs: string[][] = [[]]
    const listsOfQuestions: number[][] = [[]]
    const listOfConfigs: Array<Array<string | string[]>> = [[]]
    const listsOfStudents: number[][] = [[]]
    // liste des QCM sélectionnés
    const selectQCM = utils.DOM.getById('select-qcm-choice') as HTMLSelectElement
    if (selectQCM !== null) {
      for (const option of Array.from(selectQCM.options)) {
        if (option.selected && option.value !== '') {
          listsOfQCMs[0].push(option.value)
        }
      }
      // get questions id for each QCM
      for (const qcmId of listsOfQCMs[0]) {
        const qcm = await Qcm.load(Number(qcmId))
        listsOfQuestions[0] = listsOfQuestions[0].concat(qcm.questionsList)
      }
      // check if extract n questions from list
      const haveToExtract = utils.forms.getRadioValue('qcm-slides')
      if (haveToExtract === 'extract') {
        const newList = []
        const copyOfList = listsOfQuestions[0].slice()
        const num = utils.forms.getValue('qcm-slides-number')
        if (num === null || num === '' || num === undefined) {
          console.error('qcm-slides-number not found')
          return
        }
        const number = parseInt(num)
        for (let i = 0; i < number; i++) {
          const random = Math.floor(Math.random() * copyOfList.length)
          newList.push(copyOfList[random])
          copyOfList.splice(random, 1)
        }
        listsOfQuestions[0] = newList
      }
      // shuffle questions
      const orderOfQuestions = utils.forms.getRadioValue('qcm-order-questions')
      if (orderOfQuestions === 'random') {
        shuffleQuestionsMonde[0] = true
        if (haveToExtract !== 'extract') { // si extraction, c'est déjà random
          listsOfQuestions[0].sort(() => Math.random() - 0.5)
        }
      }
      const orderAnswers = utils.forms.getRadioValue('qcm-order-answers')
      // list of shuffled questions
      if (orderAnswers === 'random') {
        shuffleAnswersMode[0] = true
        // on mélange les réponses des questions et on stocke pour l'analyse des scores
        for (const [index, question] of listsOfQuestions[0].entries()) {
          const aQuestion = await Question.load(question)
          if (aQuestion.shuffleMode) {
            const conf = ['A', 'B', 'C', 'D'].slice(0, aQuestion.answers.length - 1)
            conf.sort(() => Math.random() - 0.5)
            listOfConfigs[0][index] = conf
          } else {
            listOfConfigs[0][index] = ''
          }
        }
      }
    } else {
      alert('Veuillez sélectionner au moins un QCM')
      return
    }
    if (mode === 'double') {
      listsOfQCMs.push([])
      listsOfQuestions.push([])
      listOfConfigs.push([])
      const selectQCM2 = utils.DOM.getById('select-qcm-choice0') as HTMLSelectElement
      if (selectQCM2 !== null) {
        for (const option of Array.from(selectQCM2.options)) {
          if (option.selected && option.value !== '') {
            listsOfQCMs[1].push(option.value)
          }
        }
      }
      // get questions id for each QCM
      for (const qcmId of listsOfQCMs[1]) {
        const qcm = await Qcm.load(Number(qcmId))
        listsOfQuestions[1] = listsOfQuestions[1].concat(qcm.questionsList)
      }
      // check if extract n questions from list
      const haveToExtract = utils.forms.getRadioValue('qcm-slides0')
      if (haveToExtract === 'extract') {
        const newList = []
        const copyOfList = listsOfQuestions[1].slice()
        const num = utils.forms.getValue('qcm-slides-number0')
        if (num === null || num === '' || num === undefined) {
          console.error('qcm-slides-number not found')
          return
        }
        const number = parseInt(num)
        for (let i = 0; i < number; i++) {
          const random = Math.floor(Math.random() * copyOfList.length)
          newList.push(copyOfList[random])
          copyOfList.splice(random, 1)
        }
        listsOfQuestions[1] = newList
      }
      // shuffle questions
      const orderOfQuestions = utils.forms.getRadioValue('qcm-order-questions0')
      if (orderOfQuestions === 'random') {
        shuffleQuestionsMonde[1] = true
        if (haveToExtract !== 'extract') { // si extraction, c'est déjà random
          listsOfQuestions[1].sort(() => Math.random() - 0.5)
        }
      }
      const orderAnswers = utils.forms.getRadioValue('qcm-order-answers')
      // list of shuffled questions
      if (orderAnswers === 'random') {
        shuffleAnswersMode[1] = true
        // on mélange les réponses des questions et on stocke pour l'analyse des scores
        for (const [index, question] of listsOfQuestions[1].entries()) {
          const aQuestion = await Question.load(question)
          if (aQuestion.shuffleMode) {
            const conf = ['A', 'B', 'C', 'D'].slice(0, aQuestion.answers.length - 1)
            conf.sort(() => Math.random() - 0.5)
            listOfConfigs[1][index] = conf
          } else {
            listOfConfigs[1][index] = ''
          }
        }
      }
    }
    // listes des élèves
    const selectGroup = utils.DOM.getById('select-group-choice') as HTMLSelectElement
    let group1, group2
    const groupIds = []
    if (selectGroup !== null && mode !== 'double') {
      for (const option of Array.from(selectGroup.options)) {
        if (option.selected && option.value !== '') {
          groupIds.push(Number(option.value))
        }
      }
      if (groupIds.length > 2) {
        alert('Trop de groupes sélectionnés')
        return
      } else if (mode === 'normal' && groupIds.length > 1) {
        alert('Trop de groupes sélectionnés')
        return
      } else if (mode === 'normal') {
        group1 = Group.getGroupById(Number(groupIds[0]), groups) as Group
        listsOfStudents[0] = Object.keys(group1.students).map((i) => Number(i))
      } else if (mode === 'duel') {
        if (groupIds.length === 1) {
          // usage des sous-groupes si existants
          const group0 = Group.getGroupById(Number(groupIds[0]), groups) as Group
          if (group0.subgroups[0].length === 0 || group0.subgroups[1].length === 0) {
            alert('Le groupe sélectionné n\'a pas de sous-groupes défini')
            return
          } else {
            listsOfStudents[0] = group0.subgroups[0]
            listsOfStudents[1] = group0.subgroups[1]
            group1 = new Group('Groupe A')
            for (const id of group0.subgroups[0]) {
              if(group0.students[id] === undefined) {
                continue
              }
              group1.pushStudent(await Student.load(id))
            }
            group2 = new Group('Groupe B')
            for (const id of group0.subgroups[1]) {
              if(group0.students[id] === undefined) {
                continue
              }
              group2.pushStudent(await Student.load(id))
            }
          }
        } else if (groupIds.length === 2) {
          group1 = Group.getGroupById(Number(groupIds[1]), groups) as Group
          group2 = Group.getGroupById(Number(groupIds[0]), groups) as Group
          // comparer les numéros de marqueurs des groupes
          let double = false
          for (const key of Object.keys(group1.students)) {
            for (const key2 of Object.keys(group2.students)) {
              if (group1.students[Number(key)].markerId === group2.students[Number(key2)].markerId) {
                double = true
                break
              }
            }
            if (double) {
              alert('Il y a au moins une carte de numéro identique dans les 2 groupes')
              return
            }
          }
        }
      }
    } else {
      // groupe double, donc id pris dans les radios
      const groupIdRadio0 = utils.forms.getRadioValue('groupqcmselect')
      const groupIdRadio1 = utils.forms.getRadioValue('groupqcmselect2')
      if (groupIdRadio0 !== null && groupIdRadio1 !== null) {
        if (groupIdRadio0.indexOf(',') > 0) {
          // cas de sous groupes
          const group0 = Group.getGroupById(Number(groupIdRadio0.split(',')[0]), groups) as Group
          listsOfStudents[0] = group0.subgroups[groupIdRadio0.split(',')[1] === '0' ? 0 : 1]
          listsOfStudents[1] = group0.subgroups[groupIdRadio0.split(',')[1] === '0' ? 1 : 0]
          group1 = new Group('Groupe A')
          for (const id of group0.subgroups[groupIdRadio0.split(',')[1] === '0' ? 0 : 1]) {
            if(group0.students[id] === undefined) {
              continue
            }
            group1.pushStudent(await Student.load(id))
          }
          group2 = new Group('Groupe B')
          for (const id of group0.subgroups[groupIdRadio0.split(',')[1] === '0' ? 1 : 0]) {
            if(group0.students[id] === undefined) {
              continue
            }
            group2.pushStudent(await Student.load(id))
          }
        } else {
          group1 = Group.getGroupById(Number(groupIdRadio0), groups) as Group
          group2 = Group.getGroupById(Number(groupIdRadio1), groups) as Group
          listsOfStudents[0] = Object.keys(group1.students).map((i) => Number(i))
          listsOfStudents[1] = Object.keys(group2.students).map((i) => Number(i))
        }
      }
    }
    const groupsList = [groupIds, listsOfStudents[0], listsOfStudents[1]]
    const questionsList: Array<Array<[number, string | string[]]>> = []
    questionsList.push([])
    for (const [index, question] of listsOfQuestions[0].entries()) {
      questionsList[0].push([question, listOfConfigs[0][index]])
    }
    if (listsOfQuestions[1] !== undefined) {
      questionsList.push([])
      for (const [index, question] of listsOfQuestions[1].entries()) {
        questionsList[1].push([question, listOfConfigs[1][index]])
      }
    }
    // C'est parti
    session = new Session(mode, listsOfQCMs, questionsList, groupsList, shuffleAnswersMode, shuffleQuestionsMonde)
    if (group1 !== undefined) {
      session.constructedGroups[0] = group1
    }
    if (group2 !== undefined) {
      session.constructedGroups[1] = group2
    }
    await session.init()
    // démarrage de la détection
    dectectionWithCamera = false
    if (camera.isReady) {
      const videoElement = utils.DOM.getById('videotest') as HTMLVideoElement
      dectectionWithCamera = detection.start(videoElement)
    }
    const howTostart = utils.forms.getRadioValue('qcm-start')
    utils.DOM.removeClass('QCMCamShow', 'double')
    utils.DOM.removeClass('QCMCamShow', 'duel')
    if (howTostart === 'appel' && !rollCallIsOver && group1 !== undefined) {
      callRoll(group1, group2)
      return
    }
    utils.DOM.addClass('show-settings', 'is-hidden')
    utils.DOM.removeClass('QCMCamShow', 'is-hidden')
    setStyleSheetColor('destroy')
    let addCache = false
    if (howTostart === 'hidden') addCache = true
    if (session.questions[0].length > 0) {
      // couleurs du qcm
      const qcmparams = await Qcm.load(Number(session.qcms[0][0]))
      const qcm1 = new Qcm('QCM 1', 'qcmcam2')
      for (const questionconf of session.questions[0]) {
        qcm1.questionsList.push(Number(questionconf[0]))
      }
      // TODO : deux qcm possibles, en parallèle
      if (qcmparams.name !== 'QCM vide') {
        currentQcm.set(qcm1)
        setStyleSheetColor('1')
        currentQuestionIndex = 1
        const isColored = qcmparams.coloredAnswers
        const questions = await qcm1.loadQuestions()
        currentQuestions.set(questions)
        slidePopulate(questions, '', addCache, isColored)
        const numero = document.getElementById('qcm-numero'+(session.mode === 'double' ? '1' : '')) as HTMLElement
        numero.innerHTML = String(1) + ' / ' + String(questions.length)
      }
      if (session.mode === 'duel') {
        utils.DOM.addClass('QCMCamShow', 'duel')
      }
      if (session.mode === 'double') {
        utils.DOM.addClass('QCMCamShow', 'double')
        const getqcm = await Qcm.load(Number(session.qcms[1][0]))
        const qcm2 = new Qcm('QCM 2', 'qcmcam2')
        for (const questionconf of session.questions[1]) {
          qcm2.questionsList.push(Number(questionconf[0]))
        }
        if (getqcm.name !== 'QCM vide') {
          currentQcm2.set(qcm2)
          setStyleSheetColor('0')
          currentQuestionIndex2 = 1
          const isColored = getqcm.coloredAnswers
          let addCache = false
          if (howTostart === 'hidden') addCache = true
          const questions = await qcm2.loadQuestions()
          currentQuestions2.set(questions)
          slidePopulate(questions, '0', addCache, isColored)
          const numero = document.getElementById('qcm-numero0') as HTMLElement
          numero.innerHTML = String(1) + ' / ' + String(questions.length)
        }
      }
      if (group1 !== undefined) {
        slideGroupPopulate(group1)
        slideMarkersReset()
        votes = new Votes(group1.getMarkerList())
      }
      if (group2 !== undefined) {
        slideGroupPopulate(group2, '0')
        slideMarkersReset('0')
        votes2 = new Votes(group2.getMarkerList())
      }
      utils.DOM.removeClass('qm-diaporama-selection', 'is-active')
      // démarrer la détection ne peut commencer avant
      if (dectectionWithCamera && !addCache) {
        startDetection()
        if (mode === 'double') {
          startDetection('0')
        }
      } else if (!addCache) {
        startCountDown()
        if (mode === 'double') {
          startCountDown('0')
        }
      }
    }
  }
  function callRoll (group1: Group, group2?: Group) {
    utils.DOM.removeClass('qm-diaporama-selection', 'is-active')
    utils.DOM.addClass('qm-callroll', 'is-active')
    const $presentContainer = utils.DOM.getById('presents-container')
    const $absentContainer = utils.DOM.getById('absents-container')
    if ($presentContainer !== null && $absentContainer !== null) {
      $presentContainer.innerHTML = ''
      $absentContainer.innerHTML = ''
    }
    if (group1 !== undefined) {
      callRollPopulate(group1)
    }
    if (group2 !== undefined) {
      callRollPopulate(group2)
    }
    startCallRoll(group1, group2)
  }
  function callRollPopulate (group: Group) {
    const $container = utils.DOM.getById('absents-container')
    if ($container !== null) {
      $container.innerHTML = ''
      for (const key in group.students) {
        const student = group.students[key]
        student.present = false
        const $containerstudent = utils.DOM.create('article', { class: 'student', id: 'student' + String(student.markerId) })
        $containerstudent.dataset.markerId = String(student.markerId)
        const $number = utils.DOM.create('div', { text: String(student.markerId), class: 'markerid' })
        const $div = utils.DOM.create('div', { class: 'studentname', text: ((student.firstname ?? '') + ' ' + (student.name ?? '').trim()) })
        $containerstudent.appendChild($number)
        $containerstudent.appendChild($div)
        $container.appendChild($containerstudent)
      }
    }
  }
  function callRollSetPresentState (group1: Group, group2?: Group) {
    // changer les participants présents de place
    const $presentContainer = utils.DOM.getById('presents-container')
    const $absentContainer = utils.DOM.getById('absents-container')
    if ($presentContainer !== null && $absentContainer !== null) {
      if ($absentContainer.children.length > 0) {
        for (const absent of Array.from($absentContainer.children)) {
          for (const key in group1.students) {
            const student = group1.students[key]
            if ((absent as HTMLElement).dataset.markerId === String(student.markerId) && student.present) {
              $presentContainer.appendChild(absent)
            }
          }
        }
        if (group2 !== undefined) {
          for (const absent of Array.from($absentContainer.children)) {
            for (const key in group2.students) {
              const student = group2.students[key]
              if ((absent as HTMLElement).dataset.markerId === String(student.markerId) && student.present) {
                $presentContainer.appendChild(absent)
              }
            }
          }
        }
      } else {
        stopCallRoll()
      }
    }
  }
  function slidePopulate (questions: Question[], second = '', addCache = false, isColored = false) {
    const qcmContainer = utils.DOM.getById('qcm-container' + second)
    if (qcmContainer !== null) {
      qcmContainer.innerHTML = ''
      if (isColored) {
        qcmContainer.classList.add('colored')
      } else {
        qcmContainer.classList.remove('colored')
      }
    }
    if (addCache) {
      slideAddCache(second)
    }
    for (const [index, question] of questions.entries()) {
      let defaultClass = 'question minus100'
      if (index === 0) defaultClass = 'question slidein'
      const questionContainer = utils.DOM.create('div', { id: 'questionContainer' + String(index), class: defaultClass })
      if (!isNaN(question.textSize)) questionContainer.style.fontSize = String(question.textSize) + 'em'
      questionContainer.classList.add(question.displayMode)
      const questionHeader = utils.DOM.create('header', { class: 'question-content ' + question.type })
      const $divHeader = utils.DOM.create('div')
      $divHeader.innerHTML = question.content
      questionHeader.appendChild($divHeader)
      const answersContainer = utils.DOM.create('ol', { class: 'answers ' + question.type }) as HTMLOListElement
      const answersList = [...question.answers]
      // if (question.shuffleMode) utils.array.shuffle(answersList)
      let thisIndex = 0
      if (session.mode === 'double' && second === '0') {
        thisIndex = 1
      }
      if (session.questions[thisIndex][index][1] !== undefined) {
        const order = session.questions[thisIndex][index][1]
        for (let i = 0, len = question.answers.length; i < len; i++) {
          answersList[order.indexOf(['A', 'B', 'C', 'D'][i])] = question.answers[i]
        }
      }
      for (const answer of answersList) {
        const answerContent = utils.DOM.create('li', { class: 'answer ' + question.type })
        if (answer.content === '') answerContent.innerHTML = '<p>&nbsp;</p>'
        else answerContent.innerHTML = answer.content
        if (answer.isCorrect) { answerContent.classList.add('is-correct') }
        answersContainer.appendChild(answerContent)
      }
      questionContainer.appendChild(questionHeader)
      questionContainer.appendChild(answersContainer)
      qcmContainer?.appendChild(questionContainer)
    }
    renderLatex('qcm-container' + second)
    const fin = utils.DOM.create('div', { id: 'slideEnd' + second, class: 'question fin minus100' })
    fin.innerHTML = 'FIN'
    qcmContainer?.appendChild(fin)
    setQCMQuestionFontSize()
  }
  function slideAddCache (second = '') {
    if (dectectionWithCamera && detection.isStarted()) stopDetection(second)
    const qcmContainer = utils.DOM.getById('qcm-container' + second)
    const cacheIsActiv = document.querySelector('#qcm-container' + second + ' .qcm-cache')
    if (cacheIsActiv !== null) {
      qcmContainer?.removeChild(cacheIsActiv)
      // on démarre la détection quand on enlève le cache
      if (dectectionWithCamera) startDetection(second)
      else startCountDown(second)
      return
    }
    const divCache = utils.DOM.create('div', { id: 'qcm-cache' + second, class: 'qcm-cache' })
    divCache.innerHTML = '<div><i class="ri-play-circle-line ri-5x"></i></div>'
    divCache.onclick = () => {
      qcmContainer?.removeChild(divCache)
      if (dectectionWithCamera) startDetection(second)
      else startCountDown(second)
    }
    qcmContainer?.appendChild(divCache)
  }
  function slideGroupPopulate (group: Group, second = '') {
    const $groupContainer = utils.DOM.getById('participant-list' + second)
    const $absentContainer = utils.DOM.create('div', { id: 'absents-list' + second })
    if ($groupContainer !== null) {
      $groupContainer.innerHTML = ''
      currentGroup = group
      const studentsIdsByMarkers = group.orderedByMarkersIdStudentsIds
      for (const key of studentsIdsByMarkers) {
        const student = group.students[key]
        const studentContainer = utils.DOM.create('article', { id: 'studentContainer' + String(key), class: 'student' })
        const studentMarker = utils.DOM.create('div', { id: 'marker' + String(student.markerId), class: 'markerid', text: String(student.markerId) })
        const studentName = utils.DOM.create('div', { class: 'studentname', text: ((student.firstname ?? '') + ' ' + (student.name ?? '')).trim() })
        studentContainer.appendChild(studentMarker)
        studentContainer.appendChild(studentName)
        if (student.present) {
          $groupContainer.appendChild(studentContainer)
        } else {
          $absentContainer.appendChild(studentContainer)
        }
      }
      if ($absentContainer.children.length > 0) {
        const $header = utils.DOM.create('h3', { text: 'Absents' })
        $absentContainer.prepend($header)
        $groupContainer.appendChild($absentContainer)
      }
    }
  }
  function showNamesOfStudents (second = ''): void {
    document.getElementById('participant-list' + String(second))?.classList.toggle('onlyNumbers')
  }
  function showAnswersOfStudents (second = ''): void {
    const list = Array.from(document.querySelectorAll('#participant-list' + String(second) + ' .student'))
    for (const el of list) {
      continue
    }
  }
  function slideMarkersReset (second = ''): void {
    const locationToReset = [second]
    if (session.mode === 'duel') locationToReset.push('0')
    for (const second of locationToReset) {
      document.querySelectorAll('#participant-list' + second + ' .markerid').forEach(el => {
        el.className = 'markerid'
      })
    }
  }
  function slideMarkerSetAnswerState () {
    const thisvotes = [votes]
    if (session.mode !== 'normal') thisvotes.push(votes2)
    for (const vote of thisvotes) {
      for (const id in vote.markersAnswers) {
        const answer = vote.markersAnswers[id][currentQuestionIndex - 1]
        if (answer !== undefined) {
          const lastVote = answer.history[answer.history.length - 1]
          if (lastVote === undefined) continue
          const marker = utils.DOM.getById('marker' + id)
          if (marker !== null) {
            if (vote.markersAnswers[id][currentQuestionIndex - 1].value.length > 0) {
              utils.DOM.addClass('marker' + id, 'hasVoted')
            }
            if (answer.history.length > 1 && marker.dataset.vote !== lastVote[0]) {
              let classVote = ('addVote')
              if (lastVote[1] === '-') classVote = 'removeVote'
              utils.DOM.addClass('marker' + id, classVote)
              setTimeout(() => { utils.DOM.removeClass('marker' + id, classVote) }, 3000)
            }
            marker.dataset.vote = lastVote[0]
          }
        }
      }
    }
  }
  function nextQuestion (second = '') {
    let qcm, current, numero
    if (second === '0') {
      qcm = $currentQcm2
      current = currentQuestionIndex2
      numero = document.querySelectorAll('#qcm-numero0')
    } else {
      qcm = $currentQcm
      current = currentQuestionIndex
      numero = document.querySelectorAll('#qcm-numero, #qcm-numero1')
    }
    // on retire le chrono s'il y en a un
    const $divChrono = document.querySelector('#qcm-container' + second + ' .chrono') as HTMLDivElement
    if ($divChrono !== null) {
      $divChrono.remove()
    }
    if (qcm !== undefined) {
      const total = qcm.questionsList.length
      if (total > current) {
        const actualSlide = document.querySelector('#qcm-container' + second + ' .question:nth-of-type(' + String(current) + ')')
        if (actualSlide !== null) {
          actualSlide.classList.remove('slidein')
          actualSlide.classList.add('slideout')
          setTimeout(() => {
            actualSlide.classList.add('is-hidden')
          }, 500)
        }
        const nextSlide = document.querySelector('#qcm-container' + second + ' .question:nth-of-type(' + String(current + 1) + ')')
        if (nextSlide !== null) {
          nextSlide.classList.remove('minus100')
          nextSlide.classList.add('slidein')
        }
        numero.forEach(el => {
          el.innerHTML = String(Number(current) + 1) + ' / ' + String(total)
        })
        if (second === '') {
          currentQuestionIndex++
        } else if (session.mode === 'duel' || second === '0') {
          currentQuestionIndex2++
        }
        slideMarkersReset(second)
        if (dectectionWithCamera) startDetection(second)
        else startCountDown(second)
      } else {
        // TODO : gestion du 2e QCM. Ne pas arrêter si pas tout fini
        // arrêt de la camera si tout fini
        if (session.mode !== 'double') {
          if (dectectionWithCamera) stopDetection()
        } else {
          const otherSlide = document.querySelector('#qcm-container' + (second === '0' ? '' : '0') + ' .question.fin')
          if (otherSlide !== null) {
            if (otherSlide.classList.contains('slidein')) { // l'autre est fini
              if (dectectionWithCamera) stopDetection(second, true)
              const videoElement = utils.DOM.getById('videotest') as HTMLVideoElement
              camera.stop(videoElement)       
            } else {
              if (dectectionWithCamera) stopDetection(second)
            }
          }
        }
        const actualSlide = document.querySelector('#qcm-container' + second + ' .question:nth-of-type(' + String(current) + ')')
        if (actualSlide !== null) {
          actualSlide.classList.remove('slidein')
          actualSlide.classList.add('slideout', 'minus100')
          setTimeout(() => {
            actualSlide.classList.add('is-hidden')
          }, 500)
        }
        const slideEnd = document.getElementById('slideEnd' + second) as HTMLElement
        slideEnd.classList.remove('minus100')
        slideEnd.classList.add('slidein')
      }
    }
  }
  function showGoodAnswer (second = '') {
    let qcmIndex, current
    if (second === '') {
      qcmIndex = currentQuestionIndex - 1
      current = currentQcm
    } else {
      qcmIndex = currentQuestionIndex2 - 1
      current = currentQcm2
    }
    if (current !== undefined && qcmIndex >= 0) {
      const slide = document.querySelector('#qcm-container' + second + ' #questionContainer' + String(qcmIndex))
      if (slide !== null) {
        slide.classList.toggle('showCorrectAnswers')
      }
    }
  }

function detectionInterval (thisQuestion: Question, second: string, timeZero: number, thisvotes: Votes) {
  if (thisQuestion.timedMode) {
    const $divChrono = document.querySelector('#qcm-container' + second + ' .chrono') as HTMLDivElement
    if (Date.now() - timeZero > thisQuestion.duration * 1000) {
      if ($divChrono !== null) {
        $divChrono.style.width = '0'
      }
      stopDetection(second)
      return
    } else {
      if ($divChrono !== null) {
        const timeLeft = Math.floor((thisQuestion.duration * 1000 - (Date.now() - timeZero)) * 100 / (thisQuestion.duration * 1000))
        $divChrono.style.width = String(timeLeft) + '%'
      }
    }
  }
  const mks: Record<number, string> | undefined = detection.detect()
  if (utils.object.getLength(mks) > 0 && mks !== undefined) {
    if (session.mode === 'normal' || session.mode === 'double') {
      thisvotes.analyseAnswers(mks, $config.changeOpinion)
    } else if (session.mode === 'duel') {
      votes.analyseAnswers(mks, $config.changeOpinion)
      votes2.analyseAnswers(mks, $config.changeOpinion)
    }
    slideMarkerSetAnswerState()
  }
}

</script>

<ShowPlay
  {showNamesOfStudents}
  {showGoodAnswersOfStudents}
  {showAnswersOfStudents}
  {displayStatsOfAnswers}
  {slideAddCache}
  {nextQuestion}
  {showGoodAnswer}
  {stopDetection}
/>
<ShowSettings
  {startQCM}
/>
