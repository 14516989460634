<script lang="ts">
  import { goToView } from '../../../lib/ts/services/navigation'

</script>

<div class="tab" style="margin: 3em;">
  <div class="columns">
    <div class="column is-one-third">
      <img src="images/markers.png" alt="4 cartes QCMCam">
    </div>
    <div class="column">
      <h2 class="title">Cartes</h2>
      <div class="p-4">
        <p>Imprimez vos cartes à la bonne taille. Dans des assez petites salles, les cartes A5 conviennent.
          Dans des salles un peu plus grandes, vous pouvez imprimer sur du A4, voir du A3 pour les
          très longues salles.</p>
        <p>La caméra aussi a de l'importance. Dans l'idéal c'est une caméra HD, pour plus de précision, à focale
          large (120°) qui pourra être installée face à votre classe et vous n'aurez ainsi pas besoin de la
          bouger pour scanner tout le monde.</p>
        <p>Vous pouvez imprimer vos cartes et les plastifier dans des films mat pour éviter les reflets et
          les distribuer à chaque séance. Personnellement, je les ai distribués à chaque élève en leur
          demandant de les coller sur du carton et ils le gardent dans leur cahier.</p>
      </div>
    </div>
  </div>
  <div class="columns is-align-items-stretch">
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
            <h2 class="title card-header-title">Cartes A5</h2>
        </header>
        <div class="card-content">
          <b>Cartes numérotés de 1 à 30</b>
          <div class="is-small">Pour une salle de taille moyenne, notamment en profondeur, deux
            cartes par page imprimée.</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a href="docs/QCMCam marqueurs 1_30 - A5 sur A4.pdf">
              <i class="ri-file-download-line qm-rel-top1"></i> Télécharger
          </a>
        </footer>
      </div>
    </div>
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
          <h2 class="title card-header-title">Cartes A5+</h2>
        </header>
        <div class="card-content">
          <b>Cartes numérotés de 31 à 60</b>
          <div class="is-small">Pour une salle de taille moyenne, notamment en profondeur, deux
            cartes par page.</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a href="docs/QCMCam marqueurs 31_60 - A5 sur A4.pdf">
            <i class="ri-file-download-line qm-rel-top1"></i> Télécharger
          </a>
        </footer>
      </div>
    </div>
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
          <h2 class="title card-header-title">Cartes A4++</h2>
        </header>
        <div class="card-content">
          <b>Cartes numérotés de 61 à 90</b>
          <div class="is-small">Pour une salle de taille moyenne, notamment en profondeur, deux
            cartes par page imprimée.</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a href="docs/QCMCam marqueurs 61_90 - A4.pdf">
            <i class="ri-file-download-line qm-rel-top1"></i> Télécharger
          </a>
        </footer>
      </div>
    </div>
  </div>
  <div class="columns is-align-items-stretch">
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
          <h2 class="title card-header-title">Cartes A4</h2>
        </header>
        <div class="card-content">
          <b>Cartes numérotés de 1 à 30</b>
          <div class="is-small">Pour une salle de bonne taille, notamment en profondeur, un marqueur
            par page.</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a href="docs/QCMCam cartes 1_30 light - A4.pdf">
            <i class="ri-file-download-line qm-rel-top1"></i> Télécharger
          </a>
        </footer>
      </div>
    </div>
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
          <h2 class="card-header-title title">Cartes A4+</h2>
        </header>
        <div class="card-content">
          <b>Cartes numérotés de 31 à 90</b>
          <div class="is-small">Pour une salle de taille moyenne, notamment en profondeur, deux
            cartes par page imprimée.</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a href="docs/QCMCam marqueurs 31_60 - A4.pdf">
            <i class="ri-file-download-line qm-rel-top1"></i> Télécharger
          </a>
        </footer>
      </div>
    </div>
    <div class="column is-one-third">
      <div class="card">
        <header class="card-header">
          <h2 class="card-header-title title">Générateur</h2>
        </header>
        <div class="card-content">
          <b>Générateur de cartes</b>
          <div class="is-small">Pour les élèves distraits, afficher un marqueur sur une
            tablette, ou générer le nombre de marqueur que l'on veut (jusqu'à 157).</div>
        </div>
        <footer class="card-footer is-justify-content-center p-2">
          <a
            href="?view=marker&action=generate"
            on:click={(mouseEvent) => goToView(mouseEvent, 'marker', 'generate')}
          >
            <i class="ri-external-link-line qm-rel-top1"></i> Ouvrir
          </a>
        </footer>
      </div>
    </div>
  </div>
</div>
