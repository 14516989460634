import { writable } from 'svelte/store'
import Group from '../models/group'
import type { Action, View } from '../models/navigation'
import QcmList from '../models/qcmsList'
import type Qcm from '../models/qcm'
import type Question from '../models/question'
import { emptyConfig, type Config } from '../models/config'
import { deepCopy } from './types'
import type InlineEditor from '../../js/ckeditor5/build/ckeditor'

export const currentView = writable<View>('home')
export const currentAction = writable<Action>('')
export const groups = writable<Group[]>([])
export const currentGroup = writable<Group | undefined>(undefined)
export const qcms = writable<QcmList>(new QcmList())
export const currentQcm = writable<Qcm | undefined>(undefined)
export const currentQcm2 = writable<Qcm | undefined>(undefined)
export const currentQuestions = writable<Question[]>([])
export const currentQuestions2 = writable<Question[]>([])
export const inlineEditors = writable<InlineEditor[]>([])
export const config = writable<Config>(deepCopy(emptyConfig))
export const tictoc = writable<Array<number | null>>([null, null])
