import { get } from 'svelte/store'
import { config } from './store'
import utils from './utils'

export function updateColors () {
  for (let i = 1; i < 5; i++) {
    const colorInput = utils.DOM.getById('defaultColor' + String(i)) as HTMLInputElement
    colorInput.value = get(config).defaultColors[i - 1]
    const colorInfo = utils.DOM.getById('editorConfigColor' + String(i)) as HTMLSpanElement
    if (colorInfo === null) continue
    colorInfo.style.backgroundColor = get(config).defaultColors[i - 1]
  }
}
