<script lang="ts">
  import { currentAction } from '../../lib/ts/services/store'
  import MarkerDownloads from './download/MarkerDownloads.svelte'
  import MarkerGenerator from './generate/MarkerGenerator.svelte'

</script>

{#if $currentAction === 'generate'}
  <MarkerGenerator />
{:else}
  <MarkerDownloads />
{/if}
