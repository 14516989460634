import utils from './utils.js'

const slider = {
  firstSlide: 'slider', // slider || blank
  numberOfQuestions: 0, // 0 for all the questions
  camera: 'local', // local || deported
  mode: 'normal', // normal || duel || relai
  checkUsageButtons () {
    const firstSlide = utils.forms.getRadioValue('qcm-start')
    if (firstSlide !== null) this.firstSlide = firstSlide
    const numberOfQuestions = utils.forms.getRadioValue('qcm-slides')
    if (numberOfQuestions !== null) {
      if (numberOfQuestions === '0') this.numberOfQuestions = Number(numberOfQuestions)
      else {
        const inputNumberValue = utils.forms.getValue('qcm-slides-number')
        if (inputNumberValue !== null) this.numberOfQuestions = Number(inputNumberValue)
      }
    }
    const camera = utils.forms.getRadioValue('camera-mode-choice')
    if (camera !== null) this.camera = camera
    const mode = utils.forms.getRadioValue('qcm-mode-choice')
    if (mode !== null) this.mode = mode
  },
  params () {
    return {
      firstSlide: this.firstSlide,
      numberOfQuestions: this.numberOfQuestions,
      camera: this.camera,
      mode: this.mode
    }
  }
}

export default slider
