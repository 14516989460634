import type { Action, View } from '../models/navigation'
import { currentAction, currentView } from './store'

export function goToView (mouseEvent: MouseEvent, destinationView: View, destinationAction?: Action): void {
  if (!isRegularClick(mouseEvent)) {
    return // to allow right clicks and opening in new tabs
  }
  mouseEvent.preventDefault()
  currentAction.set(destinationAction ?? '')
  currentView.set(destinationView)
  window.history.pushState({}, '', `?view=${destinationView}${destinationAction ? `&action=${destinationAction}` : ''}`)
}

export function isRegularClick (mouseEvent: MouseEvent): boolean {
  return mouseEvent.button === 0 && !mouseEvent.ctrlKey && !mouseEvent.metaKey
}
