<script lang="ts">
  import utils from '../../../lib/ts/services/utils'
  import Qcm from '../../../lib/ts/models/qcm'
  import storage from '../../../lib/ts/services/storage'
  import type Answer from '../../../lib/ts/models/answer'
  import type Question from '../../../lib/ts/models/question'

  export let qcms
  export let index
  export let listQcms: () => void
  export let updateSelectedQcmId: (id: number) => void
  export let displayQcmEditor: () => void

  let id: number
  let title: string
  let miniature: Question
  let questionContent: string
  let answersList: Answer[] = []

  $: if (qcms) {
    id = qcms.list[index][0]
    title = qcms.list[index][1]
    if (qcms.miniatures[index] !== undefined) {
      miniature = qcms.miniatures[index]
      questionContent = miniature.content
      answersList = [...miniature.answers]
      if (miniature.shuffleMode) utils.array.shuffle(answersList)
    } else {
      console.warn('Vignette absente de la liste des miniatures')
    }
  }

  const editQcm = () => {
    try {
      storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: String(id) })
      updateSelectedQcmId(index)
      displayQcmEditor()
    } catch (e) {
      alert('Erreur lors du chargement du QCM')
      console.warn(e)
    }
  }

  const copyQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const newQcm = await qcm.copy()
      await newQcm.save()
      qcms.list.push([newQcm.id, newQcm.name, newQcm.questionsList[0], newQcm.questionsList.length])
      // await qcms.save()
      listQcms()
    } catch (err) {
      console.error('Erreur de copie', err)
    }
  }

  const downloadQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const content = await qcm.export()
      const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })
      utils.file.download(blob, `${qcm.name}.txt`)
    } catch (err) {
      console.warn('oupsi export qcm !', err)
    }
  }

  const deleteQcm = async () => {
    if (confirm(`Vous allez supprimer le qcm \n${title}\nConfirmez-vous ?`)) {
      qcms.deleteQcmById(id).then(() => {listQcms()} )
    }
  }
</script>

<article class="is-card m4">
  <div class="is-media mb4">
    <div class="floatting">
      <input type="checkbox" value={id} id="qcm-selection-{index}" class="check-selection">
    </div>
    <div class="is-media-left">
      <i class="ri-file-line title is-3"></i>
    </div>
    <div class="is-media-content">
      <label class="is-title" for="qcm-selection-{index}">{title}</label>
    </div>
  </div>
  <div class="is-card-content p4">
    <div class="question-container">
      {#if miniature}
        <div class="question {miniature.displayMode}" style="font-size: {miniature.textSize}em">
          <header class="question-content {miniature.type}">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            <div>{@html questionContent}</div>
          </header>
          <ol class="answers {miniature.type}">
            {#each answersList as answer}
              <li class="answer {miniature.type}" class:is-correct={answer.isCorrect}>
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                <div>{@html answer.content}</div>
              </li>
            {/each}
          </ol>
        </div>
      {/if}
    </div>
  </div>
  <footer class="is-card-footer">
    <button class="card-footer-item" title="Modifier" on:click={editQcm}>
      <i class="ri-edit-line"></i>
    </button>
    <button class="card-footer-item" title="Dupliquer" on:click={copyQcm}>
      <i class="ri-file-copy-line"></i>
    </button>
    <button class="card-footer-item" title="Exporter" on:click={downloadQcm}>
      <i class="ri-file-download-line"></i>
    </button>
    <button class="card-footer-item" title="Supprimer" on:click={deleteQcm}>
      <i class="ri-delete-bin-line"></i>
    </button>
  </footer>
</article>

<style>
  .is-card {
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 0.5em 1em -0.125em hsla(221deg, 14%, 4%, 0.1), 0 0px 0 1px hsla(221deg, 14%, 4%, 0.02);
    color: rgb(64, 70, 84);
    max-width: 100%;
    position: relative;
  }
  .is-card-footer:last-child {
    border-end-start-radius: 0.75rem;
    border-end-end-radius: 0.75rem;
  }
  .is-card-footer {
    background-color: transparent;
    border-top: 1px solid hsl(221deg, 14%, 90%);
    display: flex;
    align-items: stretch;
  }
  .is-title {
    font-weight: bold;
  }
  .is-card .floatting {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .is-card .floatting input {
    display: none;
  }
  .is-card .floatting input:checked {
    display: block;
  }
  .is-card label {
    cursor: pointer;
  }
  .is-card:hover .floatting input {
    display: block;
  }
  button.card-footer-item {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    padding: 0.75rem;
    cursor: pointer;
    transition-duration: 294ms;
    transition-property: background-color, border-color, color;
  }
  .card-footer-item:not(:last-child) {
    border-inline-end: 1px solid hsl(221deg, 14%, 90%);
  }
  .is-media {
    align-items: flex-start;
    display: flex;
    text-align: inherit;
  }
  .is-media-left {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-inline-end: 1rem;
  }
  .is-media-content {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: inherit;
  }
  .mb4 {
    margin-bottom: 0.5rem;
  }
  .m4 {
    margin: 1rem;
  }
  .p4 {
    padding: 0 1rem;
  }
</style>