<script lang="ts">
  import { onMount } from 'svelte'
  import ModalImportGroups from './ModalImportGroups.svelte'
  import { groups } from '../../lib/ts/services/store'
  import Group from '../../lib/ts/models/group'
  import { showModal } from '../../lib/ts/services/modal'
  import utils from '../../lib/ts/services/utils'
  import Student from '../../lib/ts/models/student'
  import storage from '../../lib/ts/services/storage'

  onMount(() => {
    GroupList($groups)
  })

  function GroupList (groups: Group[]): void {
    const dom = utils.DOM.getById('groups-list') as HTMLUListElement
    if (groups.length > 0) {
      dom.innerHTML = ''
      for (const group of groups) {
        const li = utils.DOM.create('li')
        const anchor = utils.DOM.createAnchor({ text: group.name })
        anchor.dataset.id = String(group.id)
        anchor.onclick = () => {
          storage.db.store_data.put({ uid: 'QCMCamlatestSelectedGroup', data: group.id })
          GroupStudentsList(group.id, groups)
          unActiveGroupList()
          anchor.classList.add('is-active')
        }
        li.appendChild(anchor)
        dom.appendChild(li)
      }
    } else {
      dom.innerHTML = '<li>Pas de groupe</li>'
    }
  }

  function GroupStudentsList (id: number, groups: Group[]): void {
    if (id !== undefined) {
      const dom = utils.DOM.getById('students-list')
      let students: Record<number, Student> = []
      const group = Group.getGroupById(id, groups)
      if (group !== undefined) {
        students = group.students
        if (dom !== null) {
          dom.innerHTML = ''
          const groupNameH4 = document.createElement('h4')
          groupNameH4.className = 'title has-background-grey-lighter p-3'
          groupNameH4.innerText = 'Groupe "'
          const groupNameContent = utils.DOM.create('span', { id: 'group-name-content', text: group.name })
          groupNameContent.onfocus = () => {
            if (groupNameContent.innerText === 'Nom') groupNameContent.innerText = ''
          }
          let groupNameValue = group.name
          groupNameH4.appendChild(groupNameContent)
          groupNameH4.appendChild(document.createTextNode('"'))
          const divStudentsGroupCommands = utils.DOM.create('div', { id: 'students-list-commands' })
          const btnAddStudent = utils.DOM.createButton({ class: 'button', id: 'btn-add-student', html: '<img src="./images/plus-circle-1427-svgrepo-com.svg" style="height:1.5em">', title: 'Ajouter un participant' })
          divStudentsGroupCommands.appendChild(btnAddStudent)
          const btnEditGroupName = utils.DOM.createButton({ class: 'button is-info', id: 'btn-edit-group-name', html: '<i class="ri-edit-line"></i>' })
          divStudentsGroupCommands.appendChild(btnEditGroupName)
          const btnValidateGroupName = utils.DOM.createButton({ class: 'button is-success is-hidden is-light', id: 'btn-validate-edit-group-name', html: '<i class="ri-checkbox-circle-line"></i>' })
          divStudentsGroupCommands.appendChild(btnValidateGroupName)
          const btnCancelGroupName = utils.DOM.createButton({ class: 'button is-danger is-hidden is-light', id: 'btn-cancel-edit-group-name', html: '<i class="ri-close-circle-line"></i>' })
          divStudentsGroupCommands.appendChild(btnCancelGroupName)
          const btnDeleteGroup = utils.DOM.createButton({ class: 'button is-danger', id: 'btn-group-delete', html: '<i class="ri-delete-bin-line"></i>' })
          divStudentsGroupCommands.appendChild(btnDeleteGroup)
          btnAddStudent.onclick = () => {
            editNewStudent(dom, group, groups).catch(err => { console.warn('editNewStudent error', err) })
          }
          btnEditGroupName.onclick = () => {
            btnValidateGroupName.classList.remove('is-hidden')
            btnCancelGroupName.classList.remove('is-hidden')
            btnEditGroupName.classList.add('is-hidden')
            groupNameContent.contentEditable = 'true'
            groupNameContent.classList.add('editable')
            groupNameContent.focus()
          }
          btnValidateGroupName.onclick = async () => {
            const groupsNames = []
            for (const agroup of groups) {
              groupsNames.push(agroup.name)
            }
            if (groupNameContent.textContent !== '' && groupNameContent.textContent !== null) {
              if (groupNameContent.textContent === groupNameValue) {
                return
              } else if (groupsNames.includes(groupNameContent.textContent)) {
                alert('Nom de groupe déjà utilisé !')
                return
              } else {
                group.name = groupNameContent.textContent
                // mise à jour de la valeur dans les noms de groupes des élèves.
                for (const aGroup of groups) {
                  for (const key in aGroup.students) {
                    const aStudent = aGroup.students[key]
                    for (const [index, value] of aStudent.groups.entries()) {
                      if (value === groupNameValue) aStudent.groups[index] = group.name
                    }
                  }
                }
                groupNameValue = group.name
                await group.save()
                GroupList(groups)
                GroupStudentsList(group.id, groups)
              }
            } else {
              alert('Pas de nom défini')
              return
            }
            btnValidateGroupName.classList.add('is-hidden')
            btnCancelGroupName.classList.add('is-hidden')
            btnEditGroupName.classList.remove('is-hidden')
            groupNameContent.contentEditable = 'false'
            groupNameContent.classList.remove('editable')
          }
          btnCancelGroupName.onclick = () => {
            btnValidateGroupName.classList.add('is-hidden')
            btnCancelGroupName.classList.add('is-hidden')
            btnEditGroupName.classList.remove('is-hidden')
            groupNameContent.contentEditable = 'false'
            groupNameContent.classList.remove('editable')
          }
          btnDeleteGroup.onclick = async () => {
            if (confirm('Vous êtes sur le point de supprimer le groupe ' + group.name + '\nConfirmez-vous ?')) {
              for (const [index, group] of groups.entries()) {
                if (group.id === id) {
                  await Group.deleteGroup(group)
                  groups.splice(index, 1)
                  if (storage.isAvailable()) {
                    const data = []
                    for (const agroup of groups) {
                      data.push(agroup.id)
                    }
                    storage.db.store_data.put({ uid: 'QCMCamGroups', data })
                  }
                  GroupList(groups)
                  resetGroupStudentsList()
                  break
                }
              }
            }
          }
          dom.appendChild(divStudentsGroupCommands)
          dom.appendChild(groupNameH4)
        }
        const alphabeticalStudents = group.alphabeticalStudentIds
        for (const studentId of alphabeticalStudents) {
          const student = students[studentId]
          const divContainerStudent = utils.DOM.create('div', { class: 'columns student p-1 is-vcentered' })
          const header = utils.DOM.create('header', { class: 'column is-3', text: student.name })
          divContainerStudent.appendChild(header)
          const header2 = utils.DOM.create('header', { class: 'column is-3', text: student.firstname })
          divContainerStudent.appendChild(header2)
          const divMarker = utils.DOM.create('div', { class: 'column is-2', text: 'Carte n° ' })
          const spanNumeroMarker = utils.DOM.create('span', { text: String(student.markerId) })
          divMarker.appendChild(spanNumeroMarker)
          divContainerStudent.appendChild(divMarker)
          const divGroup = utils.DOM.create('div', { class: 'column is-2 is-relative', text: 'groupes : ' + student.groups.join(', ') })
          divContainerStudent.appendChild(divGroup)
          const divContainerActions = utils.DOM.create('div', { class: 'column' })
          const divDropDownActions = utils.DOM.create('div', { class: 'dropdown' })
          divContainerActions.appendChild(divDropDownActions)
          const divDropDownTrigger = utils.DOM.create('div', { class: 'dropdown-trigger' })
          divDropDownActions.appendChild(divDropDownTrigger)
          const buttonDropDownTrigger = utils.DOM.createButton({ class: 'button' })
          buttonDropDownTrigger.setAttribute('aria-haspopup', 'true')
          buttonDropDownTrigger.setAttribute('aria-controls', 'dropdown-menu')
          buttonDropDownTrigger.appendChild(utils.DOM.create('span', { text: 'Actions' }))
          buttonDropDownTrigger.appendChild(utils.DOM.create('span', { class: 'icon is-small' }).appendChild(utils.DOM.create('i', { class: 'ri-arrow-down-s-line' })))
          buttonDropDownTrigger.onclick = () => {
            const hisClass = divDropDownActions.classList
            const isActive = hisClass.contains('is-active')
            document.querySelectorAll('.student .dropdown.is-active').forEach(el => { el.classList.remove('is-active') })
            if (!isActive) divDropDownActions.classList.add('is-active')
          }
          divDropDownTrigger.appendChild(buttonDropDownTrigger)
          const divDropDownMenu = utils.DOM.create('div', { class: 'dropdown-menu', id: 'userEdit-' + String(student.markerId) })
          divDropDownMenu.setAttribute('role', 'menu')
          divDropDownActions.appendChild(divDropDownMenu)
          const divDropDownContent = utils.DOM.create('div', { class: 'dropdown-content' })
          divDropDownMenu.appendChild(divDropDownContent)
          const buttonPrintMarker = utils.DOM.createAnchor({ class: 'dropdown-item' })
          const iPrint = utils.DOM.create('i', { class: 'ri-printer-line' })
          buttonPrintMarker.appendChild(iPrint)
          buttonPrintMarker.appendChild(document.createTextNode(' Marqueur'))
          buttonPrintMarker.onclick = () => {
            divDropDownActions.classList.remove('is-active')
            const fenetre = window.open('?view=marker&action=generate&id=' + String(student.markerId) + '&name=' + student.name + (student.firstname !== '' ? ' ' + student.firstname : ''))
            if (fenetre !== null) fenetre.onclose = () => { return false }
          }
          divDropDownContent.appendChild(buttonPrintMarker)
          const buttonEdit = utils.DOM.createAnchor({ class: 'dropdown-item' })
          const i = utils.DOM.create('i', { class: 'ri-edit-line' })
          buttonEdit.appendChild(i)
          buttonEdit.appendChild(document.createTextNode(' Éditer'))
          buttonEdit.onclick = () => {
            if (header.contentEditable === 'true') return false
            let changes = false
            const name = student.name
            const firstname = student.firstname
            const markerId = student.markerId
            const groupList = student.groups
            divDropDownActions.classList.remove('is-active')
            divDropDownActions.classList.add('is-hidden')
            header.contentEditable = 'true'
            header2.contentEditable = 'true'
            spanNumeroMarker.innerHTML = ''
            const listOfDisponibleMarkers = group.getListOfDisponibleMarkers()
            listOfDisponibleMarkers.unshift(0, student.markerId)
            const selectMarkerId = createSelect(listOfDisponibleMarkers, student.markerId)
            selectMarkerId.classList.add('select')
            selectMarkerId.onchange = () => { student.markerId = Number(selectMarkerId.value) }
            spanNumeroMarker.appendChild(selectMarkerId)
            header.classList.add('has-background-link-light')
            header2.classList.add('has-background-link-light')
            const editGroupsButton = utils.DOM.createButton({ html: '<i class="ri-user-follow-line"></i>', class: 'button is-small' })
            divGroup.appendChild(editGroupsButton)
            const modal = createGroupCheckList(student, groups)
            modal.classList.add('is-hidden', 'modal-select-student-groups')
            divGroup.appendChild(modal)
            editGroupsButton.onclick = () => {
              modal.classList.toggle('is-hidden')
            }
            const confirmButton = utils.DOM.createButton({ html: '<i class="ri-checkbox-circle-line"></i>', class: 'is-success button ml-3 is-outlined is-small', title: 'Valider les modifications' })
            divContainerActions.appendChild(confirmButton)
            const cancelButton = utils.DOM.createButton({ html: '<i class="ri-close-circle-line"></i>', class: 'is-danger button ml-3 is-outlined is-small', title: 'Annuler les modifications' })
            divContainerActions.appendChild(cancelButton)
            confirmButton.onclick = async () => {
              header.contentEditable = 'false'
              header.classList.remove('has-background-link-light')
              header2.contentEditable = 'false'
              header2.classList.remove('has-background-link-light')
              spanNumeroMarker.innerText = String(student.markerId)
              if (header.innerText !== '') student.name = header.innerText
              if (header2.innerText !== '') student.firstname = header2.innerText
              if (name !== student.name) changes = true
              if (firstname !== student.firstname) changes = true
              if (markerId !== student.markerId) changes = true
              const StudentGroupList = student.groups.slice().sort()
              if (!(groupList.length === StudentGroupList.length && groupList.slice().sort().every((value, index) => value === StudentGroupList[index]))) changes = true
              divGroup.removeChild(editGroupsButton)
              divGroup.removeChild(modal)
              divGroup.innerText = 'groupes : ' + student.groups.join((', '))
              divContainerActions.removeChild(confirmButton)
              divContainerActions.removeChild(cancelButton)
              divDropDownActions.classList.remove('is-hidden')
              // sauvegarde
              if (changes && storage.isAvailable()) {
                await student.save()
              }
              GroupStudentsList(group.id, groups)
            }
            cancelButton.onclick = () => {
              header.contentEditable = 'false'
              header.classList.remove('has-background-link-light')
              header2.contentEditable = 'false'
              header2.classList.remove('has-background-link-light')
              spanNumeroMarker.innerText = String(markerId)
              header.innerText = name
              header2.innerText = firstname
              divGroup.removeChild(editGroupsButton)
              divGroup.removeChild(modal)
              divGroup.innerText = 'groupes :' + groupList.join(', ')
              student.groups = groupList
              divContainerActions.removeChild(cancelButton)
              divContainerActions.removeChild(confirmButton)
              divDropDownActions.classList.remove('is-hidden')
            }
          }
          divDropDownContent.appendChild(buttonEdit)
          const buttonRemove = utils.DOM.createAnchor({ class: 'dropdown-item' })
          const i2 = utils.DOM.create('i', { class: 'ri-user-unfollow-line' })
          buttonRemove.appendChild(i2)
          buttonRemove.appendChild(document.createTextNode(' Supprimer'))
          buttonRemove.onclick = async () => {
            divDropDownActions.classList.remove('is-active')
            if (confirm('Vous allez supprimer ' + student.name + '.\nConfirmez-vous ?')) {
              await group.removeStudentById(student.id)
              GroupStudentsList(id, groups)
            }
          }
          divDropDownContent.appendChild(buttonRemove)
          divContainerStudent.appendChild(divContainerActions)
          if (dom !== null) {
            dom.appendChild(divContainerStudent)
          }
        }
      }
    }
  }

  function unActiveGroupList () {
    document.querySelectorAll('#groups-list.menu-list > li > a').forEach(el => { el.classList.remove('is-active') })
  }

  async function editNewStudent (dom: HTMLElement, group: Group, groups: Group[]): Promise<void> {
    const student = new Student('Nom', 'Prénom', [group.name], 0)
    await student.init(group.id)
    const divContainerStudent = utils.DOM.create('div', { class: 'columns student p-1 is-vcentered' })
    const header = utils.DOM.create('header', { class: 'column is-3', text: student.name })
    divContainerStudent.appendChild(header)
    const header2 = utils.DOM.create('header', { class: 'column is-2', text: student.firstname })
    divContainerStudent.appendChild(header2)
    const divMarker = utils.DOM.create('div', { class: 'column is-2', text: 'Marqueur n° ' })
    const spanNumeroMarker = utils.DOM.create('span', { text: String(student.markerId) })
    divMarker.appendChild(spanNumeroMarker)
    divContainerStudent.appendChild(divMarker)
    const divGroup = utils.DOM.create('div', { class: 'column is-2 is-relative', text: 'groupes : ' + student.groups.join(', ') })
    divContainerStudent.appendChild(divGroup)
    const divContainerActions = utils.DOM.create('div', { class: 'column' })
    const buttonPrintMarker = utils.DOM.createAnchor({ class: 'button' })
    const iPrint = utils.DOM.create('i', { class: 'ri-printer-line' })
    buttonPrintMarker.appendChild(iPrint)
    buttonPrintMarker.appendChild(document.createTextNode(' Marqueur'))
    buttonPrintMarker.onclick = () => {
      const fenetre = window.open('?view=marker&action=generate&id=' + String(student.markerId) + '&name=' + student.name)
      if (fenetre !== null) fenetre.onclose = () => { return false }
    }
    divContainerActions.appendChild(buttonPrintMarker)
    header.contentEditable = 'true'
    header.onfocus = () => {
      if (header.innerText === 'Nom') header.innerText = ''
    }
    header2.contentEditable = 'true'
    header2.onfocus = () => {
      if (header2.innerText === 'Prénom') header2.innerText = ''
    }
    spanNumeroMarker.innerHTML = ''
    const listOfDisponibleMarkers = group.getListOfDisponibleMarkers()
    const selectMarkerId = createSelect(listOfDisponibleMarkers, student.markerId)
    selectMarkerId.classList.add('select')
    selectMarkerId.onchange = () => { student.markerId = Number(selectMarkerId.value) }
    spanNumeroMarker.appendChild(selectMarkerId)
    header.classList.add('has-background-link-light')
    header.classList.add('mx-2')
    header2.classList.add('has-background-link-light')
    header2.classList.add('mx-2')
    const editGroupsButton = utils.DOM.createButton({ html: '<i class="ri-user-follow-line"></i>', class: 'button is-small' })
    divGroup.appendChild(editGroupsButton)
    const modal = createGroupCheckList(student, groups)
    modal.classList.add('is-hidden', 'modal-select-student-groups')
    divGroup.appendChild(modal)
    editGroupsButton.onclick = () => {
      modal.classList.toggle('is-hidden')
    }
    const confirmButton = utils.DOM.createButton({ html: '<i class="ri-checkbox-circle-line"></i>', class: 'is-success button ml-3 is-outlined is-small', title: 'Valider les modifications' })
    divContainerActions.appendChild(confirmButton)
    const cancelButton = utils.DOM.createButton({ html: '<i class="ri-close-circle-line"></i>', class: 'is-danger button ml-3 is-outlined is-small', title: 'Annuler les modifications' })
    divContainerActions.appendChild(cancelButton)
    confirmButton.onclick = async () => {
      if (header.innerText === 'Nom' || header.innerText === '') {
        if (confirm('Vous n\'avez pas donné de nom à l\'utilisateur !\nConfirmer ?')) {
          return false
        }
      }
      student.markerId = Number(selectMarkerId.value)
      student.name = header.innerText
      student.firstname = header2.innerText
      await student.save()
      group.pushStudent(student)
      GroupStudentsList(group.id, groups)
    }
    cancelButton.onclick = async () => {
      await group.removeStudentById(student.id)
      dom.removeChild(divContainerStudent)
    }
    divContainerStudent.appendChild(divContainerActions)
    const h4GroupName = document.querySelector('#students-list > h4')
    if (dom !== null && h4GroupName !== null && h4GroupName.parentNode !== null) {
      h4GroupName.parentNode.insertBefore(divContainerStudent, h4GroupName.nextSibling)
    }
  }

  function resetGroupStudentsList () {
    const div = utils.DOM.getById('students-list')
    if (div !== null) {
      div.innerHTML = `<div class="py-6 is-size-3 has-text-grey-light has-text-weight-bold">
      <i class="ri-arrow-left-s-line"></i> Sélectionner un groupe pour voir/éditer ses éléments
    </div>`
    }
  }

  function createSelect (list: string[] | number[], selected?: string | number): HTMLSelectElement {
    const dom = utils.DOM.create('select') as HTMLSelectElement
    for (const value of list) {
      const option = utils.DOM.createOption({ value: String(value), text: String(value) })
      if (value === selected) {
        option.selected = true
      }
      dom.appendChild(option)
    }
    return dom
  }

  function createGroupCheckList (student: Student, groups: Group[]): HTMLElement {
    const dom = utils.DOM.create('div', { class: 'group-checklist field' })
    for (const group of groups) {
      const checkbox = utils.DOM.createInput({ type: 'checkbox', id: 'check' + group.name, value: group.name, class: 'is-checkradio' })
      if (student.groups.includes(group.name)) checkbox.checked = true
      const label = utils.DOM.create('label', { text: group.name }) as HTMLLabelElement
      label.htmlFor = 'check' + group.name
      dom.appendChild(checkbox)
      dom.appendChild(label)
      checkbox.onclick = async () => {
        if (checkbox.checked) {
          await student.addToGroup(group)
          group.students[student.id] = student
        } else {
          await student.removeFromGroup(group)
          await group.removeStudent(student)
        }
      }
    }
    return dom
  }

  function addGroup () {
    const group = new Group('Nom')
    group
      .init()
      .then(() => {
        $groups.push(group)
        GroupStudentsList(group.id, $groups)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function downloadGroups () {
    const groupsBlob = new Blob([JSON.stringify($groups, null, 2)], { type: 'application/json' })
    utils.file.download(groupsBlob, 'groupes.txt')
  }
</script>

<div class="tab is-flex">
  <div>
    <aside class="menu mx-2">
      <p class="menu-label">Vos groupes</p>
      <ul class="menu-list" id="groups-list">
        <li>Pas de groupe</li>
      </ul>
      <p class="menu-label">Commandes</p>
      <ul class="menu-list">
        <li>
          <button
            on:click={addGroup}
          >
            <i class="ri-play-list-add-line"></i>
            Ajouter un groupe
          </button>
        </li>
        <li>
          <button
            on:click={() => showModal('qm-import-groups')}
          >
            <i class="ri-file-upload-line"></i>
            Importer des groupes
          </button>
        </li>
        <li>
          <button
            on:click={downloadGroups}
          >
          <i class="ri-file-download-line"></i>
          Télécharger
          </button>
        </li>
        <!--<li id="btn-save-groups"><button><i class="ri-save-line"></i> Enregistrer</button></li>-->
      </ul>
    </aside>
  </div>
  <div class="p-3 is-flex-grow-1">
    <div class="p-3" id="students-list">
      <div class="py-6 is-size-3 has-text-grey-light has-text-weight-bold">
        <i class="ri-arrow-left-s-line"></i> Sélectionner un groupe pour voir/éditer ses éléments
      </div>
    </div>
  </div>
</div>
<ModalImportGroups
  {GroupList}
/>
